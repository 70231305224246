import { CardContent, Grid, Box, Stack, Button, Divider, Drawer, Typography, Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Header from 'components/Header'
import { useNavigate, useParams } from 'react-router-dom'
import { BasePathProvider, useBasePath } from 'components/Providers'
import PreviewCard from 'components/PreviewCard'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { search } from 'store/provider/marking-period'
import { getClients } from 'store/lookup'
import MarkingPeriodForm from './Form/Form'
import Skeleton from './Skeleton'
import { stringToColor } from 'components/Avatar'

export default function FullYearPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const basePath = useBasePath()
  const dispatch = useDispatch()
  let { academicYearId, '*': yearType } = useParams()
  yearType = yearType.split('/')[1]

  const academicYear = useSelector(state => state.provider.academicYear.rows)?.find(x => x.id == academicYearId)
  const clients = useSelector(state => state.lookup?.clients)
  const districts = clients?.map(item => item.client).flat()

  useEffect(() => {
    clients?.length || dispatch(getClients())
  }, [dispatch, clients?.length])

  useEffect(() => {
    academicYearId && dispatch(search({ academicYearId: +academicYearId }))
  }, [dispatch, academicYearId, yearType])

  const getName = () => {
    const startYear = new Date(academicYear?.startDate).getFullYear()
    const endYear = new Date(academicYear?.endDate).getFullYear()
    if (academicYear?.isFullYear) {
      return `${startYear} - ${endYear}`
    } else {
      return `${startYear} Summer`
    }
  }
  const schools = clients
    ?.map(item =>
      item.client.schools.map(school => ({
        id: school.id,
        name: school.name,
        campuses: school.campuses.map(campus => ({
          id: campus.id,
          name: campus.name,
        })),
      })),
    )
    .flat()

  const campuses = clients?.flatMap(item =>
    item.client.schools.flatMap(school =>
      school.campuses.map(campus => ({
        id: campus.id,
        name: campus.name,
      })),
    ),
  )
  function formatDate(date) {
    const options = { month: 'short', day: 'numeric' }
    return new Date(date).toLocaleDateString(undefined, options)
  }

  if (!academicYear?.markingPeriodProvider) {
    return null
  }
  const baseUrl = academicYear?.isFullYear ? 'full-year' : 'summer'

  const renderChips = ids =>
    ids?.map((item, index) => {
      const foundItem =
      campuses?.length && districts.length ? [...campuses, ...districts]?.find(i => i.id === item) : null
        return (
          foundItem && (
            <Chip
              key={index}
              label={foundItem?.abbreviation || foundItem?.name}
              style={{ backgroundColor: stringToColor(foundItem?.abbreviation || foundItem?.name) }}
              sx={{ color: 'primary.contrastText' }}
              size="small"
            />
        )
      )
    })

  if (!academicYear || !academicYear?.markingPeriodProvider) {
    return <Skeleton />
  }
  return (
    <BasePathProvider value={`${basePath}/${academicYear?.id}`}>
      <Box>
        <Header
          title={`${getName()} Marking Periods ${academicYear?.isFullYear ? '(School Year)' : '(Summer)'}`}
          close
          small
        />
        <Divider />
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ mt: 2, mr: 2 }}>
          <Button
            type="submit"
            size="small"
            variant="contained"
            color="primary"
            onClick={() => navigate(`${basePath}/${academicYear?.id}/${baseUrl}/new`)}
          >
            {t('ADD MARKING PERIODS')}
          </Button>
        </Stack>
        <CardContent>
          <Grid container spacing={2}>
            {Boolean(academicYear?.markingPeriodProvider?.length) &&
              academicYear?.markingPeriodProvider?.map(item => (
                <Grid item xs={12} md={12} sx={{ height: '100%', mt: 1 }} key={item.id}>
                  <PreviewCard
                    key={item.id}
                    title={item?.name}
                    size="large"
                    buttonNavigate={`${basePath}/${academicYear?.id}/full-year/${item.id}`}
                    buttonLabel={t('More')}
                    actionChildren={renderChips(item.destinationIds)}
                  >
                    <Grid container spacing={2}>
                      {item?.markingPeriods?.map((item, index) => (
                        <Grid key={index} item sm={3} xs={3} md={3}>
                          <Typography variant="caption" color="textSecondary">
                            {item.name}
                          </Typography>
                          <Typography variant="subtitle2" color="textSecondary">
                            {formatDate(item?.startDate)}
                            {' - '}
                            {formatDate(item?.endDate)} &nbsp;
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </PreviewCard>
                </Grid>
              ))}
          </Grid>
        </CardContent>
      </Box>
      <Drawer
        PaperProps={{
          sx: {
            width: 600,
          },
        }}
        open={Boolean(yearType === 'full-year' || yearType === 'summer')}
        anchor="right"
        onClose={() => navigate(`${basePath}/${academicYear?.id}`)}
      >
        <MarkingPeriodForm
          clients={clients}
          campuses={campuses}
          districts={districts}
          schools={schools}
          academicYear={academicYear}
        />
      </Drawer>
    </BasePathProvider>
  )
}
