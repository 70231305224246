import { useEffect, useState } from 'react'
import { Toolbar, Button, Paper, Link, Box, Container } from '@mui/material'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

import { resolve, approve, getEmployment } from '../store/session'

import { Body, SubTitle, Title } from './typo'

const APPROVE_KEY = 'approve'

function CenterBox({ children }) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" minHeight="100vh">
      <Toolbar />
      {children}
    </Box>
  )
}

function Approval({ children }) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const token = localStorage.getItem(APPROVE_KEY)
    if (!token) {
      setLoading(false)
      return
    }
    dispatch(resolve({ token })).then(res => {
      setData(res.payload)
      setLoading(false)
    })
  }, [dispatch])

  const onDeny = () => {
    localStorage.removeItem(APPROVE_KEY)
    setData(null)
  }

  const onApprove = async () => {
    const token = localStorage.getItem(APPROVE_KEY)
    const res = await dispatch(approve({ token }))
    if (res.payload) {
      await dispatch(getEmployment())
      localStorage.removeItem(APPROVE_KEY)
      setData(null)
    }
  }

  if (loading)
    return (
      <CenterBox>
        <Title>Loading...</Title>
      </CenterBox>
    )

  if (!data) return children

  return (
    <CenterBox>
      <Container maxWidth="sm">
        <Paper sx={{ p: 2 }}>
          <Title>Invitation</Title>
          <SubTitle>{data.district.name} invites you to join their SIS platform.</SubTitle>
          <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
            <Button variant="contained" color="secondary" onClick={onDeny}>
              Deny
            </Button>
            <Box sx={{ ml: 2 }}></Box>
            <Button variant="contained" color="primary" onClick={onApprove}>
              Confirm
            </Button>
          </Box>
          <Body sx={{ mt: 2 }} textAlign="center">
            <Link underline="none" href="#" onClick={() => setData(null)}>
              ask mee later
            </Link>
          </Body>
        </Paper>
      </Container>
    </CenterBox>
  )
}

CenterBox.propTypes = {
  children: PropTypes.node,
}

Approval.propTypes = {
  children: PropTypes.node,
}

export default Approval
