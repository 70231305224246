import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'academic-course-session'
const initialState = {
  list: null,
  studentList: null,
  selectedCourseSession: null,
  resultsList: null,
}

export const search = createApiAction('academic/course-session/search', client.search('/api/teacher/course-session'))
export const searchStudents = createApiAction('academic/course-student-list/search', client.search(`/api/teacher/student/:courseSessionId`))
export const searchResults = createApiAction('academic/course-session/results', client.search('/api/teacher/result/:courseSessionId'))

// export const get = createApiAction('academic/course-session/get', client.search('/api/academic/course-session/:id'))

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    setSelectedCourseSession(state, action) {
      state.selectedCourseSession = action.payload
    }
  },
  extraReducers(builder) {
    builder.addCase(search.fulfilled, (state, action) => {
      state.list = action.payload
      if (action.payload && action.payload.length > 0){
        state.selectedCourseSession = action.payload[0]
      }
    })
    builder.addCase(searchStudents.fulfilled, (state, action) => {
      state.studentList = action.payload
    })
    builder.addCase(searchResults.fulfilled, (state, action) => {
      state.resultsList = action.payload
    })
  },
})

export const { setSelectedCourseSession } = sessionSlice.actions
export default sessionSlice.reducer
