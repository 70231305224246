import { EmploymentTypeProvider } from '../../components/Providers'
import Person from '../Person'

export default function Staff() {
  return (
    <EmploymentTypeProvider type="staff">
      <Person />
    </EmploymentTypeProvider>
  )
}
