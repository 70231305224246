import client from '../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../utils/redux-tool'

const name = 'courseSessionEnrollment'
const initialState = {
  query: {},
  list: null,
}

export const search = createApiAction(
  'person/course-session-enrollment/search',
  client.search('/api/:employmentType/:personId/course-session-enrollment'),
)
export const get = createApiAction(
  'person/course-session-enrollment/get',
  client.retrieve(`/api/:employmentType/:personId/course-session-enrollment/:id`),
)
export const save = createApiAction(
  'person/course-session-enrollment/save',
  client.save('/api/:employmentType/:personId/course-session-enrollment'),
)
export const destroy = createApiAction(
  'person/course-session-enrollment/delete',
  client.destroy('/api/:employmentType/:personId/course-session-enrollment/:id'),
)

const updateList = (state, action) => {
  const found = state.list.find(rec => rec.id === action.payload.id)
  state.list = found
    ? state.list.map(person => (found.id === person.id ? action.payload : person))
    : [...state.list, action.payload]
}

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearCourseSessionEnrollments: state => {
      state.list = null
    },
  },
  extraReducers(builder) {
    builder
      .addCase(search.pending, state => {
        state.list = null
      })
      .addCase(search.fulfilled, (state, action) => {
        state.query = {
          personId: action.meta.arg.personId,
          include: action.meta.arg?.include || null,
        }
        state.list = action.payload
      })
      .addCase(get.fulfilled, updateList)
      .addCase(save.fulfilled, updateList)
      .addCase(destroy.fulfilled, (state, action) => {
        state.list = state.list.filter(ay => ay.id !== action.payload.id)
      })
  },
})
export const { clearCourseSessionEnrollments } = sessionSlice.actions

export default sessionSlice.reducer
