import { Fragment, useState } from 'react'
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, styled } from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell'
import { useTranslation } from 'react-i18next'
/* import AddIcon from '@mui/icons-material/Add' */

import DocumentEdit from './DocumentEdit'
import DocumentItem from './DocumentItem'
import { useSelector } from 'react-redux'

/* const fabStyle = {
  position: 'absolute',
  bottom: 16,
  right: 16,
} */

const StyledTableCell = styled(TableCell)(() => {
  return {
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 'bold',
    },
  }
})

export default function DocumentList() {
  const [newFile, setNewFile] = useState(false)
  const files = useSelector(state => state.employment.files)
  const { t } = useTranslation()

  return (
    <Fragment>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                {t('Documents')} {t('Name')}
              </StyledTableCell>
              <StyledTableCell sx={{ width: '15em' }}>{t('Type')}</StyledTableCell>
              <StyledTableCell sx={{ width: '6em' }}>{t('Approved')}</StyledTableCell>
              <StyledTableCell sx={{ width: '14em' }}>{t('Expiration Date')}</StyledTableCell>
              <StyledTableCell sx={{ width: '6em' }}>{t('Shared')}</StyledTableCell>
              <StyledTableCell sx={{ width: '8em' }}></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newFile && <DocumentEdit document={{}} onClose={() => setNewFile(false)} />}
            {files.map(item => {
              return <DocumentItem key={item.id || item.uid} document={item} />
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/*  <Fab sx={fabStyle} color="primary" aria-label="add" onClick={() => setNewFile(true)}>
        <AddIcon />
      </Fab> */}
    </Fragment>
  )
}
