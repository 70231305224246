import client from '../../../utils/api'
import { createApiAction } from '../../../utils/redux-tool'

export const search = createApiAction('academic/parent/schedule/search', client.search('/api/academic/schedule'))

export default function setCourseEnrollmentReducers(builder) {
  builder.addCase(search.fulfilled, (state, action) => {
    const list = state.list || []
    const { schoolEnrollmentId } = action.meta.arg
    const found = list.find(item => item.employments[0].schoolEnrollments[0].id === schoolEnrollmentId)
    if (found)
      state.list = list.map(item =>
        item.employments[0].schoolEnrollments[0].id === found.employments[0].schoolEnrollments[0].id
          ? { ...item, schedule: action.payload }
          : item,
      )
  })
}
