import { useDispatch } from 'react-redux'
import {
  Button,
  Box,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Toolbar,
  MenuItem,
  IconButton,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { pick, flatten } from 'lodash'
import { styled } from '@mui/material/styles'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { save } from 'store/academic-year-enrollment'
import { useBasePath, useEmploymentType, usePerson, useSession } from 'components/Providers'
import { CloseIcon } from 'components/Icons'
import { omit } from 'lodash'
import { useTranslation } from 'react-i18next'

const Root = styled(Box)(({ theme }) => ({
  width: theme.breakpoints.values.sm,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

function getGradeLevelSections(gradeLevels, gradeLevelId, enrollment) {
  const sections = flatten(gradeLevels.map(gl => gl.gradeLevelSections))
  const results = sections.filter(item => (gradeLevelId ? item?.gradeLevelId === gradeLevelId : true))
  const found = results?.find(section => section?.id === enrollment?.gradeLevelSectionId)

  if (!found) {
    const selected = sections?.find(section => section?.id === enrollment?.gradeLevelSectionId)
    selected && results.unshift({ ...selected, name: `${selected.name} (previously selected)` })
  }
  return results
}

export default function AcademicYearEnrollmentEditForm({ enrollment, academicYears, gradeLevels }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { academicYear, schoolEnrollment } = useSession()
  const employmentType = useEmploymentType()
  const person = usePerson()
  const basePath = useBasePath()
  const { t } = useTranslation()

  const schema = yup.object().shape({
    academicYearId: yup.number().required('Required'),
    gradeLevelId: yup
      .string()
      .nullable()
      .transform(value => value || null),
    gradeSection: yup
      .string()
      .nullable()
      .transform(value => value || null),
  })

  const { control, handleSubmit, reset, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      academicYearId: enrollment?.academicYear?.id || academicYear?.id || '',
      gradeLevelId: enrollment?.gradeLevel?.id || '',
      gradeSection: enrollment?.gradeSection || '',
      ...pick(enrollment, 'id'),
    },
  })

  const gradeLevelId = watch('gradeLevelId')

  const onSubmit = async data => {
    if (data.id) {
      let payload = omit(data, ['gradeLevelSectionId', 'academicYearId', 'schoolEnrollmentId'])
      const res = await dispatch(save({ ...payload, employmentType, personId: person.id }))
      if (res.payload) {
        reset(res.payload)
        navigate(basePath)
      }
    } else {
      let payload = omit(data, 'gradeLevelSectionId')
      const res = await dispatch(
        save({ ...payload, employmentType, personId: person.id, schoolEnrollmentId: schoolEnrollment.id }),
      )
      if (res.payload) {
        reset(res.payload)
        navigate(basePath)
      }
    }
  }

  const onError = errors => console.error(errors)

  const flterAcademicYear = () => {
    if (!academicYears || !schoolEnrollment || !schoolEnrollment?.school) {
      return []
    }

    return academicYears.filter(item => {
      return (
        item.school.id === schoolEnrollment.school.id &&
        item.startDate >= schoolEnrollment.startDate &&
        (!schoolEnrollment.endDate || item.endDate <= schoolEnrollment.endDate)
      )
    })
  }

  return (
    <Root
      component="form"
      elevation="0"
      onSubmit={handleSubmit(onSubmit, onError)}
      noValidate
      _sx={{ minHeight: '80vh' }}
    >
      <Toolbar />
      <CardHeader
        title={t('Academic')}
        subheader={t('Academic Year Enrollments')}
        action={
          <IconButton onClick={() => navigate(basePath)}>
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <Controller
              name="academicYearId"
              control={control}
              render={({ field: { onChange, ...field }, fieldState }) => {
                return (
                  <TextField
                    autoFocus
                    fullWidth
                    select
                    size="small"
                    label={t('Academic Year')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                    onChange={onChange}
                  >
                    {flterAcademicYear()?.map(({ id, name, school }) => (
                      <MenuItem key={id} value={id}>
                        {name} - {school.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item sm={6} xs={6}>
            <Controller
              name="gradeLevelId"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    select
                    size="small"
                    label={t('Grade')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    value={value || ''}
                    {...field}
                  >
                    <MenuItem value="">
                      <em>{t('None')}</em>
                    </MenuItem>
                    {gradeLevels?.map(item => (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item sm={6} xs={6}>
            <Controller
              name="gradeSection"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Grade Section')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    value={value || ''}
                    {...field}
                  >
                    <MenuItem value="">n/a</MenuItem>
                    {getGradeLevelSections(gradeLevels, gradeLevelId, enrollment)?.map(item => (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end', pr: 2 }}>
        <Button variant="outlined" onClick={() => navigate(basePath)}>
          {t('Cancel')}
        </Button>
        <Button type="submit" variant="contained">
          {t('Submit')}
        </Button>
      </CardActions>
    </Root>
  )
}
