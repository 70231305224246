import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'store/settings/school-enrollment'
import { getSchools } from 'store/lookup'
import SchoolEnrolmentList from './List'
import { Drawer } from '@mui/material'
import Header from 'components/Header'
import { useNavigate, useParams } from 'react-router-dom'
import { BasePathProvider, useBasePath, useEmploymentType, usePerson } from 'components/Providers'
import SchoolEnrollmentForm from './Form'
import { t } from 'i18next'

export default function SchoolEnrolments({ enrollments }) {
  const dispatch = useDispatch()
  const person = usePerson()
  const employmentType = useEmploymentType()
  const [employment] = useState(person.employments?.[0])
  const [enrollment, setEnrollment] = useState(null)
  const currentPath = useBasePath()
  const navigate = useNavigate()
  const basePath = `${currentPath}/enrollments`
  const { schoolEnrollmentId } = useParams()

  const schools = useSelector(state => state.lookup.schools)

  useEffect(() => {
    schools?.length || dispatch(getSchools({ include: ['academicYear'] }))
  }, [dispatch])

  useEffect(() => {
    setEnrollment(null)
    if (schoolEnrollmentId === 'new') {
      setEnrollment({})
    } else if (schoolEnrollmentId) {
      dispatch(get({ employmentType, personId: person.id, id: schoolEnrollmentId })).then(res => {
        setEnrollment(res.payload)
      })
    }
  }, [dispatch, employmentType, person?.id, schoolEnrollmentId])

  if (!schools) return null

  /*   if (!employment) return <div>loading</div>
   */
  return (
    <>
      <BasePathProvider value={`${basePath}/school-enrollment`}>
        <Header small title={t('School Enrollments')} add />
        {enrollments && <SchoolEnrolmentList enrollments={enrollments} employment={employment} />}
      </BasePathProvider>
      <BasePathProvider value={basePath}>
        <Drawer anchor="right" open={Boolean(enrollment)} onClose={() => navigate(basePath)} sx={{ width: '1000px' }}>
          {schools && <SchoolEnrollmentForm enrollment={enrollment} schools={schools} />}
        </Drawer>
      </BasePathProvider>
    </>
  )
}
