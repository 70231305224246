import { useMember } from 'components/Providers'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import * as action from 'store/member/attendance-report'
import { useParams } from 'react-router-dom'
import Attendance from 'components/Attendance'
import NotFound from 'components/NotFound'
import Skeleton from './Skeleton'
import { useBasePath } from 'components/Providers'

export default function Index() {
  const member = useMember()
  const dispatch = useDispatch()
  const { memberId, employmentType } = useParams()
  const [notFound, setNotFound] = useState(false)
  const basePath = useBasePath()

  useEffect(() => {
    dispatch(action.retrieve({ type: employmentType, memberId })).then(res => {
      res.error && setNotFound(true)
    })
  }, [dispatch, memberId, employmentType])

  const attendanceReports = useSelector(state => state.member.list.find(item => item.id === member.id).attendanceReport)

  let absenceRate =
    (attendanceReports?.daily?.absent * 100) /
    (attendanceReports?.daily?.absent + attendanceReports?.daily?.tardy + attendanceReports?.daily?.present)

  absenceRate = isNaN(absenceRate) || !isFinite(absenceRate) ? '-' : absenceRate

  if (notFound) return <NotFound basePath={`${basePath}/attendance`} />

  if (!attendanceReports) return <Skeleton />

  return <Attendance absenceRate={absenceRate} attendanceReports={attendanceReports} />
}
