
export function stringToColor(string) {
if (string && typeof string === 'string' && string.includes("Bergen")) {
  return "#0075C9"
}
if (string && typeof string === 'string' && string.includes("Bronx")) {
  return "#1A428A"
}
if (string && typeof string === 'string' && string.includes("Hudson")) {
  return "#009845"
}
if (string && typeof string === 'string' && string.includes("Paterson")) {
  return "#FFB600"
}
if (string && typeof string === 'string' && string.includes("Passaic")) {
  return "#B12028"
}

  let hash = 0
  let i

  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}

export default function CustomAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  }
}
