import { debounce, omit } from 'lodash'
import { InputAdornment, Stack, TextField, MenuItem } from '@mui/material'

import { search } from '../../../store/person'

import { CloseIcon, SearchIcon } from '../../../components/Icons'
import { useEmploymentType } from '../../../components/Providers'
import { useDispatch, useSelector } from 'react-redux'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

function SearchBar() {
  const dispatch = useDispatch()
  const employmentType = useEmploymentType()
  const { t } = useTranslation()
  const { query } = useSelector(state => state.person[employmentType])
  const [que, setQue] = useState(query.que || '')

  const applyFilter = async (key, value) => {
    if (value) {
      dispatch(search({ ...query, [key]: value, employmentType, offset: 0 }))
    } else {
      dispatch(search({ ...omit(query, key), employmentType, offset: 0 }))
    }
  }

  const delayedSearch = useMemo(() => {
    return debounce((que, callback) => callback('que', que), 500)
  }, [dispatch])

  return (
    <Stack direction="row" spacing={2} sx={{ p: 2 }}>
      <TextField
        size="small"
        fullWidth
        placeholder="Search"
        value={que}
        onChange={event => {
          setQue(event.target.value)
          delayedSearch(event.target.value, applyFilter)
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <CloseIcon
              onClick={() => {
                setQue('')
                applyFilter('que', '')
              }}
              sx={{ '&:hover': { cursor: 'pointer' }, fontSize: 'small' }}
            />
          ),
        }}
      />
      <TextField
        select
        sx={{ width: '16em' }}
        size="small"
        label={t("Scope")}
        placeholder="Scope"
        value={query.scope || ''}
        onChange={event => applyFilter('scope', event.target.value)}
      >
        <MenuItem value="district">District</MenuItem>
        <MenuItem value="school">School</MenuItem>
        <MenuItem value="academicYear">Academic Year</MenuItem>
      </TextField>
      {/* <TextField
        select
        sx={{ width: '10em' }}
        size="small"
        label={t("Status")}
        placeholder="Status"
        value={query.active}
        onChange={event => applyFilter('active', event.target.value)}
      >
        <MenuItem value={true}>Active</MenuItem>
        <MenuItem value={false}>Inactive</MenuItem>
      </TextField>
      <TextField
        select
        sx={{ width: '10em' }}
        size="small"
        label={t("Grade")}
        placeholder="Grade"
        value={query.gradeLevel || ''}
        onChange={event => applyFilter('gradeLevel', event.target.value)}
      >
        <MenuItem value="">-</MenuItem>
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
      </TextField>
      <Divider orientation="vertical" flexItem />
      <IconButton><MoreIcon /></IconButton> */}
    </Stack>
  )
}

export default SearchBar
