import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  TextField,
  Button,
  IconButton,
  Grid,
  Stack,
  Box,
  Divider,
  MenuItem,
  Autocomplete,
  InputAdornment,
  Chip,
  InputLabel,
  FormControl,
  Select,
} from '@mui/material'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import InviteIcon from '@mui/icons-material/Send'
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/SaveOutlined'
import Confirm from 'components/Dialog'
import { DatePicker } from '@mui/x-date-pickers'
import { useTranslation } from 'react-i18next'
import { save, destroy, sendInvitation } from 'store/person'
import { useEmploymentType, usePerson } from 'components/Providers'
import { useNavigate } from 'react-router-dom'
import { getRaceTypes } from 'store/lookup'
import dayjs from 'dayjs'
import AddressList from './AddressList'
import PhoneList from './PhoneList'
import SocialList from './SocialList'

const genders = ['Male', 'Female', 'Non-Binary/Undesignated']

const etnicities = ['Hispanic or Latino', 'Not Hispanic or Latino']

const titles = ['Mr.', 'Ms.', 'Mrs.']

const suffixes = ['Jr.', 'Sr.', 'II', 'III']

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export default function ProfileEdit() {
  const employmentType = useEmploymentType()
  const data = usePerson()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [confirm, setConfirm] = useState(false)
  const { t } = useTranslation()

  // const dateRuleMax = dayjs(Date.now() - 24 * 60 * 60 * 365 * 1000 * 3)
  // const dateRuleMin = dayjs(Date.now() - 24 * 60 * 60 * 365 * 1000 * 30)

  const schema = yup
    .object()
    .shape({
      firstName: yup.string().required('Required'),
      lastName: yup.string().required('Required'),
      dateOfBirth: yup.date().nullable(),
      phones: yup
        .array()
        .of(
          yup.object().shape({
            type: yup.string().required('Required'),
            number: yup.string().required('Required'),
          }),
        )
        .min(0, 'Required'),
      socials: yup
        .array()
        .of(
          yup.object().shape({
            type: yup.string().required('Required'),
            address: yup.string().required('Required'),
          }),
        )
        .min(0, 'Required'),
      addresses: yup
        .array()
        .of(
          yup.object().shape({
            type: yup.string().required('Required'),
            street: yup.string().required('Required'),
            line2: yup.string(),
            city: yup.string().required('Required'),
            state: yup.string().required('Required'),
            zip: yup.string().required('Required'),
          }),
        )
        .min(0, 'Required'),
    })
    .required()

  const { control, handleSubmit, reset, getValues } = useForm(
    {
      resolver: yupResolver(schema),

      defaultValues: {
        ...data,
        title: data?.title || '',
        firstName: data?.firstName || '',
        lastName: data?.lastName || '',
        suffix: data?.suffix || '',
        email: data?.email || '',
        gender: data?.gender || '',
        raceTypeIds: data?.raceTypeIds || [],
        ssn: data?.ssn || '',
        ethnicity: data?.ethnicity || '',
        addresses: data?.addresses || [],
        employments: data?.employments || [{}],
      },
    },
    [data],
  )

  const addressArrayControl = useFieldArray({ control, name: 'addresses', keyName: 'uuid' })
  const phoneArrayControl = useFieldArray({ control, name: 'phones', keyName: 'uuid' })
  const socialArrayControl = useFieldArray({ control, name: 'socials', keyName: 'uuid' })

  const { raceTypes } = useSelector(state => state.lookup)

  useEffect(() => {
    raceTypes || dispatch(getRaceTypes())
  }, [dispatch, Boolean(raceTypes)])

  useEffect(() => {
    const { id } = getValues()
    if (id !== data?.id) reset(data)
  }, [reset, getValues, data])

  const inviteHandle = () => {
    if (data.id) {
      dispatch(sendInvitation({ employmentType, personId: data.id }))
    }
  }

  const onSubmit = async data => {
    const isNew = !data.id

    const { error, payload } = await dispatch(
      save({
        ...data,
        employmentType,
      }),
    )
    if (error) {
      console.error(error)
    } else {
      if (isNew) {
        navigate(`/${employmentType}/${payload.id}`)
      } else {
        reset(payload)
      }
    }
  }

  const onDelete = () => {
    setConfirm(true)
  }

  const onConfirm = async () => {
    setConfirm(false)
    const data = getValues()
    if (!data.id) {
      navigate(`/${employmentType}`)
      return
    }
    const { payload } = await dispatch(destroy({ id: data.id, employmentType }))
    if (payload) {
      navigate(`/${employmentType}`)
    }
    navigate(`/${employmentType}`)
  }

  const onError = errors => console.error(errors)

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit, onError)} noValidate sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <Controller
            name="title"
            control={control}
            render={({ field: { onChange, ...field }, fieldState }) => {
              return (
                <Autocomplete
                  freeSolo
                  options={titles}
                  value={field.value || ''}
                  onChange={(event, value) => {
                    onChange(value)
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      label={t('Title')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            name="firstName"
            control={control}
            render={({ field: { ...field }, fieldState }) => {
              return (
                <TextField
                  autoFocus
                  fullWidth
                  label={t('First Name')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Controller
            name="middleName"
            control={control}
            render={({ field: { ...field }, fieldState }) => {
              return (
                <TextField
                  autoFocus
                  fullWidth
                  label={t('Middle Name')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                  value={field.value || ''}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            name="lastName"
            control={control}
            render={({ field: { ...field }, fieldState }) => {
              return (
                <TextField
                  fullWidth
                  label={t('Last name')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Controller
            name="suffix"
            control={control}
            render={({ field: { onChange, ...field }, fieldState }) => {
              return (
                <Autocomplete
                  freeSolo
                  options={suffixes}
                  value={field.value}
                  onChange={(event, value) => {
                    onChange(value)
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      {...field}
                      fullWidth
                      label={t('Suffix')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )
            }}
          />
        </Grid>

        <Grid item xs={6} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={12}>
              <Controller
                name="email"
                control={control}
                render={({ field: { ...field }, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      label={t('E Mail')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => inviteHandle()} edge="end">
                              <InviteIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="gender"
                control={control}
                render={({ field: { value, ...field }, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      select
                      label={t('Gender')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={value || ''}
                      {...field}
                    >
                      {genders.map(name => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="ethnicity"
                control={control}
                render={({ field: { value, ...field }, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      select
                      label={t('Ethnicity')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={value || ''}
                      {...field}
                    >
                      {etnicities.map(name => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="dateOfBirth"
                control={control}
                render={({ field, fieldState }) => {
                  const datePickerSx = fieldState.error ? { label: { color: 'red' } } : {}
                  return (
                    <DatePicker
                      fullWidth
                      sx={datePickerSx}
                      slotProps={{
                        actionBar: {
                          actions: ['clear'],
                        },
                        field: {
                          fullWidth: true,
                          // margin: 'dense',
                          // variant: 'standard',
                          InputLabelProps: { shrink: true },
                        },
                      }}
                      label={t('Date of Birth')}
                      // margin='dense'
                      // variant='standard'
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      {...field}
                      value={field.value && dayjs(field.value)}
                      minDate={data?.id ? null : dayjs().add(employmentType == 'student' ? -22 : -85, 'year')}
                      maxDate={dayjs().add(employmentType == 'student' ? -3 : -15, 'year')}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="ssn"
                control={control}
                render={({ field: { ...field }, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      label={t("SSN")}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid item xs={12} md={6}>
            <Controller
              name="raceTypeIds"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <FormControl sx={{ width: 300 }}>
                    <InputLabel id="demo-multiple-chip-label" sx={{ width: 300, mb: 1 }}>
                      Race
                    </InputLabel>
                    <Select
                      fullWidth
                      multiple
                      label={t('Race')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={value}
                      {...field}
                      renderValue={selected => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected?.map(select => {
                            const found = raceTypes?.find(race => race.id === select)

                            return found ? <Chip key={select} variant="outlined" label={`${found.name}`} /> : null
                          })}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {raceTypes?.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )
              }}
            />
          </Grid>
        </Grid>

        <Grid item md={12}>
          <PhoneList control={control} personId={data?.id} path="phones" {...phoneArrayControl} />
        </Grid>
        <Grid item md={12}>
          <SocialList control={control} person={data?.id} path="socials" {...socialArrayControl} />
        </Grid>
        <Grid item md={12}>
          <AddressList control={control} person={data?.id} path="addresses" {...addressArrayControl} />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Button onClick={() => phoneArrayControl.append({ type: '', number: '' })}>+ {t('Phone')}</Button>
            <Button
              onClick={() =>
                addressArrayControl.append({ type: '', street: '', city: '', state: '', zip: '', country: '' })
              }
            >
              + {t('Address')}
            </Button>
            <Button onClick={() => socialArrayControl.append({ type: '', address: '' })}>+ {t('Social')}</Button>
            <Divider orientation="vertical" flexItem />

            <Button variant="contained" color="secondary" onClick={onDelete} startIcon={<DeleteIcon />}>
              {t('Delete')}
            </Button>
            <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
              {t('Submit')}
            </Button>
            <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}
