import { TextField, Grid, IconButton, MenuItem, InputAdornment } from '@mui/material'
import Confirm from '../../../components/Dialog'
import { Controller } from 'react-hook-form'

import RemoveIcon from '@mui/icons-material/HighlightOff'
import { useState } from 'react'
import { deleteSocial } from '../../../store/person'
import { useDispatch } from 'react-redux'
import { useEmploymentType, usePerson } from '../../../components/Providers'
import { useTranslation } from 'react-i18next'

export default function SocialEditForm({ path, control, item, index, ...props }) {
  const employmentType = useEmploymentType()
  const person = usePerson()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  async function remove(id, index, confirmed = false) {
    try {
      if (!id) {
        props.remove(index)
        return
      }
      if (!confirmed) {
        setOpen(true)
        return
      }
      setOpen(false)
      const res = await dispatch(deleteSocial({ employmentType, id, personId: person.id }))
      if (res.error) {
        console.error(res)
        throw new Error('Error')
      }
      props.remove(index)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Grid item xs={12} sm={6} key={item.id}>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <Controller
            name={`${path}.${index}.type`}
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  fullWidth
                  select
                  label={t("@Social")}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                >
                  <MenuItem value="twitter">Twitter</MenuItem>
                  <MenuItem value="facebook">Facebook</MenuItem>
                  <MenuItem value="instagram">Instagram</MenuItem>
                  <MenuItem value="linkedin">LinkedIn</MenuItem>
                </TextField>
              )
            }}
          />
        </Grid>
        <Grid item md={9}>
          <Controller
            name={`${path}.${index}.address`}
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  fullWidth
                  label={t("Address")}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => remove(item.id, index)} edge="end">
                          <RemoveIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )
            }}
          />
        </Grid>
      </Grid>
      <Confirm open={open} onClose={() => setOpen(false)} onConfirm={() => remove(item.id, index, true)} />
    </Grid>
  )
}
