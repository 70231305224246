import { Box, Tabs, Tab } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'


const COURSESESSION = 0
const STUDENTS = 1
const RESULTS = 2
const GRADES = 3

const getTabIndex = path => {
  const [tabName] = path.split('/')
  const i = ['course-session', 'students', 'results', 'grades'].indexOf(tabName)
  return i < 0 ? 0 : i
}

export default function MyCoursesTabs() {
  const theme = useSelector(state => state.session.appearance)
  const { t } = useTranslation()
  const { '*': tabName } = useParams()
  const [tabIndex, setTabIndex] = useState(getTabIndex(tabName))

  useEffect(() => {
    setTabIndex(getTabIndex(tabName))
  }, [tabName])

  const basePath = `/academic/my-courses`

  return (
    <Box>
      <Tabs
        value={tabIndex}
        TabIndicatorProps={{ style: { background: 'primary.main' } }}
      >
        <Tab
          sx={{ color: tabIndex === 0 && 'primary.main' }}
          className={tabIndex === 1 ? `tab-item-selected` : `tab-item-${theme}`}
          label={t('Course Sessions')}
          value={COURSESESSION}
          component={Link}
          to={`${basePath}/course-session`}
        />
        <Tab
          sx={{ color: tabIndex === 1 && 'primary.main' }}
          className={tabIndex === 1 ? `tab-item-selected` : `tab-item-${theme}`}
          label={t('Students')}
          value={STUDENTS}
          component={Link}
          to={`${basePath}/students`}
        />
        <Tab
          sx={{ color: tabIndex === 2 && 'primary.main' }}
          className={tabIndex === 2 ? `tab-item-selected` : `tab-item-${theme}`}
          label={t('Results')}
          value={RESULTS}
          component={Link}
          to={`${basePath}/results`}
        />
        <Tab
          sx={{ color: tabIndex === 2 && 'primary.main' }}
          className={tabIndex === 2 ? `tab-item-selected` : `tab-item-${theme}`}
          label={t('Grades')}
          value={GRADES}
          component={Link}
          to={`${basePath}/grades`}
        />
      </Tabs>
    </Box>
  )
}
