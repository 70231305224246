import { Autocomplete, LinearProgress, TextField } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllStaff } from "store/lookup";

export default function StaffSearch({
  onSelect = () => {},
  label = 'Select Teacher',
  size = 'small',
  value= null,
  disabled = false,
  error = false,
  helperText = '',
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { academicYear } = useSelector(state => state.session);
  const allStaff  = useSelector(state => state.lookup.allStaff.rows)

  useEffect(() => {
    allStaff || dispatch(getAllStaff({ limit: 1000 }))
  }, [dispatch, academicYear, Boolean(allStaff)]);
  
  const selectStaff = (event, value) => {
    onSelect(value);
  };

  if (!allStaff) return <LinearProgress />

  return (
    <Autocomplete
    fullWidth
    disablePortal
    size={size}
    value={value}
    disabled={disabled}
    options={allStaff}
    onChange={selectStaff}
    getOptionLabel={option => {
      if (option?.person?.lastName && option?.person?.firstName) {
        return `${option?.person?.lastName}, ${option?.person?.firstName}`
      } else {

        const staffMember = allStaff.find(staff => 
          staff?.schoolEnrollmentId === +option || staff?.personId === +option
        );

        if (staffMember?.person?.lastName && staffMember?.person?.firstName) {
          return `${staffMember.person.lastName}, ${staffMember.person.firstName}`;
        } else {
          return '';
        }
          }
        }}
    renderInput={params => (
      <TextField
        {...params}
        fullWidth
        label={t(label)}
        disabled={disabled}
        error={error}
        helperText={helperText}
      />
      
    )}
    />
  );
}
