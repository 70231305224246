import { Box, Grid, LinearProgress, Link, Paper, Stack, Typography } from '@mui/material'
import { DAYS } from 'constants'
import './Schedule.scss'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import UnScheduleList from './UnScheduleList'
import ScheduleDays from './ScheduleDays'
import SchedulePeriods from './SchedulePeriods'
// import { search } from 'store/settings/periods'
import { getPeriods } from 'store/lookup'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { orange } from '@mui/material/colors';


dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

const dayListHeight = 50
const periodListWidth = 170

export default function ScheduleTable({ hours, saturday, sunday, unScheduledSessions, scheduledSessions }) {

  const permission = useSelector(state => state.session.permissions)

  const hasCourseSessionViewPermission = parseInt(permission['setting.course-session'])

  const dispatch = useDispatch()
  const periods = useSelector(state => state.lookup.periods)
  const currentDay = dayjs().day()

  const getBackgroundColor = (dayIndex) => {
    return dayIndex === currentDay ? orange[100] : 'none'
  }

  useEffect(() => {
    periods || dispatch(getPeriods())
  }, [dispatch, Boolean(periods)])

  if (!periods) {
    return <LinearProgress color="primary" />
  }
    
  return (
      <Box id="student-detail-page-schedule" sx={{ m: 2, overflow: 'auto' }} display={'flex'} flexDirection={'row'}>
        <SchedulePeriods periodListWidth={periodListWidth} hours={hours} periods={periods} />
        <Box sx={{ width: '100%' }} >
          <ScheduleDays saturday={saturday} sunday={sunday} dayListHeight={dayListHeight} />
          <Grid container  >
            {DAYS.slice(1, 6).map((day, dayIndex) => (
              <Grid item xs key={dayIndex} >
                {periods.map((period) => (
                  <Stack
                    key={period.id}
                    direction={'row'}
                    pl={'2px'}
                    marginTop={'2px'}
                    sx={{
                      height: period?.type === 'lunch' ? '80px' : '120px',                      
                    }}
                  >
                    {scheduledSessions
                      .filter((courseSession) => {
                        const dayMatch = courseSession.dayOfWeek === dayIndex + 1;
                        const sessionStartTime = dayjs(courseSession.startTime, 'HH:mm:ss');
                        const sessionEndTime = dayjs(courseSession.endTime, 'HH:mm:ss');
                        const periodStartTime = dayjs(period.startTime, 'HH:mm:ss');
                        const periodEndTime = dayjs(period.endTime, 'HH:mm:ss');
                        const periodMatch =
                          sessionStartTime.isSameOrAfter(periodStartTime) &&
                          sessionEndTime.isSameOrBefore(periodEndTime);

                        return dayMatch && periodMatch;
                      })
                      .map((courseSession, index) => (
                        <Box
                          key={index}
                          component={Paper}
                          sx={{
                            display: 'flex',
                            p: '10px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            width: '100%',
                            backgroundColor: courseSession.list.length > 1 ? 'error.light' : getBackgroundColor(dayIndex + 1),
                            border: '1px solid',
                            borderColor: 'primary.main',
                            borderRadius: '10px',
                          }}
                        >
                          <Box>
                            {courseSession.list.length === 1 && (
                              <Box
                                sx={{
                                  width: '100%',
                                  textAlign: 'center',
                                }}
                              >
                                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                                  {hasCourseSessionViewPermission ? (
                                    <Link
                                      component={NavLink}
                                      underline="hover"
                                      color="inherit"
                                      to={`/settings/scheduling/course-sessions/${courseSession.list[0].id}`}
                                    >
                                      {courseSession.list[0].name}
                                    </Link>
                                  ) : (
                                    <>{courseSession.list[0].name}</>
                                  )}
                                </Typography>
                                  {courseSession.list[0].teachers.split(' | ').map((teacher, index) => (
                                <Typography key={index} variant="body2">{teacher}</Typography>
                                  ))}
                                <Typography variant='body2'>{courseSession.list[0].room ? courseSession.list[0].room.name : ' - '}</Typography>
                              </Box>
                            )}

                            {courseSession.list.length > 1 && (
                              <Box
                                sx={{
                                  width: '100%',
                                  textAlign: 'center',
                                }}
                              >
                                <Typography variant="body1" sx={{ fontWeight: 600, color: 'white' }}>
                                  COURSE CONFLICT
                                </Typography>
                                {courseSession.list.map((item) => (
                                  <Box key={item.id} >
                                    <Typography variant="body2" sx={{ color: 'white'}}>
                                    {hasCourseSessionViewPermission ? (
                                      <Link
                                        component={NavLink}
                                        underline="hover"
                                        color="inherit"
                                        to={`/settings/scheduling/course-sessions/${item.id}`}
                                      >
                                        {item.name}
                                      </Link>
                                    ) : (
                                      <>{item.name}</>
                                    )}
                                    </Typography>
                                  </Box>
                                ))}
                              </Box>
                            )}

                          </Box>
                        </Box>
                      ))}
                  </Stack>
                ))}
              </Grid>
            ))}
          </Grid>
          <UnScheduleList list={unScheduledSessions} permission={hasCourseSessionViewPermission} />
        </Box>
      </Box>
  )
}
