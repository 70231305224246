import { TableCell, TableRow } from '@mui/material'
import { useSession } from 'components/Providers'

export default function CourseResultListItem({ item }) {
  const { academicYear } = useSession()
  const markingPeriods = academicYear?.markingPeriods

  return (
    <TableRow hover>
      <TableCell>{item?.schoolEnrollment?.person?.lastName}, {item?.schoolEnrollment?.person?.firstName}</TableCell>
      {markingPeriods?.map(mp => {
        const gradeData = item.markingPeriodGrades?.find(mpg => mpg.markingPeriodId === mp.id);
        const grade = gradeData?.grade;
        const gradeLetter = gradeData?.gradeLetter;

        return (
          <TableCell key={mp.id} sx={{ backgroundColor: grade === undefined ? '#ccc' : undefined, borderLeft: grade === undefined ? '1px solid white' : undefined }}>
            {gradeLetter ? `${gradeLetter}${grade ? ` (${grade})` : ''}` : grade || ''}
          </TableCell>
        );
      })}
      <TableCell>{`${item?.finalGradeLetter} (${item?.grade})` || '-'}</TableCell>
    </TableRow>
  )

}
