import client from '../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../utils/redux-tool'

const name = 'person-attendance-period'
const initialState = {
  list: null,
  count: null,
  query: {},
}
export const search = createApiAction(
  'person/attendance/period/search',
  client.search('/api/:employmentType/:personId/attendance/period'),
)
export const get = createApiAction(
  'person/attendance/period/get',
  client.update('/api/:employmentType/:personId/attendance/period/:id'),
)
export const save = createApiAction(
  'person/attendance/period/save',
  client.save('/api/:employmentType/:personId/attendance/period'),
)
export const destroy = createApiAction(
  'person/attendance/period/delete',
  client.destroy('/api/:employmentType/:personId/attendance/period/:id'),
)
const updateList = (state, action) => {
  const found = state.list.find(item => item.id === action.payload.id)
  state.list = found
    ? state.list.map(item => (found.id === item.id ? action.payload : item))
    : [action.payload, ...state.list]
}

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearPersonAttendancePeriod: state => {
      state.list = null
      state.query = {}
      state.count = null
    },
  },
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        state.query = {
          personId: action.meta.arg.personId,
          include: action.meta.arg?.include || null,
        }
        state.list = action.payload
      })
      .addCase(get.fulfilled, updateList)
      .addCase(save.fulfilled, updateList)
  },
})
export const { clearPersonAttendancePeriod } = sessionSlice.actions

export default sessionSlice.reducer
