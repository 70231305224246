// import { useTheme } from '@emotion/react'
import { Box, Chip, Stack, Typography } from '@mui/material'

import dayjs from 'dayjs'

export default function SchedulePeriods({ periodListWidth, periods }) {
  // const theme = useTheme()

  return (
    <Box className="period-list" sx={{ width: `${periodListWidth}px`, marginRight: '2px' }}>
      <Box sx={{ display: 'flex', p: '10px', alignItems: 'center', border: '1px solid', borderColor: 'primary.main', borderRadius: '10px', backgroundColor: 'white' }}>
        <Box sx={{ backgroundColor: 'primary.light', p: '10px', width: '100%', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="body1">Periods</Typography>
        </Box>
      </Box>
      {periods.map((period, index) => (
        <Stack
          height={period?.type === 'lunch' ? '80px' : '120px'}
          width={`${periodListWidth}px`}
          // sx={
          //   index % 2 === 0
          //     ? { backgroundColor: theme.palette.action.disabled, marginBottom: '1px' }
          //     : { backgroundColor: theme.palette.action.hover, marginBottom: '1px' }
          // }
          key={index}
          direction={'row'}
          marginTop={'2px'}
          spacing={1}
        >
          <Box id={period.id} key={index} sx={{ display: 'flex', p: '10px', alignItems: 'center', border: '1px solid', borderColor: 'primary.main', borderRadius: '10px', width: '100%', backgroundColor: 'white' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: 'primary.light', p: '10px', height: '100%', borderRadius: '10px', justifyContent: 'center', textAlign: 'center' }}>
              {period?.name}
              <Chip
                label={`${dayjs(period?.startTime, 'HH:mm:ss').format('h:mm A')} - ${dayjs(period?.endTime, 'HH:mm:ss').format('h:mm A')}`}
                size="small"
                sx={{
                  fontSize: '10px', 
                  height: 20,        
                  padding: '0 6px',  
                  backgroundColor: '#F7D488',
                }}
              />
            </Box>
          </Box>
        </Stack>
      ))}
    </Box>
  )
}
