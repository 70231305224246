import client from '../../utils/api'
import { createApiAction } from '../../utils/redux-tool'

const basePath = '/api/member/:type'

export const retrieve = createApiAction('member/person/retrieve', client.retrieve(`${basePath}/:memberId`))
export const save = createApiAction('member/person/save', client.update(`${basePath}/:memberId`))
export const create = createApiAction('member/person/create', client.save(`${basePath}`))
export const destroy = createApiAction('member/person/destroy', client.destroy(`${basePath}/:memberId`))

export const sendInvitation = createApiAction('member/person/invite', client.create(`${basePath}/:memberId/'invite'`))

export const deletePhone = createApiAction(
  'member/person/phone/delete',
  client.destroy(`${basePath}/:memberId/person/phone/:id`),
)

function foundMemberIndex(state, action) {
  const list = state.list || []
  const { memberId } = action.meta.arg
  const member = list.find(item => item.id === memberId)
  const memberIndex = list.indexOf(member)

  if (member) return { memberIndex, member }
  else throw new Error(`Member with id ${memberId} not found.`)
}
const updatePerson = (state, action) => {
  const { member, memberIndex } = foundMemberIndex(state, action)
  if (!member) throw new Error(`Member with id ${member.id} not found.`)
  state.list[memberIndex].person = action.payload.person
  state.list[memberIndex].academicYearEnrollment = action.payload.academicYearEnrollment
  state.list[memberIndex].schoolEnrollment = action.payload.schoolEnrollment
  state.list[memberIndex].stateData = action.payload.stateData
  state.list[memberIndex].employment = action.payload.employment

}

const updatePhone = (state, action) => {
  const { member, memberIndex } = foundMemberIndex(state, action)
  const { id } = action.meta.arg
  if (!member) throw new Error(`Member with id ${member.id} not found.`)
  state.list[memberIndex].person.phones.filter(item => item.id !== id)
}

export default function setPersonalReducers(builder) {
  builder
    .addCase(retrieve.fulfilled, updatePerson)
    .addCase(save.fulfilled, updatePerson)
    .addCase(deletePhone.fulfilled, updatePhone)
}
