import { Box, Grid, MenuItem, Stack, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import Header from 'components/Header'
import dayjs from 'dayjs'
import { useEffect, useMemo, } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FaSave } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getCourseSessionsEnrollmentType } from 'store/lookup'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { get as getCourseSessionEnrollments } from 'store/settings/course-session-enrollments'
import { save as saveCourseSessionEnrollments } from 'store/settings/course-session-enrollments'
import { saveCourseSessionEnrollment } from 'store/settings/course-session'
import { search } from 'store/settings/course-session-enrollments'
import { AddPersonButton } from 'components/Buttons'
import { get } from 'store/settings/course-session'
import StaffSearch from 'components/StaffSearch'

const initialValues = {
  schoolEnrollmentId: null,
  roleId: null,
  substitute: null,
  startDate: null,
  endDate: null,
}

export default function SchedulingCourseSessionsFormAddTeacher() {
  const { courseSessionId, courseSessionEnrollmentId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { academicYear } = useSelector(state => state.session)

  const { t } = useTranslation()
  const staffRole = useSelector(state => state.lookup?.courseSessionsEnrollmentType)
  const courseSessionsEnrollmentList = useSelector(state => state.settings?.courseSessionsEnrollments?.list)

  const basePath = `/settings/scheduling/course-sessions/`

  const schema = yup.object().shape({
    schoolEnrollmentId: yup.number().required('Required'),
    startDate: yup.date().required('Required'), // TODO: Backend'de modelde not null olarak işaretlenmiş
  })

  const { control, handleSubmit, getValues, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...initialValues },
  })

  useEffect(() => {
    if (+courseSessionEnrollmentId) {
      dispatch(getCourseSessionEnrollments({ id: +courseSessionEnrollmentId, courseSessionId: +courseSessionId })).then(
        response => {
          setValue('startDate', response?.payload?.startDate, { shouldDirty: true })
          setValue('endDate', response?.payload?.endDate, { shouldDirty: true })
          setValue('roleId', response?.payload?.courseSessionEnrollmentType?.id, { shouldDirty: true })
          setValue('schoolEnrollmentId', response?.payload?.schoolEnrollment?.id, { shouldDirty: true })
          setValue('substitute', response?.payload?.substitute ? 'yes' : 'no', { shouldDirty: true })
        },
      )
    } else {
      setValue('startDate', dayjs(new Date()), { shouldDirty: true })
      setValue('endDate', null, { shouldDirty: true })
      setValue('roleId', null, { shouldDirty: true })
      setValue('schoolEnrollmentId', null, { shouldDirty: true })
      setValue('substitute', null, { shouldDirty: true })
    }
  }, [courseSessionEnrollmentId])

  const onSubmit = async () => {
    const data = getValues()
    const payload = {
      schoolEnrollmentId: [data?.schoolEnrollmentId],
      startDate: data?.startDate ? dayjs(data?.startDate).format('YYYY-MM-DD') : null,
      endDate: data?.endDate ? dayjs(data?.endDate).format('YYYY-MM-DD') : null,
      roleId: data?.roleId,
      substitute: data?.substitute === 'yes' ? true : false,
    }
    if (+courseSessionEnrollmentId) {
      delete payload.schoolEnrollmentId
      const res = await dispatch(
        saveCourseSessionEnrollments({ id: +courseSessionEnrollmentId, courseSessionId, ...payload }),
      )
      if (res.payload) {
        dispatch(saveCourseSessionEnrollment({ courseSessionId, ...res.payload }))
        navigate(`${basePath}${courseSessionId}/`)
      }
    } else {
      const res = await dispatch(saveCourseSessionEnrollments({ courseSessionId, ...payload }))
      if (res.payload) {
        dispatch(saveCourseSessionEnrollment({ courseSessionId, ...res.payload }))
        navigate(`${basePath}${courseSessionId}/`)
      }
    }
    if (+courseSessionId) {
      await dispatch(get({ id: courseSessionId }))
      await dispatch(search({ courseSessionId: +courseSessionId, academicYearId: academicYear.id }))
    }
  }

  const onErrors = errors => console.log(errors)

  useEffect(() => {
    staffRole || dispatch(getCourseSessionsEnrollmentType())
  }, [Boolean(staffRole), dispatch])

  const selectedTeacher = useMemo(
    () => courseSessionsEnrollmentList.find(item => item.id === +courseSessionEnrollmentId),
    [courseSessionsEnrollmentList, courseSessionEnrollmentId]
  )

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit, onErrors)} noValidate>
      <Header title={t('Add Teacher')} small close borderBottom />
      <Grid container sx={{ p: 3 }} spacing={2}>
        <Grid item xs={12} md={12}>
          <Controller
            name="schoolEnrollmentId"
            control={control}
            rules={{ required: 'Required' }}
            render={({ field: { onChange, ...field }, fieldState }) => {

              return (
                <StaffSearch 
                  isOptionEqualToValue={(option, value) => option?.schoolEnrollmentId === value}
                  onSelect={teacher => onChange(teacher ? teacher.schoolEnrollmentId : null)}
                  value={selectedTeacher}
                  disabled={+courseSessionEnrollmentId && true}
                  {...field}
                  error={fieldState.error}
                  helperText={fieldState.error ? fieldState.error.message : ''}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="roleId"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  select
                  value={getValues('roleId')}
                  fullWidth
                  size="small"
                  label={t('Teacher Role')}
                  InputLabelProps={{
                    shrink: getValues('roleId') ? true : false,
                  }}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                >
                  {staffRole.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="substitute"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  select
                  fullWidth
                  size="small"
                  label={t('Substitute Required')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  InputLabelProps={{
                    shrink: getValues('substitute') ? true : false,
                  }}
                  {...field}
                >
                  <MenuItem value="no">{t('No')}</MenuItem>
                  <MenuItem value="yes">{t('Yes')}</MenuItem>
                </TextField>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="startDate"
            control={control}
            render={({ field, fieldState }) => {
              const datePickerSx = fieldState.error ? { label: { color: 'red' } } : {}
              return (
                <DatePicker
                  required
                  sx={datePickerSx}
                  slotProps={{
                    actionBar: {
                      actions: ['clear'],
                    },
                    textField: { size: 'small' },
                    field: {
                      fullWidth: true,
                      InputLabelProps: { shrink: true },
                    },
                  }}
                  label={t('Start Date')}
                  InputLabelProps={{ shrink: true }}
                  {...field}
                  value={field.value && dayjs(field.value)}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="endDate"
            control={control}
            render={({ field, fieldState }) => {
              const datePickerSx = fieldState.error ? { label: { color: 'red' } } : {}
              return (
                <DatePicker
                  required
                  sx={datePickerSx}
                  slotProps={{
                    actionBar: {
                      actions: ['clear'],
                    },
                    textField: { size: 'small' },
                    field: {
                      fullWidth: true,
                      InputLabelProps: { shrink: true },
                    },
                  }}
                  label={t('End Date')}
                  InputLabelProps={{ shrink: true }}
                  {...field}
                  value={field.value && dayjs(field.value)}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} textAlign={'right'}>
          <Stack flexDirection={'row'} justifyContent={'flex-end'} alignItems={'flex-end'}>
            <AddPersonButton
              onClick={onSubmit}
              size="small"
              variant="contained"
              color="primary"
              title={t('Submit')}
              startIcon={<FaSave />}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

