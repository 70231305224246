import { Box, LinearProgress } from '@mui/material'
import { BasePathProvider, useSession } from 'components/Providers'
import AttendanceStudent from './StudentAttendance'
import  AttendanceStaff  from '../../Settings/Attendance/Teacher'
import PageHeader from 'components/AppBar/PageHeader'

const baseUrl = '/academic/attendance'

export const Attendance = () => {
  const {
    person
  } = useSession()

  if (!person.type) return <LinearProgress color="primary" />

  return (
    <Box>
      <BasePathProvider value={baseUrl}>
        <Box sx={{ width: '100%', mb: 2 }}>
          <Box sx={{ pb: 2 }}>
            <PageHeader title={'Attendance'} />
          </Box>
          {person.type === 'student' ? <AttendanceStudent /> : <AttendanceStaff  defaultTeacher={person} />}
        </Box>
      </BasePathProvider>
    </Box>
  )
}
