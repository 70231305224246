import { useDispatch, useSelector } from 'react-redux'
import CourseSessionEnrolledEditForm from './CourseSessionEnrolledEditForm'
import CourseSessionEnrolledEditFormSkeleton from './CourseSessionEnrolledEditFormSkeleton'
import { useParams, useSearchParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { getPeople } from 'store/lookup'

import { get as getEnrollmentDetail } from 'store/settings/course-session-enrollments'
import { debounce } from 'lodash'

const CourseSessionEnrolledEditFormWrapper = () => {
  const dispatch = useDispatch()

  const { courseSessionId } = useParams()
  const { enrollmentId: id } = useParams()

  const [searchParams] = useSearchParams()

  const enrolled = useSelector(state => state.settings?.courseSessionsEnrollments).list

  const [data, setData] = useState()
  const [dataBackup, setDataBackup] = useState()

  const fetchPersonList = async value => {
    if (value) {
      fetch(value, () => {})
    } else {
      afterFetchPersonList(dataBackup)
    }
  }

  const fetch = useMemo(
    () =>
      debounce(async value => {
        const res = await dispatch(getPeople({ scope: 'academicYear', type: searchParams.get('type'), que: value }))
        if (res.payload) {
          afterFetchPersonList(res)
        }
      }, 400),
    [dispatch],
  )

  const afterFetchPersonList = data => {
    let enrolledSchoolEnrollmentIds = []
    const persons = enrolled?.filter(
      item => item.schoolEnrollment?.employment?.type === String(searchParams.get('type')),
    )
    persons.forEach(item => {
      enrolledSchoolEnrollmentIds.push(item.schoolEnrollment.id)
    })
    let temp = []
    if (data?.payload?.length > 0) {
      data.payload.forEach(item => {
        if (!enrolledSchoolEnrollmentIds.includes(item?.employments[0]?.schoolEnrollments[0]?.id)) {
          temp.push(item)
        }
      })
      setData({ type: String(searchParams.get('type')), list: temp })
    }
  }

  useEffect(() => {
    if (isNaN(parseInt(id))) {
      if (searchParams.get('type')) {
        const getData = async () => {
          const data = await dispatch(getPeople({ scope: 'academicYear', type: searchParams.get('type') }))
          setDataBackup(data)
          afterFetchPersonList(data)
        }
        getData()
      }
    } else {
      const getData = async () => {
        const rawData = await dispatch(getEnrollmentDetail({ courseSessionId, id }))
        const data = [
          {
            id: rawData.payload.schoolEnrollment?.employment?.person?.id,
            firstName: rawData.payload.schoolEnrollment?.employment?.person?.firstName,
            lastName: rawData.payload.schoolEnrollment?.employment?.person?.lastName,
            startDate: rawData.payload.startDate,
            endDate: rawData.payload.endDate,
            courseSessionEnrollmentType: rawData.payload.CourseSessionEnrollmentType,
            employments: [
              {
                schoolEnrollments: [
                  {
                    id: rawData.payload.schoolEnrollment.id,
                    startDate: rawData.payload.schoolEnrollment.startDate,
                    endDate: rawData.payload.schoolEnrollment.endDate,
                  },
                ],
                employmentType: rawData.payload.schoolEnrollment.employment.type,
              },
            ],
          },
        ]
        setData({ type: id, list: data })
      }
      getData()
    }
  }, [id, searchParams])

  return data ? (
    <CourseSessionEnrolledEditForm data={data} fetchPersonList={fetchPersonList} />
  ) : (
    <CourseSessionEnrolledEditFormSkeleton />
  )
}

export default CourseSessionEnrolledEditFormWrapper
