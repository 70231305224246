import { Box, Chip, Typography } from '@mui/material'
import ProfilePicture from './ProfilePicture'
import Header from 'components/Header'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { BasePathProvider, useBasePath } from 'components/Providers'

export default function StudentHeader() {
  const { t } = useTranslation()
  const ids = useSelector(state => state.academic.student.list?.map(p => p.id))
  const { '*': tabName } = useParams()
  const navigate = useNavigate()
  const student = useSelector(state => state.academic.student.detail)
  const basePath = useBasePath()

  const page = dir => {
    if (!ids) return
    const idx = ids.indexOf(student.id)
    const n = idx + dir
    if (n < 0 || n >= ids.length) return
    if (tabName) {
      navigate([basePath, ids[n], tabName].join('/'))
    } else navigate([basePath, ids[n]].join('/'))
  }

  const gradeLevelId = student?.academicYearEnrollment?.gradeLevelId
  const gradeSection = student?.academicYearEnrollment?.gradeLevelSection?.gradeSection
  const idTitle = student?.employments?.type === 'student' ? 'Student ID' : 'Staff ID'

  return (
    <BasePathProvider value={`${basePath}/`}>
      <Header
        avatar={<ProfilePicture student={student} />}
        title={student ? `${student.person.firstName}  ${student?.person?.middleName ?? ''} ${student.person.lastName}` : t('New Person')}
        prev={() => page(-1)}
        next={() => page(1)}
        close
      >
        <Box>
          <Typography variant="body1" gutterBottom>
            <Typography component="span" display="inline-block" sx={{ mr: 2 }}>
              {t('Grade Section')}: {gradeLevelId} {gradeSection}
            </Typography>
            <Typography component="span" display="inline-block">
              {idTitle}: {student?.schoolEnrollment?.enrollmentId}
            </Typography>
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t('Status')}:
            <Chip
              label={student?.schoolEnrollment?.endDate ? t('Unenrolled') : t('Enrolled')}
              size="small"
              color={student?.schoolEnrollment?.endDate ? 'default' : 'success'}
              sx={{ ml: 1 }}
            />
          </Typography>
        </Box>
      </Header>
    </BasePathProvider>
  )
}
