import { Route, Routes } from 'react-router-dom'
import { Box, Paper } from '@mui/material'
import CourseSessionList from './CourseSession/CourseSessionList'
import { BasePathProvider } from 'components/Providers'
import PageHeader from 'components/AppBar/PageHeader'
import MyCoursesTabs from './Tabs'
import { search } from 'store/academic/course-session'
import CourseStudentList from './Students/CourseStudentList'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import CourseResultList from './Results/CourseResultList'
import Detail from './CourseSession/detail'

const baseUrl = `/academic/my-courses`


export default function MyCoursesPage() {
  const academicYear = useSelector(state => state.session.academicYear)
  const courseSessionList = useSelector(state => state.academic.courseSession.list)
  const dispatch = useDispatch()
  // const navigate = useNavigate()

  useEffect(() => {
    courseSessionList || dispatch(search())
  }, [dispatch, academicYear?.id, Boolean(courseSessionList)])

  // const handleClose = () => {
  //   navigate(baseUrl)
  // }

  return (
    <BasePathProvider value={`${baseUrl}/`}>
        <Box sx={{ pb: 2 }}>
          <PageHeader title={'My Courses'} />
        </Box>
        <Box sx={{ mb: '5px' }}>
          <Paper sx={{ py: 1, px: 2 }}>
            <MyCoursesTabs />
          </Paper>
        </Box>
        <Routes>
          <Route path="/">
            <Route path="" element={<CourseSessionList list={courseSessionList} />} />
            <Route path="/course-session/*" element={<CourseSessionList list={courseSessionList} />} />
            <Route path="/students/" element={<CourseStudentList courseSessionList={courseSessionList} />} />
            <Route path="/results/*" element={<CourseResultList courseSessionList={courseSessionList} />} />
            <Route path="/grades/*" element={<Detail courseSessionList={courseSessionList} />} />
          </Route>
        </Routes>
      {/* <Drawer anchor="right" open={Boolean(courseSessionId)} onClose={handleClose}>
        <BasePathProvider value={`${baseUrl}/${courseSessionId}`}>
          <Detail />
        </BasePathProvider>
      </Drawer> */}
    </BasePathProvider>
  )
}
