import { useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'

import { getUserRoles, setUserRoles } from '../../../store/person'
import { useDispatch, useSelector } from 'react-redux'
import { SubTitle } from '../../../components/typo'
import { useEmploymentType, usePerson } from '../../../components/Providers'
import { useTranslation } from 'react-i18next'
import { LinearProgress } from '@mui/material'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  }
}

export default function UserRoles() {
  const theme = useTheme()
  const { search: roles } = useSelector(state => state.settings.role)
  const person = usePerson()
  const employmentType = useEmploymentType()
  const dispatch = useDispatch()
  const userRoles = useSelector(state =>
    employmentType === 'staff' ? state.person.staff.selected?.userRoles : state.person.student.selected?.userRoles,
  )
  const selectedRoles = userRoles && userRoles.map(i => i.roleId)
  const { t } = useTranslation()
  const handleChange = async event => {
    const value = event.target.value
    dispatch(setUserRoles({ employmentType, personId: person.id, roles: value }))
  }

  useEffect(() => {
    selectedRoles || dispatch(getUserRoles({ employmentType, personId: person.id }))
  }, [dispatch, employmentType, person.id, selectedRoles])

  // useEffect(() => {
  //   const fetch = async () => {
  //     const { payload, error } = await dispatch(getUserRoles({ employmentType, personId: person.id }))
  //     if (error) {
  //       console.error(error)
  //     }
  //     if (payload) {
  //       setSelectedRoles(payload.map(i => i.roleId))
  //     }
  //   }
  //   fetch()
  // }, [dispatch, employmentType, person.id])

  return userRoles ? (
    selectedRoles ? (
      <Box sx={{ m: 2 }}>
        <SubTitle>{t('User Roles')}</SubTitle>
        <FormControl sx={{ m: 0, minWidth: '100%' }}>
          <Select
            multiple
            value={selectedRoles}
            onChange={handleChange}
            renderValue={selected => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map(value => {
                  const role = roles.find(r => r.id === value)

                  return role ? (
                    <Chip
                      key={value}
                      variant="outlined"
                      color={role.enabled ? (role.shared ? 'primary' : 'default') : 'error'}
                      label={`${role.name}`}
                    />
                  ) : null
                })}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {roles.map(role => (
              <MenuItem key={role.id} value={role.id} style={getStyles(role.name, selectedRoles, theme)}>
                {role.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    ) : null
  ) : (
    <LinearProgress sx={{ m: 2 }} color="success" />
  )
}
