import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import CourseEnrollmentItem from './Item'
import { useSession } from 'components/Providers'
import { useTranslation } from 'react-i18next'

export default function CourseSessionEnrollmentList({ enrollments, employmentType, person }) {
  const { academicYear, schoolEnrollment } = useSession()
  const { t } = useTranslation()

  return (
    <Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell> {t('Course')} </TableCell>
            <TableCell sx={{ width: '16em' }}> {t('Enroll Date')} </TableCell>
            <TableCell sx={{ width: '16em' }}> {t('Unenroll Date')} </TableCell>
            <TableCell sx={{ width: '8em' }}>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {academicYear?.id &&
            schoolEnrollment.id &&
            enrollments?.map(enrollment => (
              <CourseEnrollmentItem
                key={enrollment.id}
                enrollment={enrollment}
                employmentType={employmentType}
                person={person}
              />
            ))}
        </TableBody>
      </Table>
    </Box>
  )
}
