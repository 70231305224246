import { useParams } from 'react-router-dom'
import GradingFormScales from './Scales'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { retrieve } from 'store/settings/grading-scale'

export default function GradingFormIndex() {
  const dispatch = useDispatch()

  const { type, id } = useParams()
  const { coefficient } = useSelector(state => state?.settings?.gradingCoefficient)

  const [data, setData] = useState()

  const getScaleDetail = async () => {
    await dispatch(retrieve({ id })).then(response => {
      setData(response.payload)
    })
  }

  useEffect(() => {
    if (+id && type === 'scales') {
      getScaleDetail()
    }
  }, [id])

  if (+id && type === 'scales' && !data) return null  // Skeleton

  if (+id && type === 'coefficient' && !coefficient) return null // Skeleton

  if (type === 'scales') return <GradingFormScales data={data || null} getScaleDetail={getScaleDetail} />
}
