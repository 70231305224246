import { Button, TableCell, TableRow } from '@mui/material'
import Confirm from 'components/Dialog'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export default function SchedulingEnrollStudentListItem({ item, destroyEnrollment }) {
  const { t } = useTranslation()
  const [confirm, setConfirm] = useState({ status: false, id: null, courseSessionId: null })
  const academicYearMarkingPeriods = useSelector(state => state.session?.academicYear?.markingPeriods)
  const persons = useSelector(state => state?.settings?.courseSessionEnrollment?.person)

  const getTerm = mp => {
    if (academicYearMarkingPeriods?.length === 1 && mp?.length === 1) {
      return 'Summer'
    } else if (mp?.length === academicYearMarkingPeriods?.length) {
      return 'Full Year'
    } else {
      return mp?.map(item => item.name).toString()
    }
  }

  const getTeacherName = session => {
    const lastname = persons?.find(p => p.id === session?.teacherSchoolEnrollmentId)?.person?.lastName
    const firstname = persons?.find(p => p.id === session?.teacherSchoolEnrollmentId)?.person?.firstName
    if (lastname && firstname) {
      return `${lastname}, ${firstname}`
    }
    return null
  }

  return (
    <>
      <TableRow hover>
        <TableCell>{item?.courseSession?.course?.name}</TableCell>
        <TableCell>{item?.courseSession?.name}</TableCell>
        <TableCell>{getTeacherName(item)}</TableCell>
        <TableCell>{getTerm(item?.courseSession?.markingPeriods)}</TableCell>
        <TableCell>
          {item?.enrollmentCount}/{item?.courseSession?.quota || 0}
        </TableCell>
        <TableCell>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => setConfirm({ status: true, id: item?.id, courseSessionId: item?.courseSession?.id })}
          >
            {t('Unenroll')}
          </Button>
        </TableCell>
      </TableRow>
      <Confirm
        open={confirm.status}
        onClose={() => setConfirm({ status: false, id: null, courseSessionId: null })}
        onConfirm={() => {
          destroyEnrollment(confirm)
          setConfirm({ status: false, id: null, courseSessionId: null })
        }}
      />
    </>
  )
}
