import { useState } from 'react'
import { IconButton, TableCell, TableRow, Link, styled } from '@mui/material'
import dayjs from 'dayjs'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  CheckBoxOutlined as CheckedIcon,
  CheckBoxOutlineBlankRounded as UnCheckedIcon,
} from '@mui/icons-material'

import Confirm from 'components/Dialog'
import { useDispatch } from 'react-redux'
import { destroy } from 'store/member/document'
import { useBasePath, useMember } from 'components/Providers'
import client from 'utils/api'

const StyledTableRow = styled(TableRow)(() => {
  return {
    '&:last-child td, &:last-child th': {},
  }
})

export default function DocumentItem({ document }) {
  const [confirm, setConfirm] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const basePath = useBasePath()
  const member = useMember()
  const { employmentType, memberId } = useParams()
  const detailPath = `${basePath}/${document?.id}`

  const onDelete = () => {
    setConfirm(true)
  }

  const onConfirm = () => {
    setConfirm(false)
    dispatch(
      destroy({
        type: employmentType,
        memberId,
        personId: member?.person.id,
        id: document.id,
      }),
    )
  }

  const download = async event => {
    event.preventDefault()
    try {
      const { data: url } = await client.get(`/api/file/${document.file?.id}/url`)
      let a = window.document.createElement('a')
      a.href = url
      a.download = document.name
      a.target = '_blank'
      a.click()
    } catch (err) {
      console.log('---error--->', err.message)
    }
  }

  return (
    <StyledTableRow hover>
      <TableCell>
        <Link href="#" onClick={download}>
          {document?.name}
        </Link>
      </TableCell>
      <TableCell>{document?.documentType}</TableCell>
      <TableCell>{document?.approved ? <CheckedIcon /> : <UnCheckedIcon />}</TableCell>
      <TableCell>{document?.expirationDate && dayjs(document?.expirationDate).format('MMM DD, YYYY')}</TableCell>
      <TableCell>{document?.shared ? <CheckedIcon /> : <UnCheckedIcon />}</TableCell>
      <TableCell>
        <IconButton onClick={() => navigate(detailPath) }>
          <EditIcon />
        </IconButton>
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
        <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
      </TableCell>
    </StyledTableRow>
  )
}
