import { Box } from '@mui/material'
import Header from 'components/Header'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { search as getStudentCourseSession } from 'store/academic/parent/course-session-enrollment'
import { search as getStudentAcademicYears } from 'store/academic/parental-academic-year'
import ScheduleTable from 'components/Schedule'
import { LinearProgress } from '@mui/material'

export default function Schedules() {
  const dispatch = useDispatch()

  const { studentId } = useParams()

  const [studentCourseSession, setStudentCourseSession] = useState()
  const [minMaxPeriod, setMinMaxPeriod] = useState()
  const [scheduledSessions, setScheduledSessions] = useState()
  const [unScheduledSessions, setUnscheduledSessions] = useState()
  const [saturdaySession, setSaturdaySession] = useState(false)
  const [sundaySession, setSundaySession] = useState(false)
  const [errorMessage, setErrorMessage] = useState('Loading..')
  const [startRenderList, setStartRenderList] = useState(false)

  const parentStudentList = useSelector(state => state.academic.student?.list)
  const [studentSchoolEnrollmentId, setStudentSchoolEnrollmentId] = useState()
  const [studentAcademicYearId, setStudentAcademicYearId] = useState()

  useEffect(() => {
    if (parentStudentList?.length > 0) {
      const temp = parentStudentList.filter(item => item.id === parseInt(studentId))
      if (temp) {
        setStudentSchoolEnrollmentId(temp[0].schoolEnrollments[0].id)
      }
    }
  }, [parentStudentList])

  useEffect(() => {
    if (studentId && studentSchoolEnrollmentId) {
      const studentAcademicYearFetch = async () => {
        const data = await dispatch(
          getStudentAcademicYears({
            studentId,
            schoolEnrollmentId: studentSchoolEnrollmentId,
          }),
        )
        setStudentAcademicYearId(data.payload[0].id)
      }
      studentAcademicYearFetch()
    }
  }, [studentSchoolEnrollmentId])

  const addSessionToSchedule = (newItem, storage) => {
    if (storage.length === 0) {
      storage.push({
        startTime: newItem.startTime,
        endTime: newItem.endTime,
        dayOfWeek: newItem.dayOfWeek,
        list: [{ name: newItem.name, room: newItem.room }],
      })
      return storage
    }
    let existsIndex = 0
    storage.forEach((item, index) => {
      if (
        item.startTime === newItem.startTime &&
        item.endTime === newItem.endTime &&
        item.dayOfWeek === newItem.dayOfWeek
      ) {
        if (existsIndex === 0) existsIndex = index
      }
    })

    if (existsIndex === 0) {
      storage.push({
        startTime: newItem.startTime,
        endTime: newItem.endTime,
        dayOfWeek: newItem.dayOfWeek,
        list: [{ name: newItem.name, room: newItem.room }],
      })
      return storage
    } else {
      const temp = storage[existsIndex]
      temp.list.push({ name: newItem.name, room: newItem.room })
      storage[existsIndex] = temp
      return storage
    }
  }

  useEffect(() => {
    if (studentId && studentSchoolEnrollmentId && studentAcademicYearId) {
      const studentCourseSessionEnrollmentFetch = async () => {
        const data = await dispatch(
          getStudentCourseSession({
            schoolEnrollmentId: studentSchoolEnrollmentId,
          }),
        )
        setStudentCourseSession(data.payload)
      }
      studentCourseSessionEnrollmentFetch()
    }
  }, [studentAcademicYearId])

  useEffect(() => {
    if (studentCourseSession?.length > 0) {
      let temp = []
      const rawScheduledSessions = studentCourseSession.filter(item => item.courseSession.schedules.length > 0)
      rawScheduledSessions.forEach(item => {
        item.courseSession.schedules.forEach(scheduleItem => {
          const newItem = {
            name: item.courseSession.course.name + ' - ' + item.courseSession.name,
            startTime: scheduleItem.period.startTime,
            endTime: scheduleItem.period.endTime,
            dayOfWeek: scheduleItem.period.dayOfWeek,
            room: scheduleItem?.room?.id
              ? scheduleItem?.room
              : item?.courseSession?.room?.id
              ? item?.courseSession?.room
              : null,
          }
          temp = addSessionToSchedule(newItem, temp)
        })
      })
      setScheduledSessions(temp)
      setErrorMessage('')
      setUnscheduledSessions(studentCourseSession.filter(item => item.courseSession.schedules.length === 0))

      let min = 8
      let max = 16
      studentCourseSession.forEach(item => {
        item.courseSession.schedules.forEach(inem => {
          min = Math.min(inem.period.startTime.split(':')[0], min)
          max = Math.max(inem.period.endTime.split(':')[0], max)
        })
      })
      const hours = []
      for (let i = min; i <= max; i++) {
        hours.push(i + ':00:00')
      }
      setMinMaxPeriod(hours)
    } else if (studentCourseSession?.length === 0) {
      setErrorMessage('There is not any Course Enrollment')
    }
  }, [studentCourseSession])

  useEffect(() => {
    if (scheduledSessions?.length > 0) {
      setSundaySession(
        studentCourseSession.filter(item => item.courseSession.schedules?.find(s => s.period.dayOfWeek === 0)).length >
          0
          ? true
          : false,
      )
      setSaturdaySession(
        studentCourseSession.filter(item => item.courseSession.schedules?.find(s => s.period.dayOfWeek === 6)).length >
          0
          ? true
          : false,
      )
    }

    setStartRenderList(true)
  }, [scheduledSessions])

  if (!(minMaxPeriod && unScheduledSessions && scheduledSessions))
    return <LinearProgress sx={{ m: 2 }} color="success" />

  return (
    <Box sx={{ minHeight: '100%' }}>
      <Header title={'Schedules'} />

      {startRenderList && minMaxPeriod ? (
        <ScheduleTable
          hours={minMaxPeriod}
          saturday={saturdaySession}
          sunday={sundaySession}
          unScheduledSessions={unScheduledSessions}
          scheduledSessions={scheduledSessions}
        />
      ) : (
        <Box sx={{ m: 4 }}>{errorMessage}</Box>
      )}
    </Box>
  )
}
