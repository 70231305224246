/* eslint:disabled */
import { Box, Tab, Tabs } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import 'utils/global.scss'

const COURSESESSIONS = 0
const ENROLLMENTSTUDENT = 1
const ENROLLMENTCOURSE = 2
const COURSES = 3

const getTabIndex = path => {
  const [tabName] = path.split('/')
  const i = ['course-sessions', 'enroll-student', 'enroll-session', 'courses' ].indexOf(tabName)
  return i < 0 ? 0 : i
}

export default function SchedulingTabs() {
  const { t } = useTranslation()
  const theme = useSelector(state => state.session.appearance)
  const { '*': tabName } = useParams()
  const [tabIndex, setTabIndex] = useState(getTabIndex(tabName))
  useEffect(() => {
    setTabIndex(getTabIndex(tabName))
  }, [tabName])
  const basePath = `/settings/scheduling`

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', displayPrint: 'none' }}>
        <Tabs
          value={tabIndex}
          TabIndicatorProps={{ style: { background: 'primary', fontFamily: 'Roboto, sans-serif' } }}
        >
          <Tab
            sx={{ color: tabIndex === 0 && 'primary !important' }}
            className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
            label={t('Course Sessions')}
            value={COURSESESSIONS}
            component={Link}
            to={`${basePath}/course-sessions`}
          />
          <Tab
            sx={{ color: tabIndex === 1 && 'primary !important' }}
            className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
            label={t('Enroll By Student')}
            value={ENROLLMENTSTUDENT}
            component={Link}
            to={`${basePath}/enroll-student`}
          />
          <Tab
            sx={{ color: tabIndex === 2 && 'primary !important' }}
            className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
            label={t('Enroll By Session')}
            value={ENROLLMENTCOURSE}
            component={Link}
            to={`${basePath}/enroll-session`}
          />
          <Tab
              sx={{ color: tabIndex === 3 && 'primary !important' }}
              className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
              label={t('Courses')}
              value={COURSES}
              component={Link}
              to={`${basePath}/courses`}
          />
        </Tabs>
      </Box>
    </Box>
  )
}
