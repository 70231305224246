import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { DownIcon } from 'components/Icons'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import FeildSkeleton from './FieldSkeleton'

export default function GradingListCoefficient({ data, expanded, handleChange }) {
  const { t } = useTranslation()
  const theme = useSelector(state => state.session.appearance)

  function createData(name, suffix, coefficient) {
    return { name, suffix, coefficient }
  }
  const rows = [
    createData('Adavanced Placement', 'AP', data?.ap),
    createData('Honors', 'HON', data?.honors),
    createData('College Prep', 'CP', data?.collegePrep),
    createData('Unweighted', '-', data?.unweighted),
  ]

  if (!data) return null

  return (
    <Paper sx={{ my: '5px' }}>
      <Accordion defaultExpanded expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
        <AccordionSummary expandIcon={<DownIcon />}>
          <Typography>Grading and Weighted GPA</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper} elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Name')}</TableCell>
                  <TableCell>{t('Suffix')}</TableCell>
                  <TableCell>{t('Coefficient')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => (
                  <TableRow key={row.name || <FeildSkeleton />}>
                    <TableCell>{row.name || <FeildSkeleton />}</TableCell>
                    <TableCell>{row.suffix || <FeildSkeleton />}</TableCell>
                    <TableCell>{row.coefficient || <FeildSkeleton />}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
            <Grid item sm={4} xs={4} md={4} sx={{ p: 2 }}>
              <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontWeight: 'bold' }}>
                {t('Minimum Passing Grade')}
              </Typography>
              <Typography variant="body1" className={`grid-table-title-value`}>
                {data?.minGrade || <FeildSkeleton />}
              </Typography>
            </Grid>
        </AccordionDetails>
      </Accordion>
    </Paper>
  )
}
