import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IconButton, TableCell, TableRow, Link } from '@mui/material'
import { EditIcon, DeleteIcon } from 'components/Icons'
import { destroy } from 'store/course-session-enrollment'
import { useDispatch, useSelector } from 'react-redux'
import { useBasePath } from 'components/Providers'
import Confirm from 'components/Dialog'
import { Link as NavLink } from 'react-router-dom'

export default function CourseSessionEnrollmentItem({ enrollment, employmentType, person }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const basePath = useBasePath()
  const detailPath = `${basePath}/${enrollment.id}`
  const [confirm, setConfirm] = useState(false)

  const onConfirm = () => {
    setConfirm(false)
    enrollment.id && person.id && dispatch(destroy({ id: enrollment.id, personId: person.id, employmentType }))
  }

  const permission = useSelector(state => state.session.permissions)
  const hasCourseSessionViewPermission = parseInt(permission['setting.course-session'])

  return (
    <TableRow hover>
      <TableCell>
        {hasCourseSessionViewPermission ? (
          <Link
            component={NavLink}
            to={`/settings/course-session/${enrollment.courseSession.id}`}
            underline="hover"
            color="inherit"
          >
            {enrollment.courseSession.course.name} / {enrollment.courseSession.name}
          </Link>
        ) : (
          <>
            {enrollment.courseSession.course.name} / {enrollment.courseSession.name}
          </>
        )}
      </TableCell>

      <TableCell>{enrollment.startDate}</TableCell>
      <TableCell>{enrollment.endDate}</TableCell>
      <TableCell>
        <IconButton
          color="default"
          onClick={() => {
            setConfirm(true)
          }}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          color="default"
          onClick={() => {
            navigate(detailPath)
          }}
        >
          <EditIcon />
        </IconButton>
      </TableCell>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
    </TableRow>
  )
}
