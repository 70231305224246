import { useMember } from 'components/Providers'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { search } from 'store/academic/parent/attendance'
import Attendance from 'components/Attendance'
import { LinearProgress } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function Index() {
  const member = useMember()
  const dispatch = useDispatch()
  const { studentId } = useParams()

  const attendanceReports = useSelector(state => state.academic.parentStudent?.list)?.find(
    m => m.id.toString() === studentId,
  ).attendanceReport

  useEffect(() => {
    if (!attendanceReports && member?.id) {
      dispatch(search({ schoolEnrollmentId: member?.schoolEnrollments[0]?.id }))
    }
  }, [dispatch, member?.id, studentId])

  let absenceRate =
    (attendanceReports?.daily?.absent * 100) /
    (attendanceReports?.daily?.absent + attendanceReports?.daily?.tardy + attendanceReports?.daily?.present)

  absenceRate = isNaN(absenceRate) || !isFinite(absenceRate) ? '-' : absenceRate

  if (!(absenceRate && attendanceReports)) return <LinearProgress sx={{ m: 2 }} color="success" />

  return <Attendance absenceRate={absenceRate} attendanceReports={attendanceReports} />
}
