import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import Header from 'components/Header'
import TableHeadCell from 'components/TableHeadCell'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { search as getStudentCourseSession } from 'store/academic/parent/course-session-enrollment'
import { search as getStudentAcademicYears } from 'store/academic/parental-academic-year'

export default function Courses() {
  const dispatch = useDispatch()

  const { studentId } = useParams()

  const [studentCourseSession, setStudentCourseSession] = useState()
  const [studentSchoolEnrollmentId, setStudentSchoolEnrollmentId] = useState()
  const [studentAcademicYearId, setStudentAcademicYearId] = useState()
  const parentStudentList = useSelector(state => state.academic.parentalStudent?.list)
  const [errorMessage, setErrorMessage] = useState('Loading..')

  useEffect(() => {
    if (parentStudentList?.length > 0) {
      const temp = parentStudentList.filter(item => item.id === parseInt(studentId))
      if (temp) {
        setStudentSchoolEnrollmentId(temp[0].employments[0].schoolEnrollments[0].id)
      }
    }
  }, [parentStudentList])

  useEffect(() => {
    if (studentId && studentSchoolEnrollmentId) {
      const studentAcademicYearFetch = async () => {
        const data = await dispatch(
          getStudentAcademicYears({
            studentId,
            schoolEnrollmentId: studentSchoolEnrollmentId,
          }),
        )
        setStudentAcademicYearId(data.payload[0].id)
      }
      studentAcademicYearFetch()
    }
  }, [studentSchoolEnrollmentId])

  useEffect(() => {
    if (studentId && studentAcademicYearId && studentSchoolEnrollmentId) {
      const studentCourseSessionEnrollmentFetch = async () => {
        const data = await dispatch(
          getStudentCourseSession({
            studentId,
            academicYearId: studentAcademicYearId,
            schoolEnrollmentId: studentSchoolEnrollmentId,
          }),
        )
        setStudentCourseSession(data.payload)
        if (data.payload?.length === 0 || !data.payload) {
          setErrorMessage('There is not any Course Enrollment')
        }
      }
      studentCourseSessionEnrollmentFetch()
    }
  }, [studentAcademicYearId])

  return (
    <Box sx={{ minHeight: '100%' }}>
      <Header title={'Courses'} />
      {studentCourseSession && (
        <Paper sx={{ width: '100%', mb: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell>Course Session</TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentCourseSession?.length > 0 ? (
                studentCourseSession?.map((item, index) => (
                  <TableRow hover key={index}>
                    <TableCell>
                      {item.courseSession.course.name} - {item.courseSession.name}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <Box sx={{ m: 2 }}>{errorMessage}</Box>
              )}
            </TableBody>
          </Table>
        </Paper>
      )}
    </Box>
  )
}
