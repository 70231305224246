import { Box, TableCell, TableRow } from '@mui/material'
import ArrowCircleRightTwoToneIcon from '@mui/icons-material/ArrowCircleRightTwoTone';

export default function CourseSessionListItem({
  item,
  setSelectedRow,
  selectedRow,
  changeSelectedCourseSession,
  selectedCourseSession,
  selectedDate,
  fetchDate,
  attendanceTakenPeriods,
}) {

  const isChecked = attendanceTakenPeriods?.some(period => period.periodId === item.periodId && period.isChecked);

  return (
    <TableRow
      hover
      sx={{
        borderBottom: '2px solid white',
        '&:not(:first-of-type)': {
          borderTop: '4px solid white',
        },
      }}
    >
      <TableCell
        value={item.id}
        onClick={() => {
          changeSelectedCourseSession(item?.id, item.periodId)
          setSelectedRow(item)
        }}
        sx={{
          backgroundColor:
            isChecked
              ? '#C8E6C9'
              : '#FECDD2',
          p: 2
        }}
      >
      <Box sx={{          
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          padding: 0,
          height: '20px' 
          }}
        >
        {item.periodName} - {item?.name}
        {selectedRow &&
          String(item.startTime) === String(selectedRow.startTime) &&
          selectedCourseSession === item.id &&
          String(selectedDate) === String(fetchDate) && (
            <ArrowCircleRightTwoToneIcon color='primary' sx={{ marginLeft: 1, fontSize: '24px' }} />
          )}
      </Box>
        
      </TableCell>
    </TableRow>
  )
}
