import { InputAdornment, MenuItem, Stack, TextField } from '@mui/material'
import { CloseIcon, SearchIcon } from 'components/Icons'
import { debounce, omit } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getCourseSubjects } from 'store/lookup'
import { search } from 'store/settings/course-session'

export default function SchedulingCourseSessionsSearchBar() {
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const { query } = useSelector(state => state.settings.courseSession)
  const subjectArea = useSelector(state => state.lookup.courseSubjects)

  const [que, setQue] = useState(query.que || '')

  const filterNullValues = obj => {
    let temp = {}
    Object.keys(obj).forEach(key => {
      obj[key] !== null ? (temp[key] = obj[key]) : null
    })
    return temp
  }

  const applyFilter = async (key, value) => {
    if (value) {
      dispatch(search({ ...filterNullValues(query), [key]: encodeURI(value), offset: 0 }))
    } else {
      dispatch(search({ ...omit(query, key), offset: 0 }))
    }
  }

  useEffect(() => {
    applyFilter('name', '');
  }, []);

  const delayedSearch = useMemo(() => {
    return debounce((que, callback) => callback('name', que), 500)
  }, [dispatch])

  useEffect(() => {
    subjectArea || dispatch(getCourseSubjects())
  }, [])

  if (!(subjectArea, query)) return null

  return (
    <Stack direction="row" spacing={2} sx={{ py: 2 }}>
      <TextField
        size="medium"
        fullWidth
        value={que}
        placeholder="Search"
        onChange={event => {
          setQue(event.target.value)
          delayedSearch(event.target.value, applyFilter)
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <CloseIcon
              onClick={() => {
                setQue('')
                applyFilter('name', '')
              }}
              sx={{ '&:hover': { cursor: 'pointer' }, fontSize: 'small' }}
            />
          ),
        }}
      />

      <TextField
        select
        sx={{ width: '23em' }}
        size="medium"
        label={t('Course')}
        placeholder="Course"
        color="primary"
        onChange={e => applyFilter('courseId', e.target.value)}
      >
        <MenuItem value={null}>{t('All')}</MenuItem>
        {subjectArea &&
          subjectArea?.map(item => (
            <MenuItem key={item.value} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
      </TextField>
    </Stack>
  )
}
