import TableHeadCell from 'components/TableHeadCell'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Box,
  Paper,
  Autocomplete,
  TextField,
  LinearProgress,
} from '@mui/material'
import 'utils/global.scss'
import { BasePathProvider } from 'components/Providers'
import CourseStudentListItem from './CourseStudentListItem'
import { useDispatch, useSelector } from 'react-redux'
import { searchStudents, setSelectedCourseSession } from 'store/academic/course-session'

export default function CourseStudentList({ courseSessionList }) {
  const headers = ['Full Name', 'Student ID', 'Grade Section', 'Email', 'Absences', 'Tardies']
  const basePath = `/academic/course-session/students`
  const selectedCourseSession = useSelector((state) => state.academic.courseSession.selectedCourseSession);
  const studentList = useSelector((state) => state.academic.courseSession.studentList);
  
  const dispatch = useDispatch();

  const { t } = useTranslation()

  
  useEffect(() => {
    if (!selectedCourseSession) return;
    dispatch(searchStudents({ courseSessionId: selectedCourseSession.id }));
  }, [selectedCourseSession]);

    if (!studentList, (!courseSessionList || courseSessionList.length === 0)) return <LinearProgress />;

  return (
    <BasePathProvider value={basePath}>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '10px' }}>
            <Paper sx={{ width: '100%', p: 2 }}>
                <Autocomplete
                    value={selectedCourseSession}
                    onChange={(event, newValue) => {
                        dispatch(setSelectedCourseSession(newValue));
                    }}
                    
                    options={courseSessionList}
                    getOptionLabel={(option) => option.name || ''}
                    isOptionEqualToValue={(option, value) => option.id === value} 
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        label={t('Course Session')}
                        placeholder="Course Session"
                        size="small"
                        />
                    )}
                    noOptionsText={t('No course sessions available')}
                    sx={{ width: '100%', pb: 2 }}
                />
                <TableContainer>
                    <Table >
                    <TableHead>
                        <TableRow>
                            {headers.map((item, index) => (
                                <TableHeadCell 
                                  key={index} 
                                  className="font-weight-500"
                                  sx={{
                                    backgroundColor: 'primary.main', 
                                    color: 'primary.contrastText',
                                    ...index === 0 && { borderTopLeftRadius: 5 },
                                    ...index === headers.length - 1 && { borderTopRightRadius: 5 },
                                  }} 
                                >
                                {t(item)}
                                </TableHeadCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {studentList?.length > 0 && studentList.map((item, index) => (
                            <CourseStudentListItem key={index} item={item} />
                        ))}
                    </TableBody>
                    </Table>
                    {/* <TablePagination
                    component="div"
                    count={count || 0}
                    page={query?.limit ? query?.offset / query?.limit : 0}
                    onPageChange={handleChangePage}
                    rowsPerPage={query?.limit || 10}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    /> */}
                </TableContainer>
            </Paper>
        </Box>
    </BasePathProvider>
  )
}
