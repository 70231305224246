import { set } from 'lodash'
import _ from 'lodash'
import client from '../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../utils/redux-tool'

const name = 'staff'
const initialState = {
  staff: {
    count: null,
    list: [],
    selected: null,
    query: { active: true, employmentType: 'staff' },
    running: false,
  },
  student: {
    count: null,
    list: [],
    selected: null,
    query: { active: true, employmentType: 'student' },
    running: false,
  },
  contacts: [],
}

// export const lookup = createApiAction('person/lookup', client.search('/api/:employmentType'))

export const search = createApiAction('person/search', client.search('/api/:employmentType'))
export const get = createApiAction('person/get', client.retrieve(`/api/:employmentType/:id`))
export const save = createApiAction('person/save', client.save('/api/:employmentType'))
export const destroy = createApiAction('person/destroy', client.destroy('/api/:employmentType/:id'))

export const deleteSocial = createApiAction(
  'social/delete',
  client.destroy('/api/:employmentType/:personId/social/:id'),
)
export const deletePhone = createApiAction('phone/delete', client.destroy('/api/:employmentType/:personId/phone/:id'))
export const deleteAddress = createApiAction(
  'address/delete',
  client.destroy('/api/:employmentType/:personId/address/:id'),
)
export const deleteEmployment = createApiAction(
  'employment/delete',
  client.destroy('/api/:employmentType/:personId/employment/:id'),
)

export const sendInvitation = createApiAction('person/invite', client.create('/api/invite'))

export const getContacts = createApiAction('contact/list', client.search('/api/:employmentType/:personId/contact'))
export const saveContact = createApiAction('contact/save', client.save('/api/:employmentType/:personId/contact'))
export const deleteContact = createApiAction(
  'contact/delete',
  client.destroy('/api/:employmentType/:personId/contact/:id'),
)

export const getUserRoles = createApiAction('userrole/search', client.search('/api/:employmentType/:personId/role'))
export const setUserRoles = createApiAction('userrole/set', client.create('/api/:employmentType/:personId/role'))

export const getUserServiceRoles = createApiAction(
  'servicerole/search',
  client.search('/api/:employmentType/:personId/service-role'),
)
export const setUserServiceRoles = createApiAction(
  'servicerole/set',
  client.create('/api/:employmentType/:personId/service-role'),
)

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    initiliazePerson: (state, action) => {
      const { employmentType } = action.payload
      set(state, `${employmentType}.selected`, action.payload)
    },
  },
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        const { employmentType } = action.meta.arg
        set(state, `${employmentType}.query`, action.meta.arg)
        set(state, `${employmentType}.list`, action.payload.rows)
        set(state, `${employmentType}.count`, action.payload.count)
        set(state, `${employmentType}.running`, false)
      })
      .addCase(save.fulfilled, (state, action) => {
        const { id } = action.payload
        const { employmentType } = action.meta.arg

        const list = employmentType === 'student' ? state.student.list : state.staff.list
        let found = list.find(x => x.id === id)

        if (employmentType === 'student') {
          state.student.list = found
            ? state.student.list.map(rec => (found.id === rec.id ? action.payload : rec))
            : [...state.student.list, action.payload]
        } else {
          state.staff.list = found
            ? state.staff.list.map(rec => (found.id === rec.id ? action.payload : rec))
            : [...state.staff.list, action.payload]
        }
      })
      .addCase(get.pending, (state, action) => {
        const { employmentType } = action.meta.arg
        set(state, `${employmentType}.selected`, {})
      })
      .addCase(get.fulfilled, (state, action) => {
        const { employmentType } = action.meta.arg
        set(state, `${employmentType}.selected`, action.payload)
      })
      .addCase(getUserRoles.fulfilled, (state, action) => {
        const { employmentType } = action.meta.arg
        set(state, `${employmentType}.selected.userRoles`, action.payload)
      })
      .addCase(setUserRoles.fulfilled, (state, action) => {
        const { employmentType } = action.meta.arg
        set(state, `${employmentType}.selected.userRoles`, action.payload)
      })
      .addCase(getUserServiceRoles.fulfilled, (state, action) => {
        const { employmentType } = action.meta.arg
        set(state, `${employmentType}.selected.serviceRoles`, action.payload)
      })
      .addCase(destroy.fulfilled, (state, action) => {
        const { id } = action.payload
        const { employmentType } = action.meta.arg
        const list = employmentType === 'student' ? state.student.list : state.staff.list
        set(
          state,
          `${employmentType}.list`,
          list.filter(p => p.id !== id),
        )
      })
      .addCase(getContacts.fulfilled, (state, action) => {
        const { employmentType } = action.meta.arg
        set(state, `${employmentType}.selected.contacts`, action.payload)
      })
      .addCase(saveContact.fulfilled, (state, action) => {
        const { employmentType } = action.meta.arg
        const path = `${employmentType}.selected.contacts`
        const items = _.get(state, path)
        const found = items.find(item => item.id === action.payload.id)
        if (found)
          set(
            state,
            path,
            items.map(contact => (found.id === contact.id ? action.payload : contact)),
          )
        else set(state, path, [...items, action.payload])
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        const { employmentType } = action.meta.arg
        const path = `${employmentType}.selected.contacts`
        const list = _.get(state, path)
        set(
          state,
          path,
          list.filter(item => item.id !== action.payload.id),
        )
      })
  },
})

export const { initiliazePerson } = sessionSlice.actions

export default sessionSlice.reducer
