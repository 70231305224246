import { useNavigate, useParams } from 'react-router-dom'
import { Drawer } from '@mui/material'
import { BasePathProvider, MemberProvider } from 'components/Providers'
import StudentSearch from './Search'
import StudentForm from './Form'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'store/academic/student/teacher-student'
import FormSkeleton from './Form/FormSkeleton'
import NotFound from 'components/NotFound'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import PageHeader from 'components/AppBar/PageHeader'

function Member() {
  const navigate = useNavigate()
  const { id, employmentType } = useParams()
  const dispatch = useDispatch()
  const [isReady, setIsReady] = useState(false)
  const [notFound, setNotFound] = useState(false)

  const MainBox = styled(Box)(({ theme }) => ({
    height: '100%',
    width: theme.breakpoints.values.lg,
    [theme.breakpoints.down('lg')]: {
      width: '100vw',
    },
  }))

  const member = useSelector(state => state.academic.student.detail)

  useEffect(() => {
    id &&
      dispatch(get({ id })).then(res => {
        if (res.payload) setIsReady(!!res.payload)
        if (res.error) {
          setNotFound(true)
        }
      })
  }, [dispatch, id])

  const basePath = `/academic/student`

  if (employmentType !== 'student') {
    navigate('/')
  }

  return (
    <BasePathProvider value={basePath}>
      <MemberProvider member={member}>
        <Box sx={{pb: 2}}>
          <PageHeader title={'Student List'} />
        </Box>
        <StudentSearch />
        <Drawer open={Boolean(id)} anchor="right" onClose={() => navigate(basePath)}>
          <MainBox>
            {isReady ? (
              <StudentForm />
            ) : notFound ? (
              <NotFound title={'Student List'} basePath={`${basePath}/${id}`} />
            ) : (
              <FormSkeleton />
            )}
          </MainBox>
        </Drawer>
      </MemberProvider>
    </BasePathProvider>
  )
}

export default Member
