import { Box, Tab, Tabs, Paper } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import 'utils/global.scss'

const DAILY = 0
const STUDENT = 1
const TEACHER = 2

const getTabIndex = path => {
  const [tabName] = path.split('/')
  const i = ['daily', 'student', 'teacher'].indexOf(tabName)
  return i < 0 ? 0 : i
}

export default function SchedulingTabs() {
  const { t } = useTranslation()
  const theme = useSelector(state => state.session.appearance)
  const { '*': tabName } = useParams()
  const [tabIndex, setTabIndex] = useState(getTabIndex(tabName))
  useEffect(() => {
    setTabIndex(getTabIndex(tabName))
  }, [tabName])

  const basePath = `/settings/attendance`

  return (
    <Paper sx={{ width: '100%', py: 1, px: 2, mb: 1 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', displayPrint: 'none' }}>
        <Tabs
          value={tabIndex}
          TabIndicatorProps={{ style: { background: 'primary', fontFamily: 'Roboto, sans-serif' } }}
        >
          <Tab
            sx={{ color: tabIndex === 0 && 'primary !important' }}
            className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
            label={t('DAILY')}
            value={DAILY}
            component={Link}
            to={`${basePath}/daily`}
          />
          <Tab
            sx={{ color: tabIndex === 1 && 'primary !important' }}
            className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
            label={t('STUDENT')}
            value={STUDENT}
            component={Link}
            to={`${basePath}/student`}
          />
          <Tab
            sx={{ color: tabIndex === 2 && 'primary !important' }}
            className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
            label={t('TEACHER')}
            value={TEACHER}
            component={Link}
            to={`${basePath}/teacher`}
          />
        </Tabs>
      </Box>
    </Paper>
  )
}
