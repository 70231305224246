import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FaSave } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getCourseLevel, getCourses, getGradeLevelAndSections } from 'store/lookup'
import { search as getRooms } from 'store/settings/room'

const credits = [0, 0.25, 0.5, 1.0, 1.25, 2.0, 2.5, 3.0, 3.5, 3.75, 4.0, 5.0, 6.0, 7.0, 10.0, 12.5, 15.0]

export default function SchedulingCourseSessionsFormSessionDetails({
  control,
  getValues,
  setValue,
}) {
  const { courseSessionId } = useParams()

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const theme = useSelector(state => state.session.appearance)
  const courses = useSelector(state => state.lookup.courses)
  const { list: courseSessions } = useSelector(state => state.settings.courseSession)
  const courseLevel = useSelector(state => state.lookup.courseLevel)
  const gradeLevelSectionFromStore = useSelector(state => state.lookup.gradeLevelAndSections)
  const academicYearMarkingPeriods = useSelector(state => state.session?.academicYear?.markingPeriods)
  const rooms = useSelector(state => state.settings.room.list)

  const [gradeLevelSections, setGradeLevelSections] = useState()
  const [selectedCourse, setSelectedCourse] = useState(null)

  function setMarkingPeriods() {
    let currentCourseSession = getValues('courseSession') || {}
    let updatedMarkingPeriods = [...(currentCourseSession.markingPeriods || [])]

    academicYearMarkingPeriods.forEach((item, index) => {
      updatedMarkingPeriods[index] = { ...item }
    })

    setValue('courseSession.markingPeriods', updatedMarkingPeriods)
  }

  useEffect(() => {
    setValue('courseSession.credit', selectedCourse?.credit)
    setValue('courseSession.courseLevelId', selectedCourse?.courseLevelId)

    if (selectedCourse?.gradeLevel?.id) {
      setGradeLevelSections(gradeLevelSectionFromStore?.filter(item => item.id === selectedCourse?.gradeLevel?.id))
    } else setGradeLevelSections(gradeLevelSectionFromStore)
    setMarkingPeriods()
  }, [selectedCourse])

  useEffect(() => {
    courses || dispatch(getCourses())
    courseLevel || dispatch(getCourseLevel())
    gradeLevelSectionFromStore || dispatch(getGradeLevelAndSections())
    rooms || dispatch(getRooms())

    if (+courseSessionId) {
      const courseGradeLevelId = courseSessions.find(item => item.id === +courseSessionId)?.course?.gradeLevelId
      if (courseGradeLevelId)
        setGradeLevelSections(gradeLevelSectionFromStore?.filter(item => item.id === courseGradeLevelId))
      else setGradeLevelSections(gradeLevelSectionFromStore)
    } else {
      setGradeLevelSections(gradeLevelSectionFromStore)
    }

    if (courseSessionId === 'new') {
      setMarkingPeriods()
    }
  }, [Boolean(courses && courseLevel && gradeLevelSectionFromStore && rooms)])

  const filterGradeLevelSections = courseId => {
    const courseGradeLevelId = courses.find(item => item.id === courseId)?.gradeLevel?.id
    if (courseGradeLevelId) {
      setGradeLevelSections(gradeLevelSectionFromStore?.filter(item => item.id === courseGradeLevelId))
    } else {
      setGradeLevelSections(gradeLevelSectionFromStore)
    }
  }

  if (!(courses && courseLevel && rooms)) return null

  return (
    <Box sx={{ pt: 3, pl: 1 }} >
      <Typography variant="h6">{t('Session Details')}</Typography>
      <Grid container sx={{ pt: 1 }} spacing={2}>
        <Grid item xs={12} md={12}>
          {courseSessionId !== 'new' && +courseSessionId && (
            <TextField
              select
              fullWidth
              size="small"
              label={t('Course Name')}
              value={courseSessions.find(cs => cs.id === +courseSessionId)?.course?.id}
              disabled={true}
            >
              <MenuItem value={courseSessions.find(cs => cs.id === +courseSessionId)?.course?.id}>
                {courseSessions.find(cs => cs.id === +courseSessionId)?.course?.name}
              </MenuItem>
            </TextField>
          )}
          {courseSessionId === 'new' && (
            <Controller
              name="courseSession.course.id"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <Autocomplete
                    fullWidth
                    disablePortal
                    size="small"
                    options={courses}
                    value={courses.find(item => item.id === field.value)}
                    onChange={(event, value) => {
                      setSelectedCourse(value)
                      field.onChange(value.id)
                      filterGradeLevelSections(value.id)
                    }}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        label={t('Course Name')}
                        size="small"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="courseSession.name"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  fullWidth
                  size="small"
                  label={t('Session Name')}
                  error={!!fieldState.error}
                  required
                  helperText={fieldState.error?.message}
                  {...field}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl sx={{ m: 0 }}>
            <Typography
              sx={{
                fontSize: '12px',
                color: theme === 'dark' ? 'rgba(255, 255, 255, 0.60)' : 'rgba(0, 0, 0, 0.60)',
              }}
            >
              {t('Marking Periods')}
            </Typography>

            <FormGroup row>
              {academicYearMarkingPeriods.map((item, index) => (
                <Controller
                  key={index}
                  name={`courseSession.markingPeriods.${index}.id`}
                  control={control}
                  render={() => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            onChange={e => {
                              if (!e.target.checked) {
                                setValue(`courseSession.markingPeriods.${index}.id`, null, { shouldDirty: true })
                              } else {
                                setValue(`courseSession.markingPeriods.${index}.id`, item?.id, { shouldDirty: true })
                              }
                            }}
                            checked={+getValues(`courseSession.markingPeriods.${index}.id`) === +item?.id}
                          />
                        }
                        label={item?.name}
                        color="primary"
                      />
                    )
                  }}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="courseSession.credit"
            control={control}
            render={({ field, fieldState }) => {
              return (

                <Autocomplete
                  fullWidth
                  options={credits}
                  getOptionLabel={option => option.toString()}
                  noOptionsText={t('No Options')}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={t('Credit')}
                      size="small"
                      required
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                  {...field}
                  value={field.value ?? null}
                  onChange={(event, newValue) => {
                    field.onChange(newValue ?? null)
                  }}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="courseSession.courseLevelId"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <Autocomplete
                  fullWidth
                  options={courseLevel}
                  getOptionLabel={option => option.name}
                  noOptionsText={t('No Options')}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={t('Course Level')}
                      size="small"
                      required
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                  {...field}
                  value={courseLevel.find(option => option.id === field.value) || null}
                  onChange={(event, newValue) => {
                    field.onChange(newValue ? newValue.id : null);
                  }}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="courseSession.gradeSectionId"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <Autocomplete
                  fullWidth
                  size="small"
                  options={gradeLevelSections?.flatMap(item => item?.section.map(sec => ({
                    id: sec.id,
                    label: `${item.name} - Section: ${sec.gradeSection}`
                  })))}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => {
                    field.onChange(newValue ? newValue.id : null);
                  }}
                  value={gradeLevelSections?.flatMap(item => item?.section.map(sec => ({
                    id: sec.id,
                    label: `${item.name} - Section: ${sec.gradeSection}`
                  }))).find(option => option.id === field.value) || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('Grade Section')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="courseSession.roomId"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <Autocomplete
                  fullWidth
                  size="small"
                  options={rooms}
                  getOptionLabel={option => option.name}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={t('Classroom')}
                      size="small"
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                  {...field}
                  value={rooms.find(option => option.id === field.value) || null}
                  onChange={(event, newValue) => {
                    field.onChange(newValue ? newValue.id : null);
                  }}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="courseSession.quota"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  fullWidth
                  size="small"
                  label={t('Max Seats')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Stack flexDirection={'row'} justifyContent={'flex-end'} alignItems={'flex-end'}>
            <Button
              type="submit"
              size="medium"
              startIcon={<FaSave />}
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
                {t('Save')}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}
