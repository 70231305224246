import { useEffect, useState } from 'react'
import { Box, Tabs, Tab, Toolbar, Autocomplete, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useBasePath } from 'components/Providers'
import GradingPolicy from './GradingPolicy'
import FinalGrade from './FinalGrade'
import Assessment from './Assessment'
import { Link, Route, Outlet, Routes, useParams } from 'react-router-dom'
import Header from 'components/Header'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { searchResults, setSelectedCourseSession } from 'store/academic/course-session'

const FINALGRADE = 0
// const ASSESMENT = 1
const GRADINGPOLICY = 2

const Root = styled(Box)(({ theme }) => ({
  width: theme.breakpoints.values.lg,
  [theme.breakpoints.down('lg')]: {
    width: '100vw',
  },
}))

const getTabIndex = path => {
  const [tabName] = path.split('/')
  const i = ['grading-policy', 'final-grade', 'assesment'].indexOf(tabName)
  return i < 0 ? 0 : i
}

export default function Detail({ courseSessionList }) {
  const { '*': tabName } = useParams()
  const [tabIndex, setTabIndex] = useState(getTabIndex(tabName))
  const basePath = useBasePath()
  const { t } = useTranslation()
  const selectedCourseSession = useSelector((state) => state.academic.courseSession.selectedCourseSession);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedCourseSession) return;
    dispatch(searchResults({ courseSessionId: selectedCourseSession.id }));
  }, [selectedCourseSession]);

  return (
    <Root>
      <Toolbar />
      <Header
        title={selectedCourseSession ? [selectedCourseSession.name] : 'NotFound'}
      />
      <Autocomplete
        value={selectedCourseSession}
        onChange={(event, newValue) => {
          dispatch(setSelectedCourseSession(newValue));
        }}

        options={courseSessionList}
        getOptionLabel={(option) => option.name || ''}
        isOptionEqualToValue={(option, value) => option.id === value}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('Course Session')}
            placeholder="Course Session"
            size="small"
          />
        )}
        noOptionsText={t('No course sessions available')}
        sx={{ width: '100%', pb: 2 }}
      />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={(event, i) => setTabIndex(i)}>
          <Tab label={t('FINAL GRADE')} value={FINALGRADE} component={Link} to={`${basePath}/final-grade`} />
          {/* <Tab label={t("ASSESMENT")} value={ASSESMENT} component={Link} to={`${basePath}/assessment`} /> */}
          <Tab label={t('GRADING POLICY')} id={GRADINGPOLICY} component={Link} to={`${basePath}/grading-policy`} />
        </Tabs>
      </Box>
      <Routes value={basePath}>
        <Route path="" element={<Outlet />}>
          <Route index element={<FinalGrade selectedCourseSession={selectedCourseSession} />} />
          <Route path="final-grade" element={<FinalGrade selectedCourseSession={selectedCourseSession}/>} />
          <Route path="grading-policy" element={<GradingPolicy />} />
          <Route path="grading-policy/:id" element={<GradingPolicy />} />
          <Route path="assessment" element={<Assessment />} />
          <Route path="*" element={<FinalGrade />} />
        </Route>
      </Routes>
    </Root>
  )
}
