import { TableCell, TableRow, TextField, MenuItem, LinearProgress } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { save } from 'store/person-attendance-period'
import { useForm, Controller } from 'react-hook-form'
import { usePerson, useEmploymentType } from 'components/Providers'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

export default function PeriodAttendanceItem({ item, attendanceStatus, selectedDate }) {
  const person = usePerson()
  const dispatch = useDispatch()
  const employmentType = useEmploymentType()

  const { control, setValue } = useForm({
    defaultValues: { statusId: item ? item.statusId : null },
  })

  const periodAttendanceFromStore = useSelector(state => state.personAttendancePeriod?.list)

  const currentPeriodAttendance = periodAttendanceFromStore?.find(el => el.id === item.attendanceId)

  const [periodAttendance, setPeriodAttendance] = useState()

  useEffect(() => {
    setValue('statusId', currentPeriodAttendance?.status?.id || periodAttendance?.status?.id)
  }, [currentPeriodAttendance?.status.id || periodAttendance?.status.id, setValue])

  const handleChange = statusId => {
    if (item.attendanceId || periodAttendance?.id) {
      dispatch(
        save({
          id: item.attendanceId || periodAttendance.id,
          employmentType,
          personId: person.id,
          statusId,
        }),
      ).then(res => {
        setPeriodAttendance(res.payload)
      })
    } else {
      dispatch(
        save({
          employmentType,
          personId: person.id,
          date: dayjs(selectedDate).format('YYYY-MM-DD'),
          statusId,
          period: item.period,
          courseSessionEnrollmentId: item.courseSessionEnrollmentId,
        }),
      ).then(res => {
        setPeriodAttendance(res.payload)
      })
    }
  }

  if (!item) {
    return <LinearProgress sx={{ m: 2 }} color="success" />
  }

  return (
    <TableRow hover>
      <TableCell>{item?.period ?? <em>None</em>}</TableCell>
      <TableCell>
        <Controller
          name="statusId"
          control={control}
          render={({ field: { value, ...field }, fieldState }) => {
            return (
              <TextField
                select
                size="small"
                style={{ width: '16.5em' }}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                defaultValue={periodAttendance?.status?.name}
                value={value || ''}
                {...field}
                onChange={event => {
                  const value = event.target.value
                  handleChange(value)
                }}
              >
                {attendanceStatus?.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </TableCell>
      <TableCell>{item.name}</TableCell>
    </TableRow>
  )
}
