import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { search as searchSchoolEnrollment } from 'store/settings/school-enrollment'
import { search as getStudentCourseSessionEnrollment } from 'store/course-session-enrollment'
import { search as getStudentGrade } from 'store/person-grade'
import { Box, LinearProgress } from '@mui/material'
import { GradeList } from './GradeList'
import Header from 'components/Header'
import { useTranslation } from 'react-i18next'

export const Grades = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { personId } = useParams()

  const [studentSchoolEnrollment, setStudentSchoolEnrollment] = useState()
  const [studentCourseSessionEnrollment, setStudentCourseSessionEnrollment] = useState()
  const [studentGrade, setStudentGrade] = useState()
  const [studentScores, setStudentScores] = useState()
  const academicYearId = useSelector(state => state.session.academicYear.id)
  const schoolEnrollmentFromStore = useSelector(state => state.settings.schoolEnrollment.list)
  const courseEnrollmentFromStore = useSelector(state => state.courseSessionEnrollment.list)
  const gradeFromStore = useSelector(state => state.personGrade?.list)
  const markingPeriods = useSelector(state => state.session.academicYear.markingPeriods)

  const [isLoaded, setIsLoaded] = useState()

  const studentSchoolEnrollmentFetch = async () => {
    if (!schoolEnrollmentFromStore) {
      setIsLoaded(false)
      const data = await dispatch(searchSchoolEnrollment({ personId, employmentType: 'student' }))
      setStudentSchoolEnrollment(data?.payload[0])
    } else {
      setStudentSchoolEnrollment(schoolEnrollmentFromStore[0])
    }
  }

  const studentCourseSessionEnrollmentFetch = async () => {
    if (!courseEnrollmentFromStore?.length) {
      const data = await dispatch(
        getStudentCourseSessionEnrollment({
          employmentType: 'student',
          personId,
          schoolEnrollmentId: studentSchoolEnrollment?.id,
        }),
      )
      setStudentCourseSessionEnrollment(data.payload)
    } else {
      setStudentCourseSessionEnrollment(courseEnrollmentFromStore)
    }
  }
  const studentGradeFetch = async () => {
    if (!gradeFromStore) {
      const data = await dispatch(
        getStudentGrade({
          type: 'student',
          personId,
          schoolEnrollmentId: studentSchoolEnrollment?.id,
          academicYearId,
        }),
      )
      setStudentGrade(data.payload)
    } else {
      setStudentGrade(gradeFromStore)
    }
  }

  useEffect(() => {
    studentSchoolEnrollmentFetch()
  }, [personId])

  useEffect(() => {
    if (studentSchoolEnrollment) {
      studentCourseSessionEnrollmentFetch()
      studentGradeFetch()
    }
  }, [studentSchoolEnrollment])

  useEffect(() => {
    if (studentCourseSessionEnrollment && markingPeriods && studentGrade) {
      const temp = { markingPeriodCount: markingPeriods.length }
      studentCourseSessionEnrollment.forEach(item => {
        const periods = []
        markingPeriods.forEach(period => {
          periods.push(
            studentGrade.find(
              grade => grade.markingPeriodId === period.id && grade.courseSessionEnrollment.id === item.id,
            )?.grade,
          )
        })
        const courseKey = item.id
        temp[courseKey] = periods
      })
      setStudentScores(temp)
      setIsLoaded(true)
    }
  }, [studentGrade, studentCourseSessionEnrollment])

  return isLoaded ? (
    <Box>
      <Header
        small
        title={t(
          `Course Session Enrollments ( Total :${
            studentCourseSessionEnrollment?.length ? studentCourseSessionEnrollment?.length : ''
          } ) `,
        )}
      />
      {markingPeriods && studentCourseSessionEnrollment && studentScores && (
        <GradeList
          markingPeriods={markingPeriods}
          courseSessionEnrollments={studentCourseSessionEnrollment}
          studentScores={studentScores}
        />
      )}
    </Box>
  ) : (
    isLoaded === false && <LinearProgress sx={{ m: 2 }} color="success" />
  )
}
