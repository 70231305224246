import dayjs from 'dayjs'
import StudentList from './StudentList'
import { useEffect, useState } from 'react'
import { useSession } from 'components/Providers'
import CourseSessionList from './CourseSessionList'
import { Grid, LinearProgress, useMediaQuery, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getAttendanceStatus } from 'store/lookup'
import { search as searchSchedule } from 'store/academic/schedule'
import { search as searchStudentAttendance } from 'store/settings/attendance'
import { search as searchAttendance } from 'store/academic/attendance-period'
import { useLocation } from 'react-router-dom'

export default function Index({ defaultTeacher = null, }) {
  const dispatch = useDispatch()
  const { academicYear } = useSession()
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [fetchDate, setFetchDate] = useState()
  const [filteredCourseSession, setFilteredCourseSession] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedCourseSession, setSelectedCourseSession] = useState(null)
  const [selectedTeacher, setSelectedTeacher] = useState(defaultTeacher)
  const [list, setList] = useState([])
  const location = useLocation()

  const attendanceStatus = useSelector(state => state.lookup.attendanceStatus)
  const schedule = useSelector(state => state.academic.schedule.list)?.instances
  const isAdminPath = location.pathname.includes('/settings/attendance/teacher')

  const isAdmin = isAdminPath ? useSelector(state => state.session.permissions['setting.attendance']) : false
  const theme = useTheme()
  const isLg = useMediaQuery(theme.breakpoints.up('lg'))
  
  useEffect(() => {
    attendanceStatus?.length || dispatch(getAttendanceStatus())
  }, [dispatch, attendanceStatus])

  useEffect(() => {
    const payload = {
      date: selectedDate,
    }
    if (isAdmin) {
      payload.schoolEnrollmentId = selectedTeacher?.schoolEnrollmentId
    }

    if (selectedDate && selectedTeacher) {
      dispatch(searchSchedule(payload))
    }
  }, [dispatch, selectedTeacher, selectedDate])

  const selectedDateFunc = () => {
    setFilteredCourseSession([])
    if (schedule) {
      const dayOfWeek = new Date(selectedDate).getDay() + 1
      const hasSchedules = schedule.filter(s => s.courseSession.schedules?.find(item => item.day === dayOfWeek))

      const temp = []
      hasSchedules.forEach(item => {
        if (item.courseSession.schedules?.length > 1) {
          item.courseSession.schedules.forEach(period => {
            if (dayOfWeek === period.day) {
              temp.push({
                id: item.courseSession.id,
                courseSessionEnrollmentId: item.id,
                name: item.courseSession.course.name + ' - ' + item.courseSession.name,
                startTime: period.period.startTime,
                endTime: period.period.endTime,
                periodId: period?.period?.id,
                periodName: period?.period?.name,
                dayOfWeek,
              })
            }
          })
        } else {
          if (dayOfWeek === item.courseSession.schedules[0]?.day) {
            temp.push({
              id: item.courseSession.id,
              courseSessionEnrollmentId: item.id,
              name: item.courseSession.course.name + ' - ' + item.courseSession.name,
              startTime: item.courseSession.schedules[0].period.startTime,
              endTime: item.courseSession.schedules[0].period.endTime,
              periodId: item.courseSession.schedules[0].period.id,
              periodName: item.courseSession.schedules[0].period.name,
              dayOfWeek,
            })
          }
        }
      })
      temp.sort((a, b) => {
        const aStart = a.startTime.split(':').reduce((acc, time) => (60 * acc) + +time);
        const bStart = b.startTime.split(':').reduce((acc, time) => (60 * acc) + +time);
  
        return aStart - bStart;
      });
      setFilteredCourseSession(temp)
    }
  }

  useEffect(() => {
    if (schedule) {
      const academicYearEndDayTimestamp = new Date(academicYear.endDate).getTime()
      const todayTimestamp = new Date(Date.now()).getTime()
      if (todayTimestamp > academicYearEndDayTimestamp) {
        selectedDateFunc(dayjs(academicYearEndDayTimestamp))
      } else {
        selectedDateFunc(dayjs(Date.now()))
      }
    }
    if (!schedule) {
      setFilteredCourseSession([])
    }
  }, [schedule])

  const processBatchData = (batch, selectedDate, periodId) => {
    setList([])
    const batchStudent = batch?.payload?.enrollmentList?.filter(std => std.role === 'student')
    let list = []
    batchStudent?.forEach(student => {
      const dailyStatus = batch.payload?.dailyAttendance?.find(
        item => item.schoolEnrollmentId === student.schoolEnrollment.id,
      )?.status

      const periodAttendence = batch.payload?.periodAttendance?.find(
        item => item?.courseSessionEnrollment?.schoolEnrollmentId === student.schoolEnrollment.id,
      )

      const prevPeriodStatus = batch.payload?.prevPeriodAttendance?.find(
        item => item?.courseSessionEnrollment?.schoolEnrollmentId === student.schoolEnrollment.id,
      )?.status

      list.push({
        id: periodAttendence?.id,
        courseSessionEnrollmentId: student.id,
        schoolEnrollmentId: student.schoolEnrollment.id,
        dailyStatus,
        periodStatus: periodAttendence?.status.id,
        prevPeriodStatus,
        periodId,
        perAdmin: periodAttendence?.perAdmin,
        person: student.person,
        date: selectedDate,
      })
    })

    setList(list)
    setFetchDate(selectedDate)
    setLoading(false)
  }

  const formattedDate = selectedDate || dayjs(Date.now()).format('YYYY-MM-DD')

  const changeSelectedCourseSession = (e, periodId) => {
    setSelectedCourseSession(e)
    setLoading(true)
    if (isAdmin) {
      dispatch(searchStudentAttendance({ courseSessionId: e, date: selectedDate, periodId })).then(batch => {
        processBatchData(batch, selectedDate, periodId)
      })
    } else {
      dispatch(searchAttendance({ courseSessionId: e, date: formattedDate, periodId })).then(batch => {
        processBatchData(batch, selectedDate, periodId)
      })
    }
  }


  return (
    <Grid container>
      <Grid item md={12} lg={4}>
        <CourseSessionList
          list={list}
          changeSelectedCourseSession={changeSelectedCourseSession}
          selectedCourseSession={selectedCourseSession}
          selectedDate={selectedDate}
          fetchDate={fetchDate}
          selectedDateFunc={selectedDateFunc}
          setSelectedDate={setSelectedDate}
          filteredCourseSession={filteredCourseSession}
          setSelectedTeacher={setSelectedTeacher}
          setSelectedCourseSession={setSelectedCourseSession}
          selectedTeacher={selectedTeacher}
          isAdmin={isAdmin}
        />
      </Grid>
      <Grid item md={12} lg={8} sx={isLg ? { pl: 2 } : undefined}>
        {!loading ? (
          <StudentList
            list={list}
            attendanceStatus={attendanceStatus}
            fetchDate={fetchDate}
            selectedDate={selectedDate}
            filteredCourseSession={filteredCourseSession}
            isAdmin={isAdmin}
            selectedCourseSession={selectedCourseSession}
            selectedTeacher={selectedTeacher}
            changeSelectedCourseSession={changeSelectedCourseSession}
            setSelectedCourseSession={setSelectedCourseSession}
          />
        ) : (
          <>
            {selectedCourseSession !== null && selectedCourseSession !== undefined && (
              <LinearProgress color="primary" />
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}
