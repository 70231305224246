import { debounce, omit } from 'lodash'
import { InputAdornment, Stack, TextField, MenuItem, Box, LinearProgress, Button } from '@mui/material'
import * as action from 'store/member'
import { CloseIcon, SearchIcon } from 'components/Icons'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { getGradeLevelAndSections } from 'store/lookup'
import { getJobPositions } from 'store/lookup'
import { useTranslation } from 'react-i18next'
import { useSession } from 'components/Providers'

function SearchBar() {
  const dispatch = useDispatch()
  const { employmentType } = useParams()
  const { t } = useTranslation()
  const { loading } = useSelector(state => state.member)
  const query = useSelector(state => state.member.query[employmentType])
  const { gradeLevelAndSections } = useSelector(state => state.lookup)
  const jobPosition = useSelector(state => state.lookup.jobPosition)
  const [que, setQue] = useState(query?.que || '')
  const [selectedGrade, setSelectedGrade] = useState('All Grades')
  const [selectedJob, setSelectedJob] = useState('AllPositions')
  const [status, setStatus] = useState(true)
  const navigate = useNavigate()
  const baseUrl = `/member/${employmentType}/`
  const { academicYear } = useSession()
  useEffect(() => {
    jobPosition || dispatch(getJobPositions())
  }, [dispatch, Boolean(jobPosition)])

  useEffect(() => {
    gradeLevelAndSections || (academicYear && dispatch(getGradeLevelAndSections()))
  }, [dispatch, academicYear, Boolean(gradeLevelAndSections)])

  useEffect(() => {
    dispatch(action.search({ ...query, type: employmentType }))
  }, [dispatch, employmentType, academicYear?.id])

  const applyFilter = async (key, value) => {
    let updatedQuery = {}
    const commonProperties = {
      type: employmentType,
      offset: 0,
    }

    if (key === 'grade') {
      if (value.gradeLevelId && value.gradeSectionId) {
        updatedQuery = {
          ...query,
          gradeLevelId: value.gradeLevelId,
          gradeSectionId: value.gradeSectionId,
          ...commonProperties,
        }
      } else if (value.gradeLevelId && !value.gradeSectionId) {
        updatedQuery = {
          ...omit(query, 'gradeSectionId'),
          gradeLevelId: value.gradeLevelId,
          ...commonProperties,
        }
      } else {
        updatedQuery = {
          ...omit(query, 'gradeSectionId', 'gradeLevelId'),
          ...commonProperties,
        }
      }
    } else if (key === 'enrollment') {
      updatedQuery = {
        ...query,
        [key]: value,
        ...commonProperties,
      }
    } else if (value && key === 'que') {
      if (typeof value === 'string' && value.trim().length > 2) {
        updatedQuery = {
          ...query,
          [key]: value,
          ...commonProperties,
        }
      } else return null
    } else if (key === 'jobPositionId') {
      if (value === 'AllPositions') {
        updatedQuery = {
          ...omit(query, key),
          ...commonProperties,
        }
      } else {
        updatedQuery = {
          ...query,
          [key]: value,
          ...commonProperties,
        }
      }
    } else {
      updatedQuery = {
        ...omit(query, key),
        ...commonProperties,
      }
    }

    dispatch(action.search(updatedQuery))
  }

  const delayedSearch = useMemo(() => {
    return debounce((que, callback) => callback('que', que), 500)
  }, [dispatch])

  const renderSelectGroup = el => {
    const items = el.section.map((p, index) => {
      return (
        p && (
          <MenuItem
            key={index}
            onClick={() => {
              {
                setSelectedGrade({ gradeLevelId: el.id, gradeSectionId: p.id })
              }
              applyFilter('grade', { gradeLevelId: el.id, gradeSectionId: p.id })
            }}
            value={`Section: ${el.id}  Grade: ${p.gradeSection}`}
            sx={{ marginLeft: '17px' }}
          >
            {`Section: ${p.gradeSection}`}
          </MenuItem>
        )
      )
    })
    return [
      <MenuItem
        key={el}
        value={el.id}
        onClick={() => {
          setSelectedGrade(el.id)
          applyFilter('grade', { gradeLevelId: el.id })
        }}
      >
        {`Grade: ${el.id}`}
      </MenuItem>,
      items,
    ]
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Stack direction="row" spacing={2} sx={{ p: 2 }}>
        <TextField
          size="small"
          fullWidth
          placeholder="Search"
          value={que}
          onChange={event => {
            setQue(event.target.value)
            delayedSearch(event.target.value, applyFilter)
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <CloseIcon
                onClick={() => {
                  setQue('')
                  applyFilter('que', '')
                }}
                sx={{ '&:hover': { cursor: 'pointer' }, fontSize: 'small' }}
              />
            ),
          }}
        />
        {employmentType === 'student' ? (
          <FormControl sx={{ width: '27em' }}>
            <InputLabel id="multi-grouped-select-label" size="small">
              Grade Level/Section
            </InputLabel>
            <Select
              id="multi-grouped-select"
              size="small"
              labelId="multi-grouped-select-label"
              label="Grade Level/Section"
              value={selectedGrade}
              onChange={event => setSelectedGrade(event.target.value)}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
            >
              <MenuItem
                value="All Grades"
                onClick={() => {
                  {
                    setSelectedGrade({})
                  }
                  applyFilter('grade', {})
                }}
              >
                All Grades
              </MenuItem>
              {gradeLevelAndSections?.map(el => renderSelectGroup(el))}
            </Select>
          </FormControl>
        ) : (
          <TextField
            select
            sx={{ width: '27em' }}
            size="small"
            label={t('Position')}
            placeholder="Position"
            value={selectedJob}
            onChange={event => {
              setSelectedJob(event.target.value)
              applyFilter('jobPositionId', event.target.value)
            }}
          >
            <MenuItem value={'AllPositions'}>All Positions</MenuItem>
            {jobPosition?.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        )}
        <TextField
          select
          sx={{ width: '21em' }}
          size="small"
          label={employmentType === 'student' ? 'Student Status' : 'Status'}
          placeholder={employmentType === 'student' ? 'Student Status' : 'Status'}
          value={status}
          onChange={event => {
            setStatus(event.target.value)
            applyFilter('enrollment', event.target.value)
            // delayedSearch(event.target.value, applyFilter)
          }}
        >
          <MenuItem value={true}>{employmentType === 'student' ? 'Enrolled' : 'Active'}</MenuItem>
          <MenuItem value={false}>{employmentType === 'student' ? 'Unenrolled' : 'Inactive'}</MenuItem>
        </TextField>
        {/*         <TextField
          select
          sx={{ width: '16em' }}
          size="small"
          label={t('Scope')}
          placeholder="Scope"
          value={query?.scope || ''}
          onChange={event => applyFilter('scope', event.target.value)}
        >
          <MenuItem value="district">District</MenuItem>
          <MenuItem value="school">School</MenuItem>
          <MenuItem value="academicYear">Academic Year</MenuItem>
        </TextField> */}
        {employmentType === 'student' && (
          <Button onClick={() => navigate(`${baseUrl}new`)} sx={{ width: '10em' }} variant="contained" color="primary" size="small">
            {t('Add')}
          </Button>
        )}
      </Stack>
      {!!loading && <LinearProgress sx={{ position: 'absolute', buttom: 0, width: '100%' }} />}
    </Box>
  )
}

export default SearchBar
