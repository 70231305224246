import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { retrieve } from 'store/academic/attendance-report'
import Attendance from 'components/Attendance'
import NotFound from 'components/NotFound'
import { useBasePath, useSession } from 'components/Providers'
import { Box, LinearProgress, Paper } from '@mui/material'

export default function Index() {
  const dispatch = useDispatch()
  const basePath = useBasePath()
  const [notFound, setNotFound] = useState(false)
  const { person: { id: personId, type } } = useSession()

  const session = useSelector(state => state.session)

  const getMatchingEnrollmentId = (session) => {
    const matchingEnrollment = session.enrollments.find(
      (enrollment) => enrollment.personId === personId
    )

    return matchingEnrollment ? matchingEnrollment.id : null
  }

    const matchedId = getMatchingEnrollmentId(session)
    if (!matchedId) return <LinearProgress color="primary" />

  useEffect(() => {
    dispatch(retrieve({ memberId: matchedId, type })).then(res => {
      res.error && setNotFound(true)
    })
  }, [dispatch])

  const attendanceReports = useSelector(state => state.academic.attendanceReport.list)

  let absenceRate =
    (attendanceReports?.daily?.absent * 100) /
    (attendanceReports?.daily?.absent + attendanceReports?.daily?.tardy + attendanceReports?.daily?.present)

  absenceRate = isNaN(absenceRate) || !isFinite(absenceRate) ? '-' : absenceRate

  if (notFound) return <NotFound basePath={`${basePath}`} />

  return (
    <Box component={Paper} sx={{ py: 2 }}>
      <Attendance absenceRate={absenceRate} attendanceReports={attendanceReports} />
    </Box>
  )
}