import { Box, Button, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { PersonAddIcon } from 'components/Icons'
import TableHeadCell from 'components/TableHeadCell'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { AiOutlineDelete } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { getGradeLevels } from 'store/lookup'
import { destroy } from 'store/settings/course-session-enrollments'
import Confirm from 'components/Dialog'
import { search } from 'store/settings/course-session-enrollments'
import { get } from 'store/settings/course-session'

const heads = ['Student Name', 'Grade Section', 'Course Grade', 'Actions']

export default function SchedulingCourseSessionsFormEnrollStudent() {
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const theme = useSelector(state => state.session.appearance)
  const academicYearId = useSelector(state => state.session?.academicYear?.id)
  const gradeLevels = useSelector(state => state.lookup.gradeLevels)
  const [confirm, setConfirm] = useState({ status: false, id: null })

  const navigate = useNavigate()
  const basePath = `/settings/scheduling/course-sessions/`
  const { courseSessionId } = useParams()

  const students = useSelector(state => state.settings?.courseSessionsEnrollments?.list)?.filter(
    cse => cse?.schoolEnrollment?.person?.type === 'student',
  )

  useEffect(() => {
    gradeLevels || dispatch(getGradeLevels())
  }, [])

  useEffect(() => {
    if (+courseSessionId) {
      dispatch(search({ courseSessionId: +courseSessionId, academicYearId: +academicYearId }))
    }
  }, [courseSessionId])

  const destroyEnrollment = async () => {
    setConfirm({ status: false, id: null })
    await dispatch(destroy({ courseSessionId: +courseSessionId, id: confirm?.id }))
    await dispatch(get({ id: courseSessionId }))
  }

  if (!gradeLevels) return null

  return (
    <Box sx={{ pt: 3, pl: 1 }}>
      <Stack flexDirection={'row'} justifyContent={'space-between'}>
        <Typography variant="h6">{t('Enroll Students')}</Typography>
        <Button
          onClick={() => navigate(`${basePath}${courseSessionId}/student`)}
          size="small"
          color="primary"
          variant="outlined"
          startIcon={<PersonAddIcon />}
        >
          {t('Add')}
        </Button>
      </Stack>
      <Table
        sx={{
          border: '1px solid',
          borderColor: theme === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
          my: 2,
        }}
      >
        <TableHead>
          <TableRow>
            {heads.map((item, index) => (
              <TableHeadCell
                key={index}
                sx={{
                  fontWeight: '500 !important',
                }}
              >
                {t(item)}
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {students?.map((item, index) => (
            <TableRow key={index}>
              <TableCell>
                {item?.schoolEnrollment?.person?.lastName}, {item?.schoolEnrollment?.person?.firstName}
              </TableCell>
              <TableCell>
                {
                  gradeLevels?.find(
                    gl =>
                      gl.id ===
                      item?.schoolEnrollment?.academicYearEnrollments[0]?.gradeLevelId,
                  )?.name
                }
                {
                  (item?.schoolEnrollment?.academicYearEnrollments[0]?.section) ?
                    ' / ' + item?.schoolEnrollment?.academicYearEnrollments[0]?.section?.gradeSection : ''
                }
              </TableCell>
              <TableCell>{item?.grade}</TableCell>

              <TableCell>
                <AiOutlineDelete
                  onClick={() => setConfirm({ status: true, id: item?.id })}
                  fontSize={24}
                  color="disabled"
                  style={{
                    cursor: 'pointer',
                    marginRight: '5px',
                    color: theme === 'dark' ? 'rgba(255, 255, 255, 0.56) !important' : 'rgba(0, 0, 0, 0.56)',
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Confirm
        open={confirm.status}
        onClose={() => setConfirm({ status: false, id: null })}
        onConfirm={destroyEnrollment}
      />
    </Box>
  )
}
