import { useState } from 'react'
import { Grid, TextField } from '@mui/material'
import { save } from 'store/academic/final-grades'
// import { useParams } from 'react-router-dom'
import GradeTextField from './GradeTextField'
import { useDispatch } from 'react-redux'
import { useSession } from 'components/Providers'

export default function FinalGradeItem({ markingPeriodGrades: data, grade, courseSessionMPIds }) {
  const dispatch = useDispatch()
  // const { courseSessionId } = useParams()
  const [error, setError] = useState(false)
  const { academicYear } = useSession()
  const markingPeriods = academicYear.markingPeriods

  const update = (event, index) => {
    let grade = event.target.value.trim()
    if (grade === '') {
      grade = null
      setError(false)
    }
    if (grade < 0 || grade > 100 || isNaN(grade)) {
      setError(true)
      return
    }
    dispatch(
      save({
        courseSessionId: '699817',
        id: data[index].courseSessionEnrollmentId,
        markingPeriodGrades: [
          {
            markingPeriodGradeId: data[index].id,
            grade: grade ? +grade : null,
            markingPeriodId: data[index].markingPeriodId,
          },
        ],
      }),
    ).then(res => setError(res.error))
  }

  const onBlur = (event, index) => {
    update(event, index)
  }

  const onKeyUp = (event, index) => {
    if (event.keyCode !== 13) return
    update(event, index)
  }

  return (
    <Grid container item xs={12} md={12}>
      {markingPeriods.map((item, index) => (
        <Grid key={index} item xs={2.4} md={2.4}>
          <GradeTextField
            isActive={!courseSessionMPIds?.find(x => x === data[index]?.markingPeriodId)}
            value={data[index]?.grade}
            onBlur={event => {
              onBlur(event, index)
            }}
            onKeyUp={event => {
              onKeyUp(event, index)
            }}
            error={Boolean(error)}
            sx={{ width: '120px' }}
          />
        </Grid>
      ))}

      <Grid item xs={2.4} md={2.4}>
        <TextField
          disabled
          id="outlined-basic"
          size="small"
          variant="outlined"
          value={Number.isInteger(grade) ? grade : ''}
          inputProps={{ style: { textAlign: 'left' } }}
          error={Boolean(error)}
          sx={{ width: '120px' }}
        />
      </Grid>
    </Grid>
  )
}
