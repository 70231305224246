/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux'
import { Container, Grid } from '@mui/material'
import StudentCard from './StudentCard'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { search } from 'store/academic/parent/parent-student'

export default function Index() {
  const studentList = useSelector(state => state.academic.student.list)
  const dispatch = useDispatch()

  /*   useEffect(() => {
    studentList || dispatch(search())
  }, [dispatch, Boolean(studentList)]) */

  return (
    <Container>
      <Grid container spacing={2}>
        {studentList?.map(student =>
          student?.employments?.map(employment =>
            employment.schoolEnrollments.map(schoolEnrollment => (
              <StudentCard student={student} schoolEnrollment={schoolEnrollment} key={schoolEnrollment.id} />
            )),
          ),
        )}
      </Grid>
    </Container>
  )
}
