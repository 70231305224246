import { TableCell, TableRow } from '@mui/material'
// import { Link as NavLink } from 'react-router-dom'
// import { useBasePath } from 'components/Providers'

export default function CourseStudentListItem({ item }) {
  // const path = useBasePath()
  console.log(item)
  return (
    <TableRow hover>
      {/* <TableCell>
        <Link component={NavLink} underline="hover" color="inherit" to={`${path}/${item.id}`}>
          {item.course.name} - {item.name}
        </Link>
      </TableCell> */}
      <TableCell>{item?.schoolEnrollment?.person?.last_name}, {item?.schoolEnrollment?.person?.first_name}</TableCell>
      <TableCell>{item?.schoolEnrollment?.person?.local_id}</TableCell>
      <TableCell>{item?.schoolEnrollment?.academicYearEnrollments[0]?.grade_section}</TableCell>
      <TableCell>{item?.schoolEnrollment?.person?.email}</TableCell>
      <TableCell>{item?.absences}</TableCell>
      <TableCell>{item?.tardies}</TableCell>
    </TableRow>
  )
}
