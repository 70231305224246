import client from '../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import { defaults } from 'lodash'

import { createApiAction } from '../utils/redux-tool'

const name = 'session'
const initialState = {
  // id: null,
  token: null,
  user: null,
  employment: null,
  // role: null,
  person: null,
  district: null,
  academicYear: null,
  school: null,
  schools: [],
  permissions: [],
  employments: null,
  // service: null,
  enrollments: null,
  running: false,
  error: null,
  appearance: localStorage.getItem('appearance') || '',
  initialized: false,
  languages: {
    en: { nativeName: 'English' },
    es: { nativeName: 'Spanish' },
  },
}

export const login = createApiAction('auth/login', client.create('/api/auth/login'))

export const register = createApiAction('auth/register', client.create('/api/auth/register'))

export const getSession = createApiAction('session/current', client.retrieve('/api/session'))

export const getEmployment = createApiAction('session/employments', client.search('/api/session/employment'))

export const setEmployment = createApiAction('session/set-employment', client.create('/api/session/employment'))

export const getEnrollment = createApiAction('session/enrollments', client.search('/api/session/get-enrollment'))

export const setEnrollment = createApiAction('session/set-enrollment', client.create('/api/session/set-enrollment'))

export const getSchools = createApiAction('session/schools', client.search('/api/session/school'))
export const setSchool = createApiAction('session/set-school', client.create('/api/session/school'))

export const destroyWidget = createApiAction(
  'session/widget-employment/delete',
  client.destroy('/api/session/widget-employment/delete/:id'),
)

export const setAcademicYear = createApiAction('session/set-academicyear', client.create('/api/session/academic-year'))

export const changePassword = createApiAction('session/change-password', client.update('/api/session/change-password'))

export const resolve = createApiAction('session/resolve', client.retrieve('/api/session/resolve'))

export const approve = createApiAction('session/approve', client.retrieve('/api/session/approve'))

export const impersonate = createApiAction('session/impersonate', client.create('/api/session/impersonate'))

export const forgotPassword = createApiAction('auth/forgot', client.create('/api/auth/forgot'))
export const resetPassword = createApiAction('auth/reset', client.create('/api/auth/reset'))
export const validateEMail = createApiAction('auth/validate-email', client.create('/api/auth/validate-email'))
export const mergeUser = createApiAction('session/merge-user', client.create('/api/session/merge'))

const setSession = (state, action) => {
  const initialized = state.initialized
  const data = defaults({ ...action.payload, initialized }, initialState)
  for (const [key, value] of Object.entries(data)) {
    state[key] = value
  }
}

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    logout: () => {
      Cookies.remove('sessionid')
    },
    appearance: (state, action) => {
      if (action.payload) localStorage.setItem('appearance', action.payload)
      else localStorage.removeItem('appearance')
      state.appearance = action.payload
    },
    // setSession: (state, action) => {},
    setInitialized: (state, action) => {
      state.initialized = action.payload
    },
    // reload: state => {
    //   // state.initialized = false
    // },
  },
  extraReducers(builder) {
    builder
      .addCase(login.pending, state => {
        state.running = true
        state.error = null
      })
      .addCase(login.fulfilled, (state, action) => {
        setSession(state, action)
        state.running = false
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.error?.data?.error || action.error?.message
        state.running = false
      })
      .addCase(getSession.fulfilled, setSession)
      .addCase(getEmployment.fulfilled, (state, action) => {
        state.employments = action.payload
      })

      .addCase(getEnrollment.fulfilled, (state, action) => {
        state.enrollments = action.payload
      })
      .addCase(getSchools.fulfilled, (state, action) => {
        state.schools = action.payload
      })
      .addCase(register.fulfilled, setSession)
      .addCase(setEmployment.fulfilled, setSession)
      .addCase(setSchool.fulfilled, setSession)
      .addCase(setEnrollment.fulfilled, setSession)
      .addCase(setAcademicYear.fulfilled, setSession)
      .addCase(impersonate.fulfilled, setSession)
      .addCase(resetPassword.fulfilled, setSession)

  },
})

export const { logout, appearance, setInitialized, reload } = sessionSlice.actions
export default sessionSlice.reducer
