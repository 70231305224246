import { Route, Routes } from 'react-router-dom'
import { BasePathProvider } from 'components/Providers'
import { Box } from '@mui/material'
import PageHeader from 'components/AppBar/PageHeader'

import SettingsTabs from './Tabs'

import GradeSections from './GradeSections'
import Rooms from './Rooms'
import Periods from './Periods'
import Grading from './Grading'

export default function Settings_() {
  const basePath = '/settings_'

  return (
    <BasePathProvider value={`${basePath}/`}>
      <Box sx={{ pb: 2, }}>
        <PageHeader title={'Settings'} />
      </Box>
      <Box sx={{ width: '100%', minHeight: '500px', mb: 2 }}>
        <SettingsTabs />
        <Routes>
          <Route path="/">
            <Route path="" element={<GradeSections />} />
            <Route path="/grade-sections/*" element={<GradeSections />} />
            <Route path="/rooms/*" element={<Rooms />} />
            <Route path="/periods/*" element={<Periods />} />
            <Route path="/grading/*" element={<Grading />} />
          </Route>
        </Routes>
      </Box>
    </BasePathProvider>
  )
}
