import { createContext, useContext } from 'react'

const EmploymentTypeContext = createContext(null)

export function useEmploymentType() {
  return useContext(EmploymentTypeContext)
}

export function EmploymentTypeProvider({ children, type }) {
  return <EmploymentTypeContext.Provider value={type}>{children}</EmploymentTypeContext.Provider>
}

const PersonContext = createContext(null)

export function usePerson() {
  return useContext(PersonContext)
}

export function PersonProvider({ children, person }) {
  return <PersonContext.Provider value={person}>{children}</PersonContext.Provider>
}

const MemberContext = createContext(null)

export function useMember() {
  return useContext(MemberContext)
}

export function MemberProvider({ children, member }) {
  return <MemberContext.Provider value={member}>{children}</MemberContext.Provider>
}

const SessionContext = createContext({})

export function useSession() {
  return useContext(SessionContext)
}

export function SessionProvider({ children, session }) {
  return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>
}

const BasePathContext = createContext('')
export const useBasePath = () => useContext(BasePathContext)
export const BasePathProvider = ({ children, value }) => (
  <BasePathContext.Provider value={value}>{children}</BasePathContext.Provider>
)
