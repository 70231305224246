import { useDispatch, useSelector } from 'react-redux'
import { getEnrollment, setEnrollment } from '../../store/session'
import { useEffect, useState } from 'react'

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material'
import NextIcon from '@mui/icons-material/ChevronRight'
import CheckIcon from '@mui/icons-material/Check'

import { SubTitle, Body } from '../typo'

function Employments({ children }) {
  const { employment, district, enrollments } = useSelector(state => state.session)
  const dispatch = useDispatch()
  const [listItems, setListItems] = useState()

  useEffect(() => {
    if (!enrollments) {
      dispatch(getEnrollment())
    }
  }, [enrollments, dispatch])

  const selectEmployment = async (id) => {
    await dispatch(setEnrollment({ id }))
  }

  console.log(enrollments)
  const EmploymentList = () => (
    <TableContainer
      data-component="EmploymentList"
      component={Paper}
      sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 350 }}
    >
      <SubTitle color="primary">Employments</SubTitle>
      <Body>Please re-connect with any enrollment listed below.</Body>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell>Account</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>District</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listItems?.map(item => (
            <TableRow
              key={item.id}
              hover={false}
              selected={item.id === employment?.id}
              data-district-name={item.districtName}
            >
              <TableCell>
                {item.id === employment?.id ? (
                  <CheckIcon fontSize="small" color="primary" />
                ) : (
                  <NextIcon fontSize="small" />
                )}
              </TableCell>
              <TableCell>{item?.personName}</TableCell>
              <TableCell>{item.type}</TableCell>
              <TableCell>{item?.districtName || item?.campusName}</TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                <Button
                  size="small"
                  onClick={() => selectEmployment(item.id, item?.campusEnrollment?.id)}
                  startIcon={<NextIcon fontSize="small" />}
                >
                  Connect
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )

  // const campusEnrollments = []
  // const districtEnrollments = []

  // employments.forEach(item=>{
  //   if(!item?.campusEnrollments){
  //     districtEnrollments.push(item)
  //   } else {
  //     item?.campusEnrollments?.map(ce => campusEnrollments.push(ce))
  //   }
  // })

  useEffect(() => {
    if (enrollments) {
      const temp = []
      enrollments.forEach(item => {
        if (item?.district?.isServiceProvider === true) {
          temp.push(item)
        } else if (item?.campusEnrollments?.length) {
          item?.campusEnrollments.forEach(ce => {
            temp.push({
              id: item.id,
              district: item.district,
              person: item.person,
              type: item.type,
              campusEnrollment: ce,
            })
          })
        } else {
          temp.push(item)
        }
      })
      setListItems(temp)
    }
  }, [enrollments])

  if (employment && district && children) return children
  return enrollments ? <EmploymentList /> : null
}
export default Employments
