import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import { useTranslation } from 'react-i18next'
import ListItem from './ListItem'
import { useSelector } from 'react-redux'

const tableHeadCellStyle = {
  fontWeight: '500 !important',
  borderRight: '2px solid white',
  color: 'white',
}

export default function StudentAttendanceList() {
  const { t } = useTranslation()
  const rows = useSelector(state => state.settings.attendance?.list?.attendancesInRange)
  const periods = useSelector(state => state.lookup.periods)

  return (
    <Paper sx={{ width: '100%', p: 2, mb: 2 }}>
      <TableContainer>
        <Table>
          <TableHead sx={{ backgroundColor: 'primary.main', }}>
            <TableRow>
              <TableHeadCell sx={{ ...tableHeadCellStyle, width: '15em' }}>{t('Date')}</TableHeadCell>
              <TableHeadCell sx={tableHeadCellStyle}>{t('Daily')}</TableHeadCell>
              <TableHeadCell sx={{ ...tableHeadCellStyle, width: '13em' }}>{t('Note')}</TableHeadCell>
              {periods?.map((item, index) => (
                <TableHeadCell key={index} sx={tableHeadCellStyle}>
                  {t(item.name)}
                </TableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((item, index) => (
              <ListItem key={index} item={item} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
