import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Card, LinearProgress } from '@mui/material'
import { useSession } from 'components/Providers'
import { retrieve as seachFinalGrades } from 'store/academic/grade-finalGrade'
import FinalGrades from 'components/FinalGrades'
// import { search } from 'store/academic/grading-scales'
import NotFound from 'components/NotFound'
import PageHeader from 'components/AppBar/PageHeader'
import Header from 'components/Header'

export default function GradingReports() {
  const dispatch = useDispatch()
  const { person, academicYear, school } = useSession()
  const grades = useSelector(state => state.academic.grade_finalGrade?.list || [])
  const [notFound, setNotFound] = useState(false)
  const markingPeriods = academicYear?.markingPeriods
  const courseSessionEnrollmentGrades = grades?.data
  const gpas = grades?.gpas
  const basePath = '/academic/grading-report'
  const personId = person?.id
  const type = person?.type

  const session = useSelector(state => state.session)

  const getMatchingEnrollmentId = (session) => {
    const matchingEnrollment = session.enrollments.find(
      (enrollment) => enrollment.personId === personId
    )

    return matchingEnrollment ? matchingEnrollment.id : null
  }

  const memberId = getMatchingEnrollmentId(session)
  if (!memberId) return <LinearProgress color="primary" />

  useEffect(() => {
    dispatch(seachFinalGrades({ type, memberId }))
      .then(res => {
        if (res.error) {
          setNotFound(true);
        }
      })
      .catch(err => {
        console.error('Error fetching grades:', err);
      });
  }, [dispatch, personId, memberId, type]);

  return (
    <Box sx={{ minHeight: '50%' }}>
      <Box sx={{ pb: 2 }}>
        <PageHeader title={'Grades'} />
      </Box>
      <Card>
        <Box sx={{ pl: 2, pr: 0.5 }}>
          <Header
            title={`${person?.firstName}   ${person?.lastName} `}
            small
            print
            printTitle={`${person?.firstName}   ${person?.lastName} - ${school?.name} - ${academicYear?.name}`}
            printfunc={() => window.print()}
          />
        </Box>
        {notFound ? (
          <NotFound basePath={`${basePath}`} />
        ) : (
          <FinalGrades
            courseSessionEnrollmentGrades={courseSessionEnrollmentGrades}
            markingPeriods={markingPeriods}
            gpas={gpas}
          />
        )}
      </Card>
    </Box>
  )
}
