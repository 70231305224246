import { useDispatch } from 'react-redux'
import {
  Button,
  IconButton,
  Box,
  TextField,
  MenuItem,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Toolbar,
} from '@mui/material'
import { pick, omit } from 'lodash'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { save } from 'store/settings/school-enrollment'
import { useNavigate } from 'react-router-dom'
import { useBasePath, useEmploymentType, usePerson } from 'components/Providers'
import { styled } from '@mui/material/styles'
import { CloseIcon } from 'components/Icons'
import { useTranslation } from 'react-i18next'

const Root = styled(Box)(({ theme }) => ({
  width: theme.breakpoints.values.sm,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

export default function SchoolEnrollmentForm({ enrollment, schools }) {
  const person = usePerson()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const employmentType = useEmploymentType()
  const basePath = useBasePath()
  const { t } = useTranslation()

  const schema = yup.object().shape({
    schoolId: yup.number().required('Required'),
    startDate: yup.date().required('Required'),
    endDate: yup
      .date()
      .nullable()
      .test('valid-dates', 'End date should be later than start date', function (value) {
        return value ? this.parent.startDate < value : true
      }),
  })

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      schoolId: enrollment?.school?.id || '',
      employmentId: '',
      startDate: '',
      endDate: null,
      ...pick(enrollment, 'id', 'schoolId', 'startDate', 'endDate'),
    },
  })

  const onSubmit = async data => {
    if (data.startDate) {
      data.startDate = dayjs(data.startDate).format('YYYY-MM-DD')
    }
    if (data.endDate) {
      data.endDate = dayjs(data.endDate).format('YYYY-MM-DD')
    }
    if (data.id) {
      let payload = omit(data, 'employmentId', 'schoolId')
      const res = await dispatch(save({ ...payload, employmentType, personId: person.id }))
      if (res.payload) {
        navigate(basePath)
      }
    } else {
      let payload = omit(data, 'employmentId')
      const res = await dispatch(save({ ...payload, employmentType, personId: person.id }))
      if (res.payload) {
        navigate(basePath)
      }
    }
  }

  const onError = errors => console.error('---school-enrollment-error--->', errors)

  return (
    <Root
      component="form"
      elevation="0"
      onSubmit={handleSubmit(onSubmit, onError)}
      noValidate
      _sx={{ minHeight: '80vh' }}
    >
      <Toolbar />
      <CardHeader
        title={t('School')}
        subheader={t('School Enrollments')}
        action={
          <IconButton onClick={() => navigate(basePath)}>
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <Controller
              name="schoolId"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <TextField
                    autoFocus
                    fullWidth
                    select
                    size="small"
                    label={t('School')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    value={value || ''}
                    {...field}
                  >
                    {schools?.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item sm={6} xs={6}>
            <Controller
              name="startDate"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <DatePicker
                    fullWidth
                    label={t('Start Date')}
                    value={field.value ? dayjs(field.value) : ''}
                    onChange={field.onChange}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    slotProps={{
                      textField: {
                        size: 'small',
                        fullWidth: true,
                        error: !!fieldState.error,
                        helperText: fieldState.error ? (field.value ? 'Invalid date' : 'Required') : '',
                      },
                    }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item sm={6} xs={6}>
            <Controller
              name="endDate"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <DatePicker
                    fullWidth
                    label={t('Start Date')}
                    defaultValue={null}
                    value={field.value ? dayjs(field.value) : null}
                    onChange={field.onChange}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    slotProps={{
                      textField: {
                        size: 'small',
                        fullWidth: true,
                        error: !!fieldState.error,
                        helperText: fieldState.error ? (field.value ? 'Invalid date' : 'Required') : '',
                      },
                    }}
                  />
                )
              }}
            />
          </Grid>
        </Grid>
      </CardContent>

      <CardActions sx={{ justifyContent: 'flex-end', pr: 2 }}>
        <Button variant="outlined" onClick={() => navigate(basePath)}>
          {t('Cancel')}
        </Button>
        <Button type="submit" variant="contained">
          {t('Cancel')}
        </Button>
      </CardActions>
    </Root>
  )
}
