import client from '../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../utils/redux-tool'

const name = 'person-attendance-daily'
const initialState = {
  list: null,
  count: null,
  query: {},
}

export const search = createApiAction(
  'person/attendance/daily/search',
  client.search('/api/:employmentType/:personId/attendance/daily'),
)
export const get = createApiAction(
  'person/attendance/daily/get',
  client.update('/api/:employmentType/:personId/attendance/daily/:id'),
)
export const save = createApiAction(
  'person/attendance/daily/save',
  client.save('/api/:employmentType/:personId/attendance/daily'),
)
export const destroy = createApiAction(
  'person/attendance/daily/delete',
  client.destroy('/api/:employmentType/:personId/attendance/daily/:id'),
)

const updateList = (state, action) => {
  const found = state.list.find(rec => rec.id === action.payload.id)
  state.list = found
    ? state.list.map(person => (found.id === person.id ? action.payload : person))
    : [...state.list, action.payload]
}

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearPersonAttendanceDaily: state => {
      state.list = null
      state.query = {}
      state.count = null
    },
  },
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload
      })
      .addCase(get.fulfilled, updateList)
      .addCase(save.fulfilled, updateList)
      .addCase(destroy.fulfilled, (state, action) => {
        state.list = state.list.filter(item => item.id !== action.payload.id)
      })
  },
})
export const { clearPersonAttendanceDaily } = sessionSlice.actions

export default sessionSlice.reducer
