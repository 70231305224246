import {
    Autocomplete,
    Grid,
    InputAdornment,
    TextField,
    Paper,
  } from '@mui/material';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { SearchIcon } from 'components/Icons';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
  
export default function IncidentFilter({ dateRange, onDateChange }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [que, setQue] = useState(null);
    const [teacher, setTeacher] = useState(null);
    const students = useSelector((state) => state.settings.attendancePeriod?.list?.rows) || [];
    const searchQuery = { limit: 25, offset: 0 };
    const sessionAcademicYear = useSelector(state => state.session.academicYear)
  
    const applyFilter = async (key, value) => {
      if (key === 'que') {
        if (value) {
          searchQuery[key] = value;
        }
        if (teacher) {
          searchQuery.teacher = teacher;
        }
      }
      if (key === 'teacher') {
        if (value) {
          searchQuery.teacher = value;
        }
      }
    };
  
    const delayedSearch = useMemo(() => {
      return debounce((que, callback) => callback('que', que), 500);
    }, [dispatch]);
  
    const handleDateRangeChange = (newRange) => {
      
      onDateChange({
        startDate: newRange[0].format('YYYY-MM-DD'),
        endDate: newRange[1].format('YYYY-MM-DD'),
      });
    };

    return (
      <Paper sx={{ width: '100%', pt: 2, boxShadow: 'none' }}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={4}>
            <Autocomplete
              fullWidth
              size="small"
              freeSolo
              disablePortal
              options={students.length ? students : []}
              inputValue={que || ''}
              onInputChange={(event, value, reason) => {
                switch (reason) {
                  case 'reset':
                    break;
                  case 'clear':
                    applyFilter('que', null);
                    break;
                  case 'input':
                    setQue(value);
                    break;
                  default:
                    break;
                }
              }}
              getOptionLabel={(option) => {
                return `${option?.person?.lastName}, ${option?.person?.firstName}`;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label={t('Search Student...')}
                  onChange={(e) => {
                    setQue(e.target.value);
                    delayedSearch(e.target.value, applyFilter);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      const isOptionSelected = students.some((student) => {
                        return `${student?.person?.lastName}, ${student?.person?.firstName}` === que;
                      });
  
                      if (!isOptionSelected) {
                        e.preventDefault();
                      }
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      paddingRight: '10px',
                    },
                    endAdornment: (
                      <InputAdornment position="end" style={{ right: '0' }}>
                        <SearchIcon sx={{ mr: 0 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              fullWidth
              size="small"
              freeSolo
              disablePortal
              options={[]}
              inputValue={teacher || ''}
              onInputChange={(event, value) => {
                setTeacher(value);
                applyFilter('teacher', value);
              }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Search Teacher...')}
                  onChange={(e) => setTeacher(e.target.value)}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      paddingRight: '10px',
                    },
                    endAdornment: (
                      <InputAdornment position="end" style={{ right: '0' }}>
                        <SearchIcon sx={{ mr: 0 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <DateRangePicker
              label="Select Incident Date Range"
              startDate={dayjs(dateRange?.startDate).format('MM/DD/YYYY')}
              endDate={dayjs(dateRange?.endDate).format('MM/DD/YYYY')}
              setDateRange={handleDateRangeChange}
              minDate={sessionAcademicYear.startDate} 
              maxDate={sessionAcademicYear.endDate}
            />
          </Grid>
        </Grid>
      </Paper>
    );
}
  