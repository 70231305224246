import { useEffect } from 'react'
import $ from 'jquery'
import 'daterangepicker/daterangepicker.css'
import 'daterangepicker'
import DateRangeIcon from '@mui/icons-material/DateRange'
import './styles.css'
import { InputAdornment, TextField } from '@mui/material'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import moment from "moment";

export default function DateRangePicker({ label, startDate, endDate, setDateRange, leaveDates, eventDates, minDate, maxDate, disabled }) {
  const { t } = useTranslation()
  useEffect(() => {
    $(function () {
      $('input[name="datetimes"]').daterangepicker(
        {
          timePicker: false,
          autoApply: true,
          minDate: moment(minDate),
          maxDate: moment(maxDate),
          startDate: startDate ? moment(startDate) : moment(),
          endDate: endDate ? moment(endDate) : moment(),
          locale: {
            format: 'MM/DD/YYYY',
          },
          isInvalidDate: date => {
            const dayOfWeek = date.day()
            return (
              dayOfWeek === 0 ||
              dayOfWeek === 6 ||
              leaveDates?.some(leave => dayjs(leave.leaveDate).isSame(date, 'day')) ||
              eventDates?.some(event => dayjs(event.date).isSame(date, 'day'))
            )
          },
          isCustomDate: date => {
            if (leaveDates?.some((leave) => dayjs(leave.leaveDate).isSame(date, 'day'))) {
              return 'leaveDatesTaken'; 
            }
            if (eventDates?.some((event) => dayjs(event.date).isSame(date, 'day'))) {
              return 'eventDate'; 
            }
          },
        },
        function (startDate, endDate) {
          setDateRange([dayjs(startDate), dayjs(endDate)])
        },
      )
    })
  }, [startDate, endDate, setDateRange])

  return (
    <TextField
      fullWidth
      type="text"
      size="small"
      label={label || t('Date Range')}
      name="datetimes"
      placeholder="Select a date range"
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <DateRangeIcon className="calendar-icon" />
          </InputAdornment>
        ),
      }}
      disabled={disabled}
    />
  )
}
