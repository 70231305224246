import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import TableHeadCell from 'components/TableHeadCell'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { getPeriods } from 'store/lookup'
import ListItem from './ListItem'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import StaffSearch from 'components/StaffSearch'

export default function index({
  changeSelectedCourseSession,
  selectedCourseSession,
  filteredCourseSession,
  setSelectedDate,
  selectedDate,
  fetchDate,
  selectedDateFunc,
  setSelectedTeacher,
  selectedTeacher,
  setSelectedCourseSession,
  isAdmin,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { periods } = useSelector(state => state.lookup)
  const [selectedRow, setSelectedRow] = useState(null)
  const academicYearStartDate = useSelector(state => state.session.academicYear.startDate)
  const academicYearEndDate = useSelector(state => state.session.academicYear.endDate)
  const maxDateRule = dayjs(Math.min(new Date(academicYearEndDate).getTime(), new Date().getTime()))
  const attendanceTakenPeriods = useSelector(state => state.academic.schedule.list)?.attendanceTakenPeriods

  useEffect(() => {
    periods || dispatch(getPeriods())
  }, [periods])

  useEffect(() => {
    if (filteredCourseSession?.length > 0) {
      const firstCourseSession = filteredCourseSession[0];
      changeSelectedCourseSession(firstCourseSession.id, firstCourseSession.periodId);
      setSelectedRow(firstCourseSession);
    }
  }, [filteredCourseSession]);

  return (
    <Paper sx={{ width: '100%', p: 2, mb: 2 }}>
      <Grid container spacing={2}>
        {isAdmin && (
          <Grid item xs={12}>
            <StaffSearch
              label='Teacher'
              onSelect={setSelectedTeacher}
              value={selectedTeacher}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <DatePicker
            label={t('Date')}
            value={dayjs(selectedDate)}
            disabled={isAdmin && !selectedTeacher}
            onChange={e => {
              setSelectedDate(dayjs(e).format('YYYY-MM-DD')), selectedDateFunc()
            }}
            maxDate={maxDateRule}
            minDate={dayjs(academicYearStartDate)}
            slots={{
              openPickerIcon: CalendarTodayIcon,
            }}
            slotProps={{
              field: {
                fullWidth: true,
                InputLabelProps: { shrink: true },
                InputProps: {
                  sx: {
                    height: '45px',
                  },
                },
              },
              actionBar: {
                actions: ['clear'],
              },
            }}
          />
        </Grid>
        { !selectedTeacher || (filteredCourseSession && filteredCourseSession.length === 0) ? ( 
          <Grid item xs={12}>
            <Typography variant='body1' align='center' >{!selectedTeacher ? 'Select Teacher' : 'No Courses Assigned'}</Typography>
          </Grid>
        ) : (

          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead size='small' sx={{ backgroundColor: 'primary.main',}}>
                  <TableRow sx={{ borderRadius: '15px 0px' }}>
                    <TableHeadCell sx={{ py: 1.5, borderTopLeftRadius: 5, borderTopRightRadius: 5, color: 'primary.contrastText'}}>{t('Course Sessions')}</TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredCourseSession?.map((item, index) => (
                    <ListItem
                      key={index}
                      index={index}
                      item={item}
                      changeSelectedCourseSession={changeSelectedCourseSession}
                      selectedCourseSession={selectedCourseSession}
                      selectedDate={selectedDate}
                      fetchDate={fetchDate}
                      periods={periods}
                      setSelectedRow={setSelectedRow}
                      selectedRow={selectedRow}
                      attendanceTakenPeriods={attendanceTakenPeriods}
                      selectedTeacher={selectedTeacher}
                      filteredCourseSession={filteredCourseSession}
                      setSelectedCourseSession={setSelectedCourseSession}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}
