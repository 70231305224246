import { Avatar, IconButton } from '@mui/material'
import { useEmploymentType, usePerson } from 'components/Providers'
import AvatarIcon from '@mui/icons-material/PersonOutlined'
import { enqueueSnackbar } from 'notistack'
import { useEffect, useState } from 'react'

export default function ProfilePicture() {
  const person = usePerson()
  const employmentType = useEmploymentType()

  const [image, setImage] = useState()

  useEffect(() => {
    if (!isNaN(parseInt(person?.id))) {
      setImage(person?.employments[0]?.profilePicture?.location)
    }
  }, [person])

  const handleFile = async event => {
    const file = event.target.files[0]

    const formData = new FormData()
    formData.append('avatar', file)
    const url = `/api/${employmentType}/${person.id}/avatar`
    const res = await window.fetch(url, { method: 'POST', body: formData })
    if (res.status !== 200) {
      enqueueSnackbar(res.statusText, { variant: 'error' })
    }
    const data = await res.json()
    setImage(data.location)
  }

  if (!person?.id) return null

  return (
    <IconButton component="label" aria-label="person-avatar">
      <input hidden accept="image/*" type="file" onChange={handleFile} />
      {image ? (
        <Avatar sx={{ width: 80, height: 80, fontSize: 30 }} src={image}>
          <AvatarIcon />
        </Avatar>
      ):(
        <Avatar sx={{ width: 80, height: 80 }}>
        {[person.firstName[0], person.lastName[0]].join('')}
      </Avatar>
      )}
    </IconButton>
  )
}
