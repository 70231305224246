import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextField, MenuItem, TableRow, TableCell } from '@mui/material'
import IconProvider from 'components/IconProvider'
import '../../Utilities/AttendanceDropdown.css'
import { useEffect } from 'react'

export default function Period({ path, control, item, index, attendanceStatus, setValue }) {
  const { t } = useTranslation()
  const status = attendanceStatus?.find(status => status.id === item.periodStatus)

  useEffect(() => {
    setValue(`${path}.${index}.periodStatus`, item.periodStatus)
  }, [item.periodStatus])

  const isDiabled =  Boolean(item.id) && Boolean(item?.perAdmin)

  return (
    <TableRow hover key={index}>
      <TableCell>{item?.person?.lastName}</TableCell>
      <TableCell>{item?.person?.firstName}</TableCell>
      <TableCell key={item.id}>
        <Controller
          name={`${path}.${index}.periodStatus`}
          control={control}
          defaultValue={item.periodStatus}
          render={({ field: { value, ...field }, fieldState }) => {
            return isDiabled ? (
              <TextField
                fullWidth
                size="small"
                label={status?.name ? '' : t('Status')}
                placeholder="Status"
                disabled={isDiabled}
                defaultValue={`${status?.name} (per admin)` || ''}
                helperText={fieldState.error?.message}
                {...field}
                className="custom-text-field"
              />
            ) : (
              <TextField
                select
                fullWidth
                size="small"
                label={status?.name ? '' : t('Status')}
                placeholder="Status"
                value={value || item?.periodStatus || ''}
                helperText={fieldState.error?.message}
                {...field}
                className="custom-text-field"
              >
                {attendanceStatus?.map(statusItem => (
                  <MenuItem key={statusItem.id} value={statusItem.id}>
                    {<IconProvider icon={statusItem.ui?.icon} color={statusItem.ui?.color} />} {statusItem.name}
                  </MenuItem>
                ))}
              </TextField>
            )
          }}
        />
      </TableCell>
      <TableCell sx={{ textAlign: 'center' }}>
        {<IconProvider icon={item?.prevPeriodStatus?.ui?.icon} color={item?.prevPeriodStatus?.ui.color} />}
      </TableCell>
      <TableCell sx={{ textAlign: 'center' }}>
        {<IconProvider icon={item?.dailyStatus?.ui?.icon} color={item?.dailyStatus?.ui.color} />}
      </TableCell>
    </TableRow>
  )
}
