import { useTheme } from '@emotion/react'
import { Box, Grid, Stack } from '@mui/material'
import { DAYS } from 'constants'

export default function ScheduleDays({ dayListHeight, saturday, sunday,  }) {
  const theme = useTheme()

  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
      <Grid item xs={12} sx={{ marginBottom: '1px', pl: '2px', height: `${dayListHeight}` }}>
        <Stack direction={'row'} spacing={'2px'} >
          {DAYS.map((item, index) =>
            item !== 'Saturday' && item !== 'Sunday' ? (
              <Box id={item} key={index} sx={{ display: 'flex', p: '10px', alignItems: 'flex-start', border: '1px solid', borderColor: 'primary.main', borderRadius: '10px', backgroundColor: 'white' }}>
                <Box sx={{ backgroundColor: 'primary.dark', color: 'primary.contrastText', p: '10px', width: '100%', borderRadius: '10px', }}>                  
                  {item}
                </Box>
              </Box>
            ) : item === 'Saturday' && saturday ? (
              <Box id={item} key={index} sx={{ py: 2, mr: '1px', backgroundColor: theme.palette.action.selected }}>
                {item}
              </Box>
            ) : (
              item === 'Sunday' &&
              sunday && (
                <Box
                  id={item}
                  key={index}
                  sx={{
                    py: 2,
                    mr: '1px',
                    backgroundColor: theme.palette.action.selected,
                  }}
                >
                  {item}
                </Box>
              )
            ),
          )}
        </Stack>
      </Grid>
    </Grid>
  )
}
