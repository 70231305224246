import { Box, Paper } from '@mui/material'
import GradingListCoefficient from './Coefficient'
import GradingListScales from './Scales'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'store/settings/grading-coefficient'
import { useEffect, useState } from 'react'
import { search } from 'store/settings/grading-scale'
import Header from 'components/Header'
import { useTranslation } from 'react-i18next'


export default function GradingListIndex() {
  const dispatch = useDispatch()
  const { coefficient } = useSelector(state => state?.settings?.gradingCoefficient)
  const { list: scales } = useSelector(state => state?.settings?.gradingScale)
  const { academicYear } = useSelector(state => state.session)
  const [expanded, setExpanded] = useState('panel1')
  // const [scales, setScales] = useState()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(get())
    getScaleList()
  }, [academicYear])

  const getScaleList = async () => {
    await dispatch(search())
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  if (!coefficient || !scales) return null

  return (
    <Box >
      <Box component={Paper} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} sx={{ borderRadius: 0,  }}>
        <Header title={t('Grading')} small />
      </Box>
      <GradingListCoefficient data={coefficient} expanded={expanded} setExpanded={setExpanded} handleChange={handleChange} />
      <GradingListScales list={scales} expanded={expanded} setExpanded={setExpanded} handleChange={handleChange} />
    </Box>
  )
}
