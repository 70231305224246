import {
  Autocomplete,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material'
import dayjs from 'dayjs'
import { debounce } from 'lodash'
import Header from 'components/Header'
import { useTranslation } from 'react-i18next'
import { SearchIcon } from 'components/Icons'
import { DatePicker } from '@mui/x-date-pickers'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGradeLevelAndSections } from 'store/lookup'
import { search as searchStudentAttendance } from 'store/settings/attendance'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'

export default function StudentSearchBar({ date, setDate }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [que, setQue] = useState(null)
  const { gradeLevelAndSections } = useSelector(state => state.lookup)
  const [selectedGrade, setSelectedGrade] = useState('All Grades')
  const students = useSelector(state => state.settings.attendancePeriod?.list?.rows) || []
  const searchQuery = { limit: 25, offset: 0, date: dayjs(date).format('YYYY-MM-DD') }

  useEffect(() => {
    gradeLevelAndSections || dispatch(getGradeLevelAndSections())
  }, [gradeLevelAndSections])
  useEffect(() => {
    if (que) {
      searchQuery.que = que
    }
    getSelectedGrade(searchQuery)
    dispatch(searchStudentAttendance(searchQuery))
  }, [date])

  const applyFilter = async (key, value) => {
    if (key === 'grade') {
      if (value.gradeLevelId) {
        searchQuery.grade = value.gradeLevelId
      }
      if (value.gradeSection) {
        searchQuery.section = value.gradeSection
      }
      if (que) {
        searchQuery.que = que
      }
      dispatch(searchStudentAttendance(searchQuery))
    }
    if (key === 'que') {
      if (value) {
        searchQuery[key] = value
      }
      getSelectedGrade(searchQuery)
      dispatch(searchStudentAttendance(searchQuery))
    }
  }

  function getSelectedGrade(searchQuery) {
    if (selectedGrade && selectedGrade !== 'All Grades') {
      const grade = selectedGrade.charAt(0)
      searchQuery.grade = grade

      const section = selectedGrade.charAt(1)
      if (section) {
        searchQuery.section = selectedGrade
      }
    }
  }

  const delayedSearch = useMemo(() => {
    return debounce((que, callback) => callback('que', que), 500)
  }, [dispatch])

  const renderSelectGroup = el => {
    const items = el.section.map((p, index) => {
      return (
        p && (
          <MenuItem
            key={index}
            onClick={() => {
              applyFilter('grade', { gradeLevelId: el.id, gradeSection: p.id })
            }}
            value={(el.id, p.id)}
            sx={{ marginLeft: '17px' }}
          >
            {`Section: ${p.gradeSection}`}
          </MenuItem>
        )
      )
    })
    return [
      <MenuItem
        key={el}
        value={el.id}
        onClick={() => {
          setSelectedGrade(el.id)
          applyFilter('grade', { gradeLevelId: el.id })
        }}
      >
        {`Grade: ${el.id}`}
      </MenuItem>,
      items,
    ]
  }

  return (
    <Paper sx={{ width: '100%', px: 2, pb: 2, mb: 2 }}>
      <div style={{ marginLeft: -10 }}>
        <Header title={t('Daily Attendance')} small />
      </div>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Autocomplete
            fullWidth
            size="small"
            freeSolo
            disablePortal
            options={students.length ? students : []}
            inputValue={que || ''}
            onInputChange={(event, value, reason) => {
              switch (reason) {
                case 'reset':
                  break
                case 'clear':
                  applyFilter('que', null)
                  break
                case 'input':
                  setQue(value)
                  break
                default:
                  break
              }
            }}
            getOptionLabel={option => {
              return `${option?.person?.lastName}, ${option?.person?.firstName}`
            }}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                label={t('Search Student...')}
                onChange={e => {
                  setQue(e.target.value)
                  delayedSearch(e.target.value, applyFilter)
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    const isOptionSelected = students.some(student => {
                      return `${student?.person?.lastName}, ${student?.person?.firstName}` === que
                    })

                    if (!isOptionSelected) {
                      e.preventDefault()
                    }
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  style: {
                    paddingRight: '10px',
                  },
                  endAdornment: (
                    <InputAdornment position="end" style={{ right: '0' }}>
                      <SearchIcon sx={{ mr: 0 }} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="multi-grouped-select-label" size="small">
              Grade Level
            </InputLabel>
            <Select
              size="small"
              id="multi-grouped-select"
              labelId="multi-grouped-select-label"
              label="Grade Level"
              value={selectedGrade}
              onChange={event => setSelectedGrade(event.target.value)}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
            >
              <MenuItem
                value="All Grades"
                onClick={() => {
                  applyFilter('grade', 'All Grades')
                }}
              >
                All Grades
              </MenuItem>
              {gradeLevelAndSections?.map(el => renderSelectGroup(el))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3} xs={12}>
          <DatePicker
            label={t('Date')}
            defaultValue={dayjs()}
            onChange={date => {
              const formattedDate = dayjs(date)
              if (formattedDate.isValid()){
                setDate(dayjs(date).format('YYYY-MM-DD'))
              }
            }}
            slots={{
              openPickerIcon: CalendarTodayIcon,
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                InputLabelProps: { shrink: true },
                InputProps: {
                  sx: {
                    height: '45px',
                  },
                },
              },
            }}
            renderInput={params => <TextField {...params} />}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
