import { TableCell, TableRow } from '@mui/material'
// import { Link as NavLink } from 'react-router-dom'
// import { useBasePath } from 'components/Providers'

export default function CourseItem({ item }) {
  // const path = useBasePath()
  return (
    <TableRow hover>
      {/* <TableCell>
        <Link component={NavLink} underline="hover" color="inherit" to={`${path}/${item.id}`}>
          {item.course.name} - {item.name}
        </Link>
      </TableCell> */}
      <TableCell>{item?.course?.name}</TableCell>
      <TableCell>{item?.name}</TableCell>
      <TableCell>{item?.enrollmentCount}</TableCell>
      <TableCell>{item?.periodCount}</TableCell>
      <TableCell>{item?.room?.name || ''} </TableCell>
    </TableRow>
  )
}
