import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MobileStepper,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import Header from 'components/Header'
import { BasePathProvider } from 'components/Providers'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import 'utils/global.scss'
import { useDispatch, useSelector } from 'react-redux'
import { save } from 'store/settings/course'
import { useNavigate, useParams } from 'react-router-dom'
import _, { pick } from 'lodash'
import SchedulingCoursesFormSearchBar from './Search'
import { search } from 'store/provider/course'

const initialValues = {
  parentId: null,
  courseType: null,
  subjectId: null,
  localCourseCode: null,
  name: null,
  scedCourseName: null,
  scedSubjectAreaCode: null,
  scedCourseIdentifier: null,
  scedGradeSpan: null,
  scedCourseSequence: null,
  scedCourseType: null,
  gradeLevelId: null,
  credit: null,
  courseLevelId: null,
  gradingScaleId: null,
  description: null,
}

const credits = [0, 0.25, 0.5, 1.0, 1.25, 2.0, 2.5, 3.0, 3.5, 3.75, 4.0, 5.0, 6.0, 7.0, 10.0, 12.5, 15.0]

export default function SchedulingCoursesForm({ data, courseType, setCourseType, selectedCourse, setSelectedCourse }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const submitButton = useRef()
  const { courseId } = useParams()

  const gradeLevels = useSelector(state => state.lookup.gradeLevels)
  const gradingMethods = useSelector(state => state.lookup.gradingMethods)

  const types = useSelector(state => state.lookup.types)
  const scedCourseTypes = types?.filter(type => type.category === 'scedCourseType') || []
  const scedCourseLevels = types?.filter(type => type.category === 'scedCourseLevel') || []
  const courseTypes = types?.filter(type => type.category === 'courseType') || []

  const gradingScales = useSelector(state => state.settings.gradingScale.list)?.filter(item => item.enabled === true)

  const districtId = useSelector(state => state.session.district.id)
  const theme = useSelector(state => state.session.appearance)
  const courseSubjects = useSelector(state => state.lookup.courseSubjects)
  const courses = useSelector(state => state.settings.course)?.list
  const courseLevel = useSelector(state => state.lookup.courseLevel)

  const { query, list } = useSelector(state => state.provider.course)

  useEffect(() => {
    list || dispatch(search({ ...query, offset: 0 }))
  }, [])

  const basePath = `/settings/scheduling/courses/`
  const [activeStep, setActiveStep] = useState(data?.id ? 1 : 0)
  const maxStep = 2

  const handleNext = () => {
    if (activeStep < maxStep) {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(prevActiveStep => prevActiveStep - 1)
    }
  }

  const schema = yup.object().shape({
    subjectId: yup.number().nullable().required('Subject is required'),
    //gradeLevelId: yup.string().nullable().required('Grade Level is required'),
    credit: yup.number().nullable().required('Credit is required'),
    courseType: yup.string().nullable().required('Course Type is required'),
    courseLevelId: yup.string().nullable().required('Course Level is required'),
    gradingMethodId: yup.string().nullable().required('Grading Method is required'),
    gradingScaleId: yup.number().nullable().required('Grading Scale is required'),
  })

  const { control, handleSubmit, getValues, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues:
      data && courseId !== 'new'
        ? {
            ...data,
            gradeLevelId: data.gradeLevel?.id,
            gradingScaleId: data.gradingScale?.id,
            courseLevelId: data.courseLevelId,
            subjectId: data.courseSubject?.id,
          }
        : { ...initialValues },
  })

  useEffect(() => {
    let defaultCheckBoxValue = false
    if (courseId === 'new' && courseType === 'sced') defaultCheckBoxValue = true

    if (courses && courseType === 'sced') {
      const selectedCourseDatas = _.pick(
        list.find(item => item.id === selectedCourse),
        [
          'code',
          'courseType',
          'courseLevelId',
          'subjectId',
          'name',
          'credit',
          'description',
          'localCourseCode',
          'scedCourseSequence',
          'scedSubjectAreaCode',
          'scedCourseName',
          'scedCourseIdentifier',
          'scedCourseType',
          'scedCourseLevel',
          'scedGradeSpan',
          'gradeLevelId',
          'gradingScaleId',
          'gradingMethodId',
        ],
      )

      selectedCourseDatas.subjectId = list.find(item => item.id === selectedCourse)?.courseSubject?.id
      if (courseId === 'new') {
        reset({
          ...selectedCourseDatas,
          enabled: true,
          reportState: defaultCheckBoxValue,
          exportToReportCard: defaultCheckBoxValue,
          exportToTranscript: defaultCheckBoxValue,
        })
      } else {
        reset({
          ...selectedCourseDatas,
        })
      }
    }
    if (courseType === 'non-sced' && courseId === 'new') {
      reset({
        initialValues /*, enabled: true*/,
        reportState: defaultCheckBoxValue,
        exportToReportCard: defaultCheckBoxValue,
        exportToTranscript: defaultCheckBoxValue,
      })
    }
  }, [selectedCourse, activeStep, courseLevel])

  const onSubmit = async formData => {
    if (data?.id) {
      formData = pick(formData, [
        'id',
        'code',
        'courseType',
        'courseLevelId',
        'subjectId',
        'name',
        'enabled',
        'credit',
        'districtId',
        'description',
        'localCourseCode',
        'scedCourseSequence',
        'scedSubjectAreaCode',
        'scedCourseName',
        'scedCourseIdentifier',
        'scedCourseType',
        'scedCourseLevel',
        'scedGradeSpan',
        'reportState',
        'exportToTranscript',
        'exportToReportCard',
        'gradeLevelId',
        'gradingScaleId',
        'gradingMethodId',
      ])
      formData.credit ? +formData.credit : null
      const res = await dispatch(save({ id: data?.id, ...formData }))
      if (res.payload) {
        navigate(`${basePath}`)
      }
    } else {
      formData.parentId = selectedCourse
      formData.districtId = districtId
      formData.credit ? +formData.credit : null
      if (!formData.enabled) formData.enabled = false
      if (!formData.reportState) formData.reportState = false
      if (!formData.exportToReportCard) formData.exportToReportCard = false
      if (!formData.exportToTranscript) formData.exportToTranscript = false
      const res = await dispatch(save(formData))
      if (res.payload) {
        navigate(`${basePath}`)
      }
    }
  }

  const onErrors = errors => console.log(errors)

  return (
    <BasePathProvider value={`${basePath}`}>
      <Box
        data-component="SchedulingCoursesForm"
        component="form"
        onSubmit={handleSubmit(onSubmit, onErrors)}
        noValidate
        sx={{ overflowY: 'scroll', border: 'none' }}
      >
        <Header title={data?.id ? `Course Details` : t('Add Course')} small close borderBottom />

        {activeStep === 0 && !data?.id && (
          <SchedulingCoursesFormSearchBar
            getValues={getValues}
            selectedCourse={selectedCourse}
            setSelectedCourse={setSelectedCourse}
            courseType={courseType}
            setCourseType={setCourseType}
          />
        )}
        {activeStep === 1 && (
          <Grid container sx={{ p: 3 }} spacing={2}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <Typography
                  sx={{
                    color: theme === 'dark' ? 'rgba(255, 255, 255, 0.60)' : 'rgba(0, 0, 0, 0.60)',
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '12px',
                    letterSpacing: '0.15px',
                  }}
                >
                  {t('Course Status')}
                </Typography>
                <RadioGroup row sx={{ pl: 2 }}>
                  <Controller
                    name="enabled"
                    control={control}
                    defaultValue={true}
                    render={({ field: { value, onChange, ...field }, fieldState }) => {
                      return (
                        <FormControlLabel
                          error={!!fieldState.error}
                          control={<Checkbox color="primary" checked={value ?? false} onChange={onChange} />}
                          label={t('Currently Active')}
                          labelPlacement="end"
                          {...field}
                        />
                      )
                    }}
                  />
                  <Controller
                    name="reportState"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return (
                        <FormControlLabel
                          error={!!fieldState.error}
                          control={<Checkbox color="primary" checked={value} disabled />}
                          label={t('Report to State')}
                          labelPlacement="end"
                          {...field}
                        />
                      )
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="localCourseCode"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      size="small"
                      label={t('Local Course Code')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      size="small"
                      label={t('Local Course Name')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
            {((courseId === 'new' && courseType === 'sced') || (data?.parentId && courseId)) && (
              <Grid item xs={6} md={6}>
                <Controller
                  name="scedSubjectAreaCode"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <TextField
                        fullWidth
                        size="small"
                        label={t('SCED Subject Area Code')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        disabled={true}
                      />
                    )
                  }}
                />
              </Grid>
            )}

            {((courseId === 'new' && courseType === 'sced') || (data?.parentId && courseId)) && (
              <Grid item xs={6} md={6}>
                <Controller
                  name="scedCourseIdentifier"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <TextField
                        fullWidth
                        size="small"
                        label={t('SCED Course Identifier')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        disabled={true}
                      />
                    )
                  }}
                />
              </Grid>
            )}
            {((courseId === 'new' && courseType === 'sced') || (data?.parentId && courseId)) && (
              <Grid item xs={6} md={6}>
                <Controller
                  name="scedCourseName"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <TextField
                        fullWidth
                        size="small"
                        label={t('SCED Course Name')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        disabled={true}
                      />
                    )
                  }}
                />
              </Grid>
            )}

            {((courseId === 'new' && courseType === 'sced') || (data?.parentId && courseId)) && (
              <Grid item xs={6} md={6}>
                <Controller
                  name="scedGradeSpan"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <TextField
                        fullWidth
                        size="small"
                        label={t('SCED Grade Span')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                      />
                    )
                  }}
                />
              </Grid>
            )}
            {((courseId === 'new' && courseType === 'sced') || (data?.parentId && courseId)) && (
              <Grid item xs={6} md={6}>
                <Controller
                  name="scedCourseSequence"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <TextField
                        fullWidth
                        size="small"
                        label={t('SCED Course Sequence')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                      />
                    )
                  }}
                />
              </Grid>
            )}
            {((courseId === 'new' && courseType === 'sced') || (data?.parentId && courseId)) && (
              <Grid item xs={6} md={6}>
                <Controller
                  name="scedCourseType"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <Autocomplete
                        fullWidth
                        options={scedCourseTypes}
                        getOptionLabel={option => option.name}
                        noOptionsText={t('No Options')}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label={t('SCED Course Type')}
                            size="small"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                        {...field}
                        value={scedCourseTypes.find(option => option.code === field.value)}
                        onChange={(event, newValue) => {
                          field.onChange(newValue ? newValue.code : null)
                        }}
                      />
                    )
                  }}
                />
              </Grid>
            )}
            {((courseId === 'new' && courseType === 'sced') || (data?.parentId && courseId)) && (
              <Grid item xs={6} md={6}>
                <Controller
                  name="scedCourseLevel"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <Autocomplete
                        fullWidth
                        options={scedCourseLevels}
                        getOptionLabel={option => option.name}
                        noOptionsText={t('No Options')}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label={t('SCED Course Level')}
                            size="small"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                        {...field}
                        value={scedCourseLevels.find(option => option.code === field.value)}
                        onChange={(event, newValue) => {
                          field.onChange(newValue ? newValue.code : null)
                        }}
                      />
                    )
                  }}
                />
              </Grid>
            )}
            <Grid item xs={6} md={6}>
              <Controller
                name="subjectId"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <Autocomplete
                      fullWidth
                      options={courseSubjects}
                      getOptionLabel={option => option.name}
                      noOptionsText={t('No Options')}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={t('Subject Area')}
                          size="small"
                          required
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                      {...field}
                      value={courseSubjects.find(option => option.id === field.value)}
                      onChange={(event, newValue) => {
                        field.onChange(newValue ? newValue.id : null)
                      }}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="gradeLevelId"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <Autocomplete
                      fullWidth
                      options={gradeLevels.filter(item => item.enabled === true)}
                      getOptionLabel={option => option.name}
                      noOptionsText={t('No Options')}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={t('Grade Level')}
                          size="small"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                      {...field}
                      value={gradeLevels.find(option => option.id === field.value)}
                      onChange={(event, newValue) => {
                        field.onChange(newValue ? newValue.id : null)
                      }}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="credit"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <Autocomplete
                      fullWidth
                      options={credits}
                      getOptionLabel={(option) => option.toString()}
                      noOptionsText={t('No Options')}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={t('Credit')}
                          size="small"
                          required
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                      {...field}
                      value={field.value ?? null}
                      onChange={(event, newValue) => {
                        field.onChange(newValue ?? null)
                      }}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="courseType"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <Autocomplete
                      fullWidth
                      options={courseTypes}
                      getOptionLabel={option => option.name}
                      noOptionsText={t('No Options')}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={t('Course Type')}
                          size="small"
                          required
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                      {...field}
                      value={courseTypes.find(option => option.code === field.value)}
                      onChange={(event, newValue) => {
                        field.onChange(newValue ? newValue.code : null)
                      }}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="courseLevelId"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <Autocomplete
                      fullWidth
                      options={courseLevel}
                      getOptionLabel={option => option.name}
                      noOptionsText={t('No Options')}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={t('Course Level')}
                          size="small"
                          required
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                      {...field}
                      value={courseLevel.find(option => option.id === field.value) || null }
                      onChange={(event, newValue) => {
                        field.onChange(newValue ? newValue.id : null);
                      }}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="gradingMethodId"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <Autocomplete
                      fullWidth
                      options={gradingMethods}
                      getOptionLabel={option => option.name}
                      noOptionsText={t('No Options')}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={t('Grading Method')}
                          size="small"
                          required
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                      {...field}
                      value={gradingMethods.find(option => option.id === field.value)}
                      onChange={(event, newValue) => {
                        field.onChange(newValue ? newValue.id : null)
                      }}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="gradingScaleId"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <Autocomplete
                      fullWidth
                      options={gradingScales}
                      getOptionLabel={option => option.name}
                      noOptionsText={t('No Options')}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={t('Grading Scale')}
                          size="small"
                          required
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                      {...field}
                      value={gradingScales.find(option => option.id === field.value )}
                      onChange={(event, newValue) => {
                        field.onChange(newValue ? newValue.id : null)
                      }}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <Typography
                  sx={{
                    color: theme === 'dark' ? 'rgba(255, 255, 255, 0.60)' : 'rgba(0, 0, 0, 0.60)',
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '12px',
                    letterSpacing: '0.15px',
                  }}
                >
                  {t('Visibility')}
                </Typography>
                <Stack flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} sx={{ pl: 2 }}>
                  <Controller
                    name="exportToTranscript"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={value}
                              required
                              size="small"
                              error={!!fieldState.error}
                              {...field}
                            />
                          }
                          label={t('Transcript')}
                        />
                      )
                    }}
                  />
                  <Controller
                    name="exportToReportCard"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={value}
                              required
                              size="small"
                              error={!!fieldState.error}
                              {...field}
                            />
                          }
                          label={t('Report Card')}
                        />
                      )
                    }}
                  />
                </Stack>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      multiline
                      rows={6}
                      size="small"
                      label={t('Course Description')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
          </Grid>
        )}

        <Box>
          <MobileStepper
            variant={!data?.id ? 'dots' : null}
            steps={maxStep}
            position="static"
            activeStep={activeStep}
            sx={{
              bgcolor: 'transparent',
              px: 4,
              '.MuiMobileStepper-dotActive': { backgroundColor: 'primary.main' },
            }}
            nextButton={
              activeStep < maxStep - 1 ? (
                <Button
                  color="primary"
                  size="small"
                  onClick={handleNext}
                  disabled={courseType === 'sced' && !selectedCourse}
                >
                  {t('Next')}
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    submitButton.current.click()
                    // submitButton.current.disabled = true
                  }}
                  color="primary"
                >
                  {data?.id ? t('Save Course') : t('Add Course')}
                </Button>
              )
            }
            backButton={
              activeStep > 0 && !data?.id ? (
                <Button color="inherit" size="small" onClick={handleBack}>
                  <KeyboardArrowLeft /> {t('Back')}
                </Button>
              ) : (
                <Button disabled={true} />
              )
            }
          />
        </Box>

        <Button ref={submitButton} type="submit" sx={{ display: 'none' }} />
      </Box>
    </BasePathProvider>
  )
}
