import {
  Box,
  Button,
  Checkbox,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { EditIcon, PersonAddIcon } from 'components/Icons'
import TableHeadCell from 'components/TableHeadCell'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { AiOutlineDelete } from 'react-icons/ai'
import { getCourseSessionsEnrollmentType, getTeachers } from 'store/lookup'
import { useEffect, useState } from 'react'
import Confirm from 'components/Dialog'
import { destroy } from 'store/settings/course-session-enrollments'
import { destroyCourseSessionEnrollment } from 'store/settings/course-session'
import { search } from 'store/settings/course-session-enrollments'
import dayjs from 'dayjs'

const heads = ['Name', 'Role', 'Start Date', 'End Date', 'Sub', 'Actions']

export default function SchedulingCourseSessionsFormAssignTeacher() {
  const dispatch = useDispatch()
  const [confirm, setConfirm] = useState({ status: false, id: null })

  const { t } = useTranslation()
  const theme = useSelector(state => state.session.appearance)
  const navigate = useNavigate()
  const basePath = `/settings/scheduling/course-sessions/`
  const { courseSessionId } = useParams()
  const academicYearId = useSelector(state => state.session?.academicYear?.id)
  const courseSessionsEnrollment = useSelector(state => state.settings?.courseSessionsEnrollments?.list)?.filter(
    cse => cse?.schoolEnrollment?.person?.type === 'staff',
  )
  const staffRole = useSelector(state => state.lookup?.courseSessionsEnrollmentType)
  const { query, rows: teachers } = useSelector(state => state.lookup.teachers)

  useEffect(() => {
    teachers || dispatch(getTeachers({ ...query, offset: 0, limit: query?.limit }))
    staffRole || dispatch(getCourseSessionsEnrollmentType())
  }, [])

  useEffect(() => {
    if (+courseSessionId) {
      dispatch(search({ courseSessionId: +courseSessionId, academicYearId: +academicYearId }))
    }
  }, [courseSessionId])

  const onDestroy = () => {
    const id = confirm.id
    setConfirm({ status: false, id: null })
    if (id && +courseSessionId) {
      dispatch(destroy({ id, courseSessionId: +courseSessionId }))
      dispatch(destroyCourseSessionEnrollment(id))
    }
  }

  if (!(teachers && staffRole && courseSessionsEnrollment)) return null

  return (
    <Box sx={{ pt: 3, pl: 1 }}>
      <Stack flexDirection={'row'} justifyContent={'space-between'}>
        <Typography variant="h6">{t('Assign Teacher(s)')}</Typography>
        <Button
          onClick={() => navigate(`${basePath}${courseSessionId}/teacher`)}
          size="small"
          color="primary"
          variant="outlined"
          startIcon={<PersonAddIcon />}
        >
          {t('Add')}
        </Button>
      </Stack>
      <Table
        sx={{
          border: '1px solid',
          borderColor: theme === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
          my: 2,
        }}
      >
        <TableHead>
          <TableRow>
            {heads.map((item, index) => (
              <TableHeadCell
                key={index}
                sx={{
                  fontWeight: '500 !important',
                }}
              >
                {t(item)}
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {courseSessionsEnrollment?.map(
            (item, index) =>
              item?.id && (
                <TableRow key={index}>
                  <TableCell>
                    <Link
                      component={NavLink}
                      underline="hover"
                      color="inherit"
                      to={`${basePath}${courseSessionId}/teacher/${item?.id}`}
                    >
                      {item?.schoolEnrollment?.person?.lastName},{' '}
                      {item?.schoolEnrollment?.person?.firstName}
                    </Link>
                  </TableCell>
                  <TableCell>{item?.courseSessionEnrollmentType?.name}</TableCell>
                  <TableCell>{dayjs(item?.startDate).format('MM/DD/YYYY')}</TableCell>
                  <TableCell>{item?.endDate ? dayjs(item?.endDate).format('MM/DD/YYYY') : '-'}</TableCell>
                  <TableCell>
                    <Checkbox color='primary' size="small" checked={item?.substitute} />
                  </TableCell>
                  <TableCell>
                    <AiOutlineDelete
                      fontSize={24}
                      color="disabled"
                      onClick={() => setConfirm({ status: true, id: item?.id })}
                      style={{
                        cursor: 'pointer',
                        marginRight: '5px',
                        color: theme === 'dark' ? 'rgba(255, 255, 255, 0.56) !important' : 'rgba(0, 0, 0, 0.56)',
                      }}
                    />
                    <Link
                      component={NavLink}
                      underline="hover"
                      color="inherit"
                      to={`${basePath}${courseSessionId}/teacher/${item?.id}`}
                    >
                      <EditIcon
                        fontSize="medium"
                        sx={{ color: theme === 'dark' ? 'rgba(255, 255, 255, 0.56)' : 'rgba(0, 0, 0, 0.56)' }}
                      />
                    </Link>
                  </TableCell>
                </TableRow>
              ),
          )}
        </TableBody>
      </Table>
      <Confirm open={confirm.status} onClose={() => setConfirm({ status: false, id: null })} onConfirm={onDestroy} />
    </Box>
  )
}
