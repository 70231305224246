import { Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import { useTranslation } from 'react-i18next'
import ListItem from './ListItem'
import { useDispatch, useSelector } from 'react-redux'
import { search as searchStudentAttendance, setSearchLimit, setSearchOffset } from 'store/settings/attendance'
import _ from 'lodash'
import { ROWS_PER_PAGE_OPTIONS } from 'constants'

const tableHeadCellStyle = {
  fontWeight: '500 !important',
  borderRight: '2px solid white',
  color: 'white',
}

export default function AttendanceList({ setStudent, date }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { rows, count } = useSelector(state => state.settings.attendance?.list) || { rows: [], count: 0 }
  const { query } = useSelector(state => state.settings.attendance)

  const periods = useSelector(state => state.lookup.periods)
  const handleChangePage = (event, page) => {
    const limit = query?.limit || 25
    dispatch(setSearchOffset(limit * page))
    dispatch(searchStudentAttendance({ limit, offset: limit * page, date }))
  }

  const handleChangeRowsPerPage = event => {
    const limit = event.target.value
    dispatch(searchStudentAttendance({ limit, date }))
    dispatch(setSearchLimit(limit))
    dispatch(setSearchOffset(0))
  }

  if (!rows) return null
  return (
    <Paper sx={{ width: '100%', p: 2, mb: 2 }}>
      <TableContainer>
        <Table>
          <TableHead sx={{ backgroundColor: 'primary.main' }}>
            <TableRow>
              <TableHeadCell sx={{ ...tableHeadCellStyle, width: '15em' }}>{t('Student Name')}</TableHeadCell>
              <TableHeadCell sx={tableHeadCellStyle}>{t('Daily')}</TableHeadCell>
              <TableHeadCell sx={{ ...tableHeadCellStyle, width: '13em' }}>{t('Note')}</TableHeadCell>
              {periods?.map((item, index) => (
                <TableHeadCell key={index} sx={tableHeadCellStyle}>
                  {t(item.name)}
                </TableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((item, index) => (
              <ListItem key={index} item={item} setStudent={setStudent} date={date} />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={count || 0}
          page={query?.limit ? query?.offset / query?.limit : 0}
          onPageChange={handleChangePage}
          rowsPerPage={query?.limit || 25}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={_.uniq(ROWS_PER_PAGE_OPTIONS)}
        />
      </TableContainer>
    </Paper>
  )
}
