import client from 'utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from 'utils/redux-tool'

const name = 'attendance-report'
const initialState = {
  list: null,
}
// export const search = createApiAction('academic/attendance/search', client.search('/api/academic/attendance'))
const basePath = '/api/member/:type/:memberId/attendance-report'

export const retrieve = createApiAction('academic/student/attendance/get', client.retrieve(basePath))

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(retrieve.pending, state => {
        state.list = null
      })
      .addCase(retrieve.fulfilled, (state, action) => {
        state.list = action.payload
      })
  },
})

export default sessionSlice.reducer
