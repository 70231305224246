import { useState } from 'react'
import { IconButton, TableCell, TableRow, Link, styled } from '@mui/material'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckedIcon from '@mui/icons-material/CheckBoxOutlined'
import UnCheckedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded'

import Confirm from '../../../components/Dialog'

/* import DocumentEdit from './DocumentEdit' */
import { useDispatch } from 'react-redux'
import { deleteFile } from '../../../store/employment'
import { useBasePath, useEmploymentType, usePerson } from '../../../components/Providers'

import client from '../../../utils/api'

const StyledTableRow = styled(TableRow)(() => {
  return {
    '&:last-child td, &:last-child th': {
      // border: 0,
    },
  }
})

export default function DecumentItem({ document }) {
  /*   const [edit, setEdit] = useState(false) */
  const [confirm, setConfirm] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const basePath = useBasePath()
  const employmentType = useEmploymentType()
  const person = usePerson()
  const detailPath = `${basePath}/${document.id}`
  const onDelete = () => {
    setConfirm(true)
  }

  const onConfirm = () => {
    setConfirm(false)
    dispatch(
      deleteFile({ employmentType, personId: person.id, employmentId: person.employments[0].id, id: document.id }),
    )
  }

  const download = async event => {
    event.preventDefault()
    try {
      const { data: url } = await client.get(`/api/file/${document.file?.id}/url`)
      let a = window.document.createElement('a')
      a.href = url
      a.download = document.name
      a.target = '_blank'
      a.click()
    } catch (err) {
      console.log('---error--->', err.message)
    }
  }

  /*   if (edit || !document.id) {
      return <DocumentEdit document={document} onClose={() => setEdit(false)} />
    } */
  return (
    <StyledTableRow hover>
      <TableCell>
        <Link href="#" onClick={download}>
          {document.name}
        </Link>
      </TableCell>
      <TableCell>{document.documentType}</TableCell>
      <TableCell>{document.approved ? <CheckedIcon /> : <UnCheckedIcon />}</TableCell>
      <TableCell>{document.expirationDate && dayjs(document.expirationDate).format('MMM DD, YYYY')}</TableCell>
      <TableCell>{document.shared ? <CheckedIcon /> : <UnCheckedIcon />}</TableCell>
      <TableCell>
        <IconButton onClick={() => navigate(detailPath) /* setEdit(true) */}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
        <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
      </TableCell>
    </StyledTableRow>
  )
}
