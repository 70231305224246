import { useDispatch, useSelector } from "react-redux";
import { getEnrollment, setEnrollment } from "../store/session";
import { useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import NextIcon from '@mui/icons-material/ChevronRight'
import { SubTitle, Body } from './typo'
import { capitalize } from 'lodash'

export default function Enrollments({ children}) {
  const { person, district, enrollments } = useSelector((state) => state.session);
  const dispatch = useDispatch();

  console.log('enrollments', enrollments)

  useEffect(() => {
    enrollments || dispatch(getEnrollment());
  }, [Boolean(enrollments), dispatch]);

  const selectEnrollment = async ( id ) => {
    await dispatch(setEnrollment({ id }));
  };

  const EnrollmentList = () => (
    <TableContainer
      data-component="EnrollmentList"
      component={Paper}
      sx={{ p: 2, display: "flex", flexDirection: "column", height: 350 }}
    >

        <SubTitle color="primary">Enrollments</SubTitle>
        <Body>Please re-connect with any enrollment listed below.</Body>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>School Enrollment</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {enrollments?.map((item) => (
            <TableRow
              key={item.id}
              hover={false}
            >
              <TableCell>{item?.campusName || item?.districtName}</TableCell>
              <TableCell>{capitalize(item.type)}</TableCell>
              <TableCell>
                <Button
                  onClick={() => selectEnrollment(item?.id)}
                  variant="text"
                  color="primary"
                  size="small"
                  endIcon={<NextIcon fontSize="small" />}
                >
                  Connect
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
    if ( person && district && children ) return children
    return enrollments ? <EnrollmentList /> : null
}