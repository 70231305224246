import { Box, LinearProgress, Paper, Table, TableBody, TableHead, TableRow } from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import CourseSessionItem from './CourseSessionItem'
import { useTranslation } from 'react-i18next'
import { BasePathProvider } from 'components/Providers'
// import Header from 'components/Header'

export default function CourseList({ list }) {
  const basePath = `/academic/course-session`
  const headers = ['Course Name', 'Session Name', 'Enrollments', 'Periods', 'Room']
  const { t } = useTranslation();

  if (!list) return <LinearProgress />

  return (
    <BasePathProvider value={`${basePath}`}>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '10px' }}>
        <Paper sx={{ width: '100%', p: 2 }}>
          <Table >
            <TableHead>
              <TableRow>
                {headers.map((item, index) => (
                  <TableHeadCell 
                    sx={{
                      backgroundColor: 'primary.main', 
                      color: 'primary.contrastText',
                      ...index === 0 && { borderTopLeftRadius: 5 },
                      ...index === headers.length - 1 && { borderTopRightRadius: 5 },
                    }} 
                    key={index}>
                    {t(item)}
                  </TableHeadCell>
                ))}
                  {/* <Header small title={`Course Session   ( Total :${list?.length} ) `} sx={{ marginLeft: '0px' }} /> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {list?.map(item => (
                <CourseSessionItem key={item.id} item={item} />
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Box>
    </BasePathProvider>
  )
}
