import {
  Box,
  Button,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import { useTranslation } from 'react-i18next'
import IconProvider from 'components/IconProvider'
import { useEffect, useState } from 'react'
import { FaSave } from 'react-icons/fa'
import Period from './Period'
import { useFieldArray, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { save as teacherSavePeriodAttendance } from 'store/academic/attendance-period'
import { updateAttendanceTakenPeriods } from 'store/academic/schedule'
import NoRecords from 'components/NoRecords'
import * as snackbar from 'utils/snackbar'

const tableHeadCellStyle = {
  fontWeight: '500 !important',
  color: 'white',
  width: '9em',
}

export default function index({ list, attendanceStatus, selectedDate, isAdmin, selectedCourseSession, filteredCourseSession, selectedTeacher, changeSelectedCourseSession }) {
  const { t } = useTranslation()
  const [status, setStatus] = useState()
  const dispatch = useDispatch()

  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      periods: list,
    },
  })

  function applyToAll() {
    if (status) {
      list?.forEach(item => {
          item.periodStatus = status
      })
    }
  }

  const selectedCourseName = filteredCourseSession?.find(item => item.id === selectedCourseSession)?.name 
  const selectedCourseId = filteredCourseSession?.find(item => item.id === selectedCourseSession)?.id 
  const selectedCoursePeriodId = filteredCourseSession?.find(item => item.id === selectedCourseSession)?.periodId 
  
  const periodArrayControl = useFieldArray({ control, name: 'periods', keyName: 'uuid' })

  useEffect(() => {
    applyToAll()
    reset({ periods: list })
  }, [status])

  const onSubmit = async formData => {
    const periodAttendanceList = []
    formData.periods.map(item =>
      periodAttendanceList.push({
        courseSessionEnrollmentId: item.courseSessionEnrollmentId,
        id: item.id,
        date: selectedDate,
        day: new Date(selectedDate).getDay() + 1,
        periodId: item.periodId,
        statusId: item.periodStatus,
      }),
    )

    const handleAttendanceResponse = data => {
        let isChecked = false;
        if (data.payload?.periodAttendance?.some(item => item.status && item.status.id)) {
          isChecked = true;
        
          dispatch(updateAttendanceTakenPeriods({
            periodId: data.payload?.periodAttendance?.[0]?.period?.id,
            isChecked,
          }));
          snackbar.success('Attendance Saved Successfully');
          changeSelectedCourseSession(selectedCourseId, selectedCoursePeriodId);
        } else {
          snackbar.error('Failed to save attendance');
          }
      }

      dispatch(teacherSavePeriodAttendance({ courseSessionId: selectedCourseSession, periodAttendanceList })).then(
        data => {
          handleAttendanceResponse(data)
        },
      )
    
  }

  const onErrors = errors => console.log(errors)

  if (list.length === 0 || !selectedCourseName || !selectedTeacher) return (
  <Paper sx={{ width: '100%', p: 2, mb: 2 }}>
    <NoRecords message={'Select Teacher to see attendance list'} />
  </Paper>
  )

  return (
    <Paper
      data-component="AttendanceForm"
      component="form"
      onSubmit={handleSubmit(onSubmit, onErrors)}
      noValidate
      sx={{ width: '100%', p: 2, mb: 2 }}
    >
      <TableContainer>
        <Box sx={{ display: 'flex', flexDirection: 'row', py: 2, gap: 2, alignItems: 'center', overflowY: 'auto', width: '100%' }}>
          <Box width="50%">
            <Typography variant="heading1">{selectedCourseName}</Typography>
          </Box>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'} sx={{ gap: 2, width: '50%' }}>
            <TextField
              select
              fullWidth
              size='small'
              label={t('One Click Attendance')}
              placeholder="One Click Attendance"
              onChange={e => {
                setStatus(e.target.value)
              }}
              className="custom-text-field"
            >
              {attendanceStatus?.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {<IconProvider icon={item.ui?.icon} color={item.ui?.color} />} {item.name}
                </MenuItem>
              ))}
            </TextField>
            <Button
              type="submit"
              color="primary"
              size="small"
              variant="outlined"
              sx={{ cursor: 'pointer', opacity: list?.length ? 1 : 0, height: '35px', px: 2}}
              startIcon={<FaSave />}
            >
              {t('SAVE')}
            </Button>
          </Box>
        </Box>
        <Table size="small">
          <TableHead sx={{ backgroundColor: 'primary.main', height: '54px' }}>
            <TableRow>
              <TableHeadCell sx={{ ...tableHeadCellStyle, width: '15em', borderTopLeftRadius: 5 }}>{t('Last Name')}</TableHeadCell>
              <TableHeadCell sx={{ ...tableHeadCellStyle, width: '15em' }}>{t('First Name')}</TableHeadCell>
              <TableHeadCell sx={{ ...tableHeadCellStyle, width: '19em' }}>{t('Attendance Status')}</TableHeadCell>
              <TableHeadCell sx={{ ...tableHeadCellStyle, textAlign: 'center' }}>{t('Prev. Period')}</TableHeadCell>
              <TableHeadCell sx={{ ...tableHeadCellStyle, textAlign: 'center', borderTopRightRadius: 5 }}>{t('Daily')}</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {periodArrayControl.fields?.map((item, index) => (
              <Period
                key={item.id}
                path="periods"
                control={control}
                item={item}
                index={index}
                attendanceStatus={attendanceStatus}
                setValue={setValue}
                isAdmin={isAdmin}
                {...periodArrayControl}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
