import { Autocomplete, Grid, InputAdornment, MenuItem, Paper, TextField } from '@mui/material'
import { SearchIcon } from 'components/Icons'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getStudents } from 'store/lookup'
import Header from 'components/Header'
import dayjs from 'dayjs'
import { search as searchStudentAttendance } from 'store/settings/attendance'
import DateRangePicker from 'components/DateRangePicker/DateRangePicker'

export default function StudentSearchBar({ student, setStudent, setDateRange, dateRange }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [que, setQue] = useState(null)
  const students = useSelector(state => state.lookup.students?.rows) || []
  const { markingPeriods } = useSelector(state => state.session.academicYear)
  const searchQuery = { limit: 10, offset: 0 }
  const [filterBy, setFilterBy] = useState('custom')
  const sessionAcademicYear = useSelector(state => state.session.academicYear)

  const applyFilter = async (key, value) => {
    if (value) {
      searchQuery[key] = value
    }
    dispatch(getStudents(searchQuery))
  }
  

  const delayedSearch = useMemo(() => {
    return _.debounce((que, callback) => callback('que', que), 500)
  }, [dispatch])

  const selectStudent = (event, value) => {
    if (value) {
      setStudent(_.pick(value, ['personId', 'schoolEnrollmentId', 'person', 'academicYear']))
      setQue(`${value.person.lastName}, ${value.person.firstName}`)
    } else {
      setStudent(null)
      setQue('')
    }
  }

  const handleFilterChangeWithDate = value => {
    if (value === 'custom') {
      setDateRange([dayjs().startOf('week').add(1, 'day'), dayjs()])
      setFilterBy(value)
    } else if (value === 'fullYear') {
      setDateRange([markingPeriods[0].startDate, markingPeriods[3]?.endDate])
      setFilterBy(value)
    } else if (value === 'mp1') {
      setDateRange([markingPeriods[0]?.startDate, markingPeriods[0]?.endDate])
      setFilterBy(value)
    } else if (value === 'mp2') {
      setDateRange([markingPeriods[1]?.startDate, markingPeriods[1]?.endDate])
      setFilterBy(value)
    } else if (value === 'mp3') {
      setDateRange([markingPeriods[2]?.startDate, markingPeriods[2]?.endDate])
      setFilterBy(value)
    } else if (value === 'mp4') {
      setDateRange([markingPeriods[3]?.startDate, markingPeriods[3]?.endDate])
      setFilterBy(value)
    }
  }

  console.log('dateRange', dateRange)

  useEffect(() => {
    dateRange[0] && dateRange[1] && fetchAttendance()
  }, [student, dateRange])

  function fetchAttendance() {
    if (dateRange) {
      student?.schoolEnrollmentId &&
        dispatch(
          searchStudentAttendance({
            offset: 0,
            limit: 10,
            date: dayjs(dateRange[0]).format('YYYY-MM-DD'),
            endDate: dayjs(dateRange[1]).format('YYYY-MM-DD'),
            schoolEnrollmentId: student?.schoolEnrollmentId,
          }),
        )
    }
  }
  return (
    <Paper sx={{ width: '100%', px: 2, pb: 2, mb: 2 }}>
      <div style={{ marginLeft: -10 }}>
        <Header title={t('Student Attendance')} small />
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Autocomplete
            size="small"
            fullWidth
            freeSolo
            disablePortal
            options={students}
            inputValue={que || ''}
            onInputChange={(event, value, reason) => {
              if (reason === 'input') {
                setQue(value)
                delayedSearch(value, applyFilter)
              } else if (reason === 'clear') {
                setQue('')
                applyFilter('que', null)
              }
            }}
            onChange={(event, value) => {
              if (value && value.person) {
                selectStudent(event, value)
              }
            }}
            getOptionLabel={option => {
              return `${option?.person?.lastName || ''}, ${option?.person?.firstName || ''}`
            }}
            renderInput={params => (
              <TextField
                {...params}
                value={que || ''}
                fullWidth
                label={t('Student')}
                onChange={e => {
                  setQue(e.target.value)
                  delayedSearch(e.target.value, applyFilter)
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    const isOptionSelected = students.some(student => {
                      return `${student?.person?.lastName}, ${student?.person?.firstName}` === que
                    })

                    if (!isOptionSelected) {
                      e.preventDefault()
                    }
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  style: {
                    paddingRight: '10px',
                  },
                  endAdornment: (
                    <InputAdornment position="end" style={{ right: '0' }}>
                      <SearchIcon sx={{ mr: 0 }} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            select
            fullWidth
            size="small"
            color="primary"
            label={t('Filter By')}
            placeholder="Filter By"
            //defaultValue={'custom'}
            value={filterBy}
            onChange={event => handleFilterChangeWithDate(event.target.value)}
          >
            <MenuItem value={'custom'}>{t('Custom Dates')}</MenuItem>
            <MenuItem value={'fullYear'}>{t('Full Year')}</MenuItem>
            <MenuItem value={'mp1'}>{t('MP1')}</MenuItem>
            <MenuItem value={'mp2'}>{t('MP2')}</MenuItem>
            <MenuItem value={'mp3'}>{t('MP3')}</MenuItem>
            <MenuItem value={'mp4'}>{t('MP4')}</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <DateRangePicker startDate={dateRange[0]} endDate={dateRange[1]} setDateRange={setDateRange} disabled={filterBy !== 'custom'} minDate={sessionAcademicYear.startDate} maxDate={sessionAcademicYear.endDate} />
        </Grid>
      </Grid>
    </Paper>
  )
}
