import { Button, Link, Popover, Stack, TableCell, TableRow, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { getCourseSessionsEnrollmentType } from 'store/lookup'

export default function SchedulingCourseSessionsListItem({ item }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()


  const basePath = '/settings/scheduling/course-sessions'
  const academicYearMarkingPeriods = useSelector(state => state.session?.academicYear?.markingPeriods)
  const staffRole = useSelector(state => state.lookup?.courseSessionsEnrollmentType)

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  useEffect(() => {
    staffRole || dispatch(getCourseSessionsEnrollmentType())
  }, [])

  const getTerm = mp => {
    if (academicYearMarkingPeriods?.length === 1 && mp?.length === 1) {
      return 'Summer'
    } else if (mp?.length === academicYearMarkingPeriods?.length) {
      return 'Full Year'
    } else {
      return mp?.map(item => item.name).toString()
    }
  }

  const getLeadTeacher = () => {
    const lead = item?.courseSessionEnrollments?.find(cse => cse?.roleId === 'lead-teacher')
    if (lead) {
      return `${lead?.lastName}, ${lead?.firstName}`
    }
    return null
  }

  const teachers = () => {
    const leadIsExists = item?.courseSessionEnrollments.find(cse => cse?.roleId === 'lead-teacher')
    const count = item?.courseSessionEnrollments.filter(cse => cse?.roleId !== null)?.length
    if (leadIsExists && count > 1) {
      return (
        <>
          {getLeadTeacher()}
          {item?.courseSessionEnrollments.filter(cse => cse?.roleId !== null)?.length > 1 && (
            <>
              <Typography
                variant="body2"
                aria-describedby={item?.id}
                onClick={item?.teacherSchoolEnrollmentIds?.length > 1 ? handleClick : null}
              >
                ...
              </Typography>
              <Popover
                id={item?.id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                {item?.courseSessionEnrollments
                  .filter(cse => cse?.roleId !== null && cse?.roleId !== 'lead-teacher')
                  ?.map((teacher, index) => (
                    <Typography variant="body2" sx={{ px: 2, py: 1 }} key={index}>
                      {teacher?.lastName}, {teacher?.firstName} (
                      {staffRole.find(role => role.id === teacher?.roleId)?.name})
                    </Typography>
                  ))}
              </Popover>
            </>
          )}
        </>
      )
    } else if (leadIsExists && count === 1) {
      return getLeadTeacher()
    } else {
      return null
    }
  }

  if (!(academicYearMarkingPeriods && staffRole && item)) return null

  return (
    <TableRow hover>
      <TableCell>
        <Link component={NavLink} underline="hover" color="inherit" to={`${basePath}/${item.id}`}>
          {item?.course?.name}
        </Link>
      </TableCell>
      <TableCell>{item?.name}</TableCell>
      <TableCell>
        {item?.courseSessionEnrollments?.find(cse => cse?.roleId === 'lead-teacher') && (
          <Stack flexDirection={'row'}>{teachers()}</Stack>
        )}
      </TableCell>

      <TableCell>{getTerm(item?.markingPeriods)}</TableCell>
      <TableCell>
        {item?.enrollmentCount}/{item?.quota}
      </TableCell>
    
     <TableCell>{item?.periodCount}</TableCell> 

      <TableCell>
        <Button variant="outlined" component={NavLink} to={`${basePath}/${item.id}`} color="primary" size="small">
          {t('More')}
        </Button>
      </TableCell>
    </TableRow>
  )
}
