import client from '../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../utils/redux-tool'

const name = 'student'
const initialState = {
  files: [],
  running: false,
}

const fileUrl = '/api/:employmentType/:personId/employment/:employmentId/file'
export const searchFiles = createApiAction('employment-file/search', client.search(fileUrl))
export const getFile = createApiAction('employment-file/get', client.retrieve(`${fileUrl}/:id`))
export const saveFile = createApiAction('employment-file/save', client.save(fileUrl))
export const deleteFile = createApiAction('employment-file/delete', client.destroy(`${fileUrl}/:id`))

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(searchFiles.pending, state => {
        state.files = []
      })
      .addCase(searchFiles.fulfilled, (state, action) => {
        state.files = action.payload
      })
      .addCase(saveFile.fulfilled, (state, action) => {
        let found = false
        state.files = state.files.map(file => {
          found = found || file.id === action.payload.id
          return file.id === action.payload.id ? action.payload : file
        })
        if (!found) state.files = [action.payload, ...state.files]
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        const id = action.meta.arg.id
        state.files = state.files.filter(file => file.id !== id)
      })
  },
})

export default sessionSlice.reducer
