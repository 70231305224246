import client from 'utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from 'utils/redux-tool'

const name = 'grade-finalGrade'
const initialState = {
  list: null,
}

// export const search = createApiAction(
//   'academic/grade-finalGrade/search',
//   client.retrieve('/api/member/:type/:memberId/final-grades'),
// )

const basePath = '/api/member/:type/:memberId/final-grades'

export const retrieve = createApiAction('academic/final-grades/get', client.retrieve(basePath))

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(retrieve.fulfilled, (state, action) => {
        console.log('action.payload', action.payload)
        state.list = action.payload
      })
  },
})

export default sessionSlice.reducer
