import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'schoolEnrollment'
const initialState = {
  selected: null,
  list: null,
}

export const search = createApiAction(
  'setting/school-enrollment/search',
  client.search('/api/:employmentType/:personId/school-enrollment'),
)
export const get = createApiAction(
  'setting/school-enrollment/get',
  client.retrieve(`/api/:employmentType/:personId/school-enrollment/:id`),
)
export const save = createApiAction(
  'setting/school-enrollment/save',
  client.save('/api/:employmentType/:personId/school-enrollment'),
)
export const destroy = createApiAction(
  'setting/school-enrollment/delete',
  client.destroy('/api/:employmentType/:personId/school-enrollment/:id'),
)

const updateList = (state, action) => {
  const found = state.list.find(rec => rec.id === action.payload.id)
  state.list = found
    ? state.list.map(person => (found.id === person.id ? action.payload : person))
    : [...state.list, action.payload]
}

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    select: (state, action) => {
      state.selected = action.payload
    },
    clearSchoolEnrollments: state => {
      state.list = null
    },
  },
  extraReducers(builder) {
    builder
      .addCase(search.pending, state => {
        state.list = []
      })
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload
      })
      .addCase(get.fulfilled, updateList)
      .addCase(save.fulfilled, updateList)
      .addCase(destroy.fulfilled, (state, action) => {
        state.list = state.list.filter(ay => ay.id !== action.payload.id)
        state.selected = null
      })
  },
})

export const { select, clearSchoolEnrollments } = sessionSlice.actions
export default sessionSlice.reducer
